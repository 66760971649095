import React, { useEffect, useState } from 'react';
import { Box, HStack, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import TrackService from '../services/track-service';
import AvailableTracks from './AvailableTracks';
import { CodeBracketIcon, Squares2X2Icon } from '@heroicons/react/24/outline';

const TrackList = ({ noAuth }) => {
  const [tracks, setTracks] = useState('');

  useEffect(() => {
    async function fetchTracks() {
      const result = await TrackService.fetchAllTracks();
      const joinedTracks = result.tracks.filter(track => track.hasJoined);
      const notJoinedTracks = result.tracks.filter(track => !track.hasJoined);
      setTracks([...joinedTracks, ...notJoinedTracks]);
    }
    fetchTracks();
  }, []);

  return (
    <div>
      {noAuth && <Box py="10px"></Box>}
      {tracks ? (
        <>
          <div>
            <HStack mb="24px" mt="60px">
              <CodeBracketIcon className="w-6 h-6 text-gray-600" />
              <Text fontSize="xl" fontWeight="bold" textColor={'gray.600'}>
                Coding Skills
              </Text>
            </HStack>
            <SimpleGrid columns={{ sm: 1, md: 1, lg: 3 }} spacing={'24px'}>
              {tracks
                .filter(track => track.slug.includes('nd'))
                .map(item => (
                  <AvailableTracks item={item} key={item.title} />
                ))}
            </SimpleGrid>
          </div>
          <div>
            <HStack mb="24px" mt="60px">
              <Squares2X2Icon className="w-6 h-6 text-gray-600" />
              <Text fontSize="xl" fontWeight="bold" textColor={'gray.600'}>
                Non-coding Skills
              </Text>
            </HStack>
            <SimpleGrid columns={{ sm: 1, md: 1, lg: 3 }} spacing={8}>
              {tracks
                .filter(track => !track.slug.includes('nd'))
                .map(item => (
                  <AvailableTracks item={item} key={item.title} />
                ))}
            </SimpleGrid>
          </div>
        </>
      ) : (
        <SimpleGrid columns={{ sm: 1, md: 1, lg: 3 }} spacing={8}>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-3">
            <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:rounded-lg rounded">
              <div className="w-full justify-center">
                <div className="cursor-pointer py-12 px-6 max-w-xl lg:w-2/3">
                  <h3 className="text-2xl text-gray-800">
                    <Skeleton height="30px" my="10px">
                      {' '}
                    </Skeleton>
                  </h3>
                  <div className="mt-4">
                    <Skeleton height="60px" my="10px">
                      {' '}
                    </Skeleton>
                  </div>
                </div>
              </div>
              <div className="flex md:w-5/12 w-full justify-end items-center">
                <Box h="45px" w="100px">
                  <Skeleton rounded="9999px" h="50px" w="100px">
                    {' '}
                  </Skeleton>
                </Box>
              </div>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-3">
            <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:rounded-lg rounded">
              <div className="w-full justify-center">
                <div className="cursor-pointer py-12 px-6 max-w-xl lg:w-2/3">
                  <h3 className="text-2xl text-gray-800">
                    <Skeleton height="30px" my="10px">
                      {' '}
                    </Skeleton>
                  </h3>
                  <div className="mt-4">
                    <Skeleton height="60px" my="10px">
                      {' '}
                    </Skeleton>
                  </div>
                </div>
              </div>
              <div className="flex md:w-5/12 w-full justify-end items-center">
                <Box h="45px" w="100px">
                  <Skeleton rounded="9999px" h="50px" w="100px">
                    {' '}
                  </Skeleton>
                </Box>
              </div>
            </div>
          </div>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded p-3">
            <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:rounded-lg rounded">
              <div className="w-full justify-center">
                <div className="cursor-pointer py-12 px-6 max-w-xl lg:w-2/3">
                  <h3 className="text-2xl text-gray-800">
                    <Skeleton height="30px" my="10px">
                      {' '}
                    </Skeleton>
                  </h3>
                  <div className="mt-4">
                    <Skeleton height="60px" my="10px">
                      {' '}
                    </Skeleton>
                  </div>
                </div>
              </div>
              <div className="flex md:w-5/12 w-full justify-end items-center">
                <Box h="45px" w="100px">
                  <Skeleton rounded="9999px" h="50px" w="100px">
                    {' '}
                  </Skeleton>
                </Box>
              </div>
            </div>
          </div>
        </SimpleGrid>
      )}
    </div>
  );
};

export default TrackList;
