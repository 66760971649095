import React from "react";
import { Flex, Text, Skeleton, Box } from "@chakra-ui/react"
import { FaClock } from "react-icons/fa";

import { convertMsToTime } from "../utils/convertMsToTime"

export const RecentActivity = ({ recentActivitiesPage, recentActivitiesDesign, newRecentActivity, activity }) => {

    const activityDate = Date.parse(activity.created_at);
    const currentDate = Date.now()

    return (
        <>
            <Flex shadow={recentActivitiesPage ? "md" : "0px"} p={recentActivitiesPage ? "20px" : "0px"} color="#718096" mb="10px" fontSize="sm" alignItems="center" rounded="md">
                {newRecentActivity ? <Flex display={["none", "flex"]} px="15px" fontSize="10px">
                    <i style={{ color: "#ed8936" }} className="fas fa-circle"></i>
                </Flex> : null}
                <Flex pr="10px" fontSize="15px">
                <Box as={FaClock} size="16px" color="gray.400" />
                </Flex>
                <Flex w="100%" justifyContent="space-between">
                    <Flex >
                        <Text color="gray.600"> {activity.message} </Text>
                    </Flex>
                    <Flex>
                        <Text color="gray.400"> {convertMsToTime(activityDate, currentDate)} </Text>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export const RecentActivitySkeleton = ({ recentActivitiesPage }) => {
    return (
        <>
            <Flex p={recentActivitiesPage ? "20px" : "0px"} shadow={recentActivitiesPage ? "md" : null} justifyContent="space-between" rounded="md">
                <Skeleton w="5%" h="20px"> </Skeleton>
                <Skeleton w="70%" h="20px"> </Skeleton>
                <Skeleton w="20%" h="20px"> </Skeleton>
            </Flex>
            <Flex p={recentActivitiesPage ? "20px" : "0px"} shadow={recentActivitiesPage ? "md" : null} mt="10px" justifyContent="space-between" rounded="md">
                <Skeleton w="5%" h="20px"> </Skeleton>
                <Skeleton w="70%" h="20px"> </Skeleton>
                <Skeleton w="20%" h="20px"> </Skeleton>
            </Flex>
        </>
    )
}
