import React, { useState } from "react";
import { Flex, Text, Input, Divider, Icon, Image } from "@chakra-ui/react";
import DashboardNavbar from "../components/DashboardNavbar.js";
import Sidebar from "../components/Sidebar.js";
import DashboardFooter from "../components/DashboardFooter.js";
import NotificationList from "../components/NotificationList";
import { getDate } from "../utils/currentDate"

const Notifications = () => {
  const today = getDate(true)
  const [numberOfNotifications, setNumberOfNotifications] = useState("0")

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar title="Notifications" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="relative bg-white md:pt-32 pb-16"></div>
        <div className="px-2 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full  mb-12 xl:mb-0 px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 px-2 border-0">
                  <Flex w="100%" flexDirection="column">
                    <Flex mt={["50px", "50px", "50px", "0px"]} mb="20px" w="100%" justifyContent="space-between">
                      <Flex alignItems="center">
                        <Text color="#718096" pl="5px"> ({numberOfNotifications}) </Text>
                      </Flex>
                      <Flex color="#718096">
                        <Input type="date" value={today} />
                      </Flex>
                    </Flex>
                    <Flex mb="20px" flexDirection="column">
                      <Flex mb="20px" flexDirection="column">
                        <Text mb="20px" fontWeight="bold" color="#2d3748"> Today </Text>
                        <NotificationList setNumberOfNotifications={setNumberOfNotifications} notificationsPage={true} />
                      </Flex>
                    </Flex>
                    <Flex justifyContent="flex-end">
                      <Flex color="#718096" shadow="md" p="10px" alignItems="center">
                        <Flex>
                          <Icon name="chevron-left" size="24px" />
                          <Divider border="1px solid" borderColor="#ed8936" orientation="vertical" />
                          <Icon color="#2d3748" name="chevron-right" size="24px" />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter title="Dashboard" />
        </div>
      </div>
    </>
  );
};

export default Notifications;
