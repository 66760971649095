import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Image,
  Skeleton,
  HStack,
  Button,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

export const ChallengeCard = ({ challengeDetails }) => {
  const navigate = useNavigate();
  const { slug, title, difficulty_level, track, description, image, tasks } =
    challengeDetails;

  return (
    <>
      <Flex
        className="challenge"
        borderRadius="5px"
        flexDirection="column"
        justifyContent="space-between"
        rounded="lg"
        cursor="pointer"
        onClick={event => {
          event.preventDefault();
          navigate(`/challenges/${slug}`);
        }}
        border="1.5px solid #E2E8F0"
        _hover={{ shadow: 'lg' }}
        overflow="hidden"
      >
        <Box flexShrink={0} w={'100%'}>
          <Image
            src={image}
            alt={title}
            objectFit="cover"
            h={'48'}
            w={'full'}
          />
        </Box>

        <Flex color="#2d3748" w={'100%'} flexDirection="column">
          <Flex
            p={['10px', '15px']}
            alignItems="center"
            pb={['10px', '10px']}
            flexDirection="column"
          >
            <Flex
              w={['100%', '100%', '100%', '100%']}
              pt="10px"
              justifyContent={['center', 'center', 'center', 'center']}
              flexDirection="column"
            >
              <Text
                fontWeight={'semibold'}
                cursor="pointer"
                textColor={'gray.700'}
                noOfLines={1}
              >
                {title}
              </Text>
            </Flex>
            <Flex w={['100%', '100%', '100%', '100%']} pb={2}>
              <HStack spacing={1} color="gray.600" letterSpacing="wide" pr={4}>
                <Text letterSpacing="wide" fontSize={'sm'}>
                  {track}
                </Text>
                <span>&bull;</span>
                <Text
                  letterSpacing="wide"
                  textTransform="capitalize"
                  fontSize={'sm'}
                >
                  {difficulty_level}
                </Text>
              </HStack>
            </Flex>
            <Text
              overflow="hidden"
              maxHeight="40px"
              minHeight="40px"
              color="gray.600"
              fontSize="sm"
              className="line-clamp"
            >
              {description}
            </Text>
          </Flex>
        </Flex>
        <Flex
          pt={4}
          pb={8}
          px={4}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text fontSize={'sm'} fontWeight="semibold" textColor={'gray.600'}>
            {tasks && tasks.length > 0 && (
              <span>
                {tasks.length > 0 && tasks.length}{' '}
                {tasks.length > 1 ? 'Tasks' : 'Task'}
              </span>
            )}
          </Text>
          <Button
            variant={'outline'}
            fontSize={'sm'}
            rightIcon={<ArrowRightIcon className="w-4 h-4" />}
          >
            Preview
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export const ChallengeCardSkeleton = () => {
  return (
    <Flex
      p="15px"
      flexDirection="column"
      boxShadow="sm"
      justifyContent="space-between"
      minHeight="100px"
      rounded="lg"
    >
      <Flex
        w={['100%', '100%', '100%', '100%']}
        color="#2d3748"
        flexDirection="column"
      >
        <Flex
          alignItems="center"
          p="15px"
          pb={['0px', '10px']}
          flexDirection="column"
        >
          <Box
            alignItems="center"
            px="10px"
            py="15px"
            justifyContent={['center', 'center', 'center', 'flex-start']}
          >
            <Skeleton mt={['0px', '20px']} size="70px"></Skeleton>
          </Box>
        </Flex>
        <Box
          w="100%"
          justifyContent={['center', 'center', 'center', 'center']}
          flexDirection="column"
        >
          <Skeleton mt="5px" h="15px"></Skeleton>
          <Skeleton mt="5px" h="12px"></Skeleton>
          <Skeleton mt="5px" h="30px"></Skeleton>
          <Skeleton mt="5px" h="30px"></Skeleton>
        </Box>
      </Flex>
      <Flex
        w="100%"
        justifyContent="space-between"
        mt={['10px', '10px', '10px', '40px']}
      >
        <Flex mt={['10px', '10px', '10px', '0px']} color="#718096">
          <Skeleton size="40px"></Skeleton>
        </Flex>
        <Flex direction="column" color="#718096">
          <Skeleton size="40px"></Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
};
