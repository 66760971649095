import React from "react";
import {
    Flex,
    Text,
    Avatar,
    Skeleton
} from "@chakra-ui/react";
import Ordinal from "../utils/ordinal"
import AuthService from '../services/auth-service'

export const LeaderboardItem = ({ user, position }) => {

    return (
        <>
            <Flex w="100%" flexDirection="column">
                <Flex mb="10px" mt="20px" shadow="md" p={["10px", "10px", "20px", "20px"]} alignItems="center" rounded="md" justifyContent="space-between" _hover={{bg: "gray.100"}}>
                    <Flex alignItems="center">
                        <Avatar mr="10px" name={`${user.firstname.toUpperCase()} ${user.lastname.toUpperCase()}`} src={AuthService.buildImageUrl(user.image)} />
                        <Flex flexDirection="column">
                            <Text color="#2d3748"><strong> {`${user.firstname} ${user.lastname}`} </strong></Text>
                            {/* <Text color="#718096" fontSize="sm">Software Developer</Text> */}
                        </Flex>
                    </Flex>
                    <Flex color="#718096" textAlign="center" flexDirection="column">
                        <Text > {Ordinal(position)} </Text>
                        <Text> {user.total_points} pts </Text>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export const LeaderboardItemSkeleton = ({ user, position }) => {

    return (
        <>
            <Flex w="100%" flexDirection="column">
                <Flex mb="10px" mt="20px" shadow="md" p="20px" alignContent="center" justifyContent="space-between" rounded="md" _hover={{bg: "gray.100"}}>
                    <Flex w="70%" >
                        <Skeleton minWidth="48px" minHeight="48px" w="48px" h="48px" rounded="full">  </Skeleton>
                        <Flex ml="10px" w="90%" alignContent="center" flexDirection="column">
                            <Skeleton w={["80%", "70%", "70%", "50%"]} h="20px"></Skeleton>
                            <Skeleton w={["80%", "70%", "70%", "50%"]} mt="5px" h="20px"></Skeleton>
                        </Flex>
                    </Flex>
                    <Flex w="15%" color="#718096" textAlign="center" flexDirection="column">
                        <Skeleton w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
