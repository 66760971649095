import React from "react";
import {
    SimpleGrid, Text, Flex
} from "@chakra-ui/react";
import { TeamItem, TeamItemSkeleton } from "./TeamItem";

const TeamList = ({ teams }) => {

    const skeletons = ["firstSkeleton", "secondSkeleton", "thirdSkeleton", "fourthSkeleton"]

    return (
        <>
            <SimpleGrid mt="25px" columns={[1, 2, 3, 4]} spacing="20px" alignItems="flex-end" justifyContent="space-evenly" w="100%">
                {
                    teams && teams.length > 0 && teams.map((team, index) => <TeamItem team={team} key={index} />)
                }

                {
                    !teams && skeletons.map((skeleton, index) => <TeamItemSkeleton key={index} />)
                }

            </SimpleGrid>
            {
                teams && teams.length < 1 && <Flex w="100%">
                    <Text fontWeight="bold" color="#718096"> No team available yet. </Text>
                </Flex>
            }
        </>
    )
}

export default TeamList;
