import React, { useState } from "react";
import { Flex, Text, Input, Divider, Icon, Image } from "@chakra-ui/react";
import DashboardNavbar from "../components/DashboardNavbar.js";
import Sidebar from "../components/Sidebar.js";
import DashboardFooter from "../components/DashboardFooter.js";
import { getDate } from "../utils/currentDate"
import RecentActivityList from "../components/RecentActivityList"

const RecentActivities = () => {
    const today = getDate(true)
    const [numberOfNewActivities, setNumberOfNewActivities] = useState("0")
    const [pageNum, setPageNum] = useState(1)
    const [nextPageIndicator, setNextPageIndicator] = useState(true)
    const [prevPageIndicator, setPrevPageIndicator] = useState(false)

    const incrementPageNum = () => {
        if (pageNum !== Math.ceil(parseInt(numberOfNewActivities) / 10)) {
            setPageNum(pageNum + 1)
            setPrevPageIndicator(true)
        } else {
            setNextPageIndicator(false)
        }
    }
    const decrementPageNum = () => {
        if (pageNum !== 1) {
            setPageNum(pageNum - 1)
            setPrevPageIndicator(true)
            setNextPageIndicator(true)
        } else {
            setPrevPageIndicator(false)
        }
    }

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-white">
                <DashboardNavbar title="Recent Activities" hideBecomeAMentorButton={true} />
                {/* Header */}
                <div className="relative bg-white md:pt-32 pb-16"></div>
                <div className="px-2 md:px-6 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full  mb-12 xl:mb-0 px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                                <div className="rounded-t mb-0 px-2 border-0">
                                    <Flex w="100%" flexDirection="column">
                                        <Flex mt={["50px", "50px", "50px", "0px"]} mb="20px" w="100%" justifyContent="space-between">
                                            <Flex fontSize="12px" alignItems="center">
                                                <Image alt="recent-activities-icon" boxSize="40px" /> <Text color="#718096" pl="5px"> ({numberOfNewActivities}) </Text>
                                            </Flex>
                                            <Flex color="#718096">
                                                <Input size={["sm"]} fontSize={["sm", "md"]} type="date" value={today} />
                                            </Flex>
                                        </Flex>
                                        <RecentActivityList recentActivitiesPage={true} setNumberOfNewActivities={setNumberOfNewActivities} pageNum={pageNum} />
                                        <Flex mt="15px" justifyContent="flex-end">
                                            <Flex color="#718096" shadow="md" p="10px" alignItems="center">
                                                <Flex cursor="pointer">
                                                    <Icon onClick={decrementPageNum} color={prevPageIndicator ? "#2d3748" : null} name="chevron-left" size="24px" />
                                                    <Divider border="1px solid" borderColor="#ed8936" orientation="vertical" />
                                                    <Icon onClick={incrementPageNum} color={nextPageIndicator ? "#2d3748" : null} name="chevron-right" size="24px" />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DashboardFooter title="Dashboard" />
                </div>
            </div>
        </>
    );
};

export default RecentActivities;
