import React from 'react';
import { SimpleGrid, Box, Text } from '@chakra-ui/react';
import { ChallengeCard, ChallengeCardSkeleton } from './ChallengeCard';

const ChallengesGrid = ({ challengesList, isLoading }) => {
  return (
    <div>
      <SimpleGrid
        mt="25px"
        columns={[1, 2, 2, 3]}
        justifyContent="space-evenly"
        spacing="20px"
        mb="20px"
        w="100%"
      >
        {isLoading && (
          <>
            <ChallengeCardSkeleton />
            <ChallengeCardSkeleton />
            <ChallengeCardSkeleton />
          </>
        )}

        {!isLoading &&
          challengesList.length > 0 &&
          challengesList.map((challenge, index) => (
            <ChallengeCard challengeDetails={challenge} key={index} />
          ))}
      </SimpleGrid>
      {!isLoading && challengesList.length < 1 && (
        <Box mx={4} borderRadius="5px" p="20px" w="100%">
          <Text fontWeight="semibold" color="#718096">
            We couldn't find any challenges that match your search.
          </Text>
        </Box>
      )}
    </div>
  );
};

export default ChallengesGrid;
