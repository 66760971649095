import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import SetNewPassword from './pages/SetNewPassword';
import EditUserProfile from './pages/EditUserProfile';
import Skills from './pages/Skills';
import Mentors from './pages/Mentors';
import Mentor from './pages/Mentor';
import AuthGate from './components/AuthGate';
import AlreadyAuthorized from './components/AlreadyAuthorized';
import NewsletterConfirmation from './pages/NewsletterSubscription';
import PageNotFound from './pages/404';
import AccountVerificationHandler from './pages/AccountVerificationHandler';
import Challenge from './pages/Challenge';
import AuthLeaderboard from './pages/UserLeaderboard';
import Notifications from './pages/Notifications';
import RecentActivities from './pages/RecentActivities';
import Team from './pages/Team';
import CreateChallenge from './pages/CreateChallenge';
import Teams from './pages/Teams';
import CreateTeam from './pages/CreateTeam';
import JoinTeam from './pages/JoinTeam';
import SubmissionDetail from './pages/SubmissionDetail';
import Profile from './pages/Profile';
import Bookings from './pages/Bookings';
import BookingDetail from './pages/BookingDetail';
import Discussions from './pages/Discussions';
import Stories from './pages/Stories';
import StoryDetail from './pages/StoryDetail';
import DiscussionDetail from './pages/DiscussionDetail';
import Pathfinder from './pages/Pathfinder';
import About from './pages/About';
import Projects from './pages/Projects';
import Landing from './pages/Landing';
import MentorLanding from './pages/MentorLanding';
import Onboarding from './pages/Onboarding';
import LearnerOnboarding from './pages/LearnerOnboarding';
import AccountVerficationRequired from './pages/AccountVerificationRequired';
import MentorOnboarding from './pages/MentorOnboarding';
import SkillDetailWithLessons from './pages/SkillDetailWithLessons';
import MentorsDashboard from './pages/MentorsDashboard';
import Submissions from './pages/Submissions';
import SkillDetail from './pages/SkillDetail';
import ProfileSwitchHandler from './pages/ProfileSwitchHandler';
import Logout from './pages/Logout';
import Membership from './pages/Membership';
import CheckoutSuccess from './pages/CheckoutSuccess';
import CheckoutCancelled from './pages/CheckoutCancelled';
import Challenges from './pages/Challenges';
import EditChallengeSubmission from './pages/EditChallengeSubmission';
import Connections from './pages/Connections';

const theme = extendTheme({
  colors: {
    brand: {
      darkBlue: '#152340',
      lightBlue: '#51def5',
      orange: '#da8f67',
      gray: '#2d3748',
      darkOrange: '#2d3748',
    },
  },
  fonts: {
    heading:
      "'Avenir', Arial, Helvetica,sans-serif, 'Open Sans',Baskerville,'Times New Roman','Helvetica Neue'",
    body: '"Avenir", sans-serif',
  },
  components: {
    Steps,
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: '#152340',
        },
      },
    },
  },
});

const ThemedApp = () => (
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/leaderboard" element={<AuthLeaderboard />} />
        <Route
          exact
          path="/login"
          element={<AlreadyAuthorized Component={Login} />}
        />
        <Route
          exact
          path="/get-started"
          element={<AlreadyAuthorized Component={Register} />}
        />
        <Route
          exact
          path="/forgot-password"
          element={<AlreadyAuthorized Component={ForgotPassword} />}
        />
        <Route exact path="/set-password" element={<SetNewPassword />} />
        <Route
          exact
          path={'/home'}
          element={<AuthGate Component={Dashboard} />}
        />
        <Route
          exact
          path={'/skills/:trackId/lessons'}
          element={<AuthGate Component={SkillDetailWithLessons} />}
        />
        <Route exact path={'/skills/:trackId'} element={<SkillDetail />} />
        <Route exact path={'/skills'} element={<Skills />} />
        <Route exaact path="/membership" element={<Membership />} />
        <Route exact path="/discussions" element={<Discussions />} />
        <Route exact path="/discussions/:slug" element={<DiscussionDetail />} />
        <Route exact path="/stories" element={<Stories />} />
        <Route exact path="/stories/:slug" element={<StoryDetail />} />
        <Route exact path="/challenges" element={<Challenges />} />
        <Route exact path="/challenges/:challengeId" element={<Challenge />} />
        <Route
          exact
          path="/challenges/:challengeId/submit"
          element={<EditChallengeSubmission />}
        />
        <Route exact path={'/mentors'} element={<Mentors />} />
        <Route exact path={'/mentor/:username'} element={<Mentor />} />
        <Route
          exaact
          path="/learning-paths"
          element={<Navigate replace to="/skills" />}
        />
        <Route
          exact
          path={'/learning-paths/:trackId'}
          element={<SkillDetail />}
        />

        <Route
          exact
          path="/settings"
          element={<AuthGate Component={EditUserProfile} />}
        />
        <Route
          exact
          path="/notifications"
          element={<AuthGate Component={Notifications} />}
        />
        <Route
          exact
          path="/recent-activities"
          element={<AuthGate Component={RecentActivities} />}
        />
        <Route
          exact
          path="/create-challenge"
          element={<AuthGate Component={CreateChallenge} />}
        />
        <Route
          exact
          path="/create-challenge"
          element={<AuthGate Component={CreateChallenge} />}
        />
        <Route exact path="/teams" element={<AuthGate Component={Teams} />} />
        <Route
          exact
          path="/teams/:teamId"
          element={<AuthGate Component={Team} />}
        />
        <Route
          exact
          path="/teams/:invitationCode/join"
          element={<AuthGate Component={JoinTeam} />}
        />
        <Route
          exact
          path="/create-team"
          element={<AuthGate Component={CreateTeam} />}
        />
        <Route
          exact
          path="/submissions/:submissionsId"
          element={<SubmissionDetail />}
        />
        <Route
          exact
          path="/verify_account"
          element={<AccountVerificationHandler />}
        />
        <Route
          exact
          path="/verification_required"
          element={<AuthGate Component={AccountVerficationRequired} />}
        />
        <Route
          exact
          path="/onboarding"
          element={<AuthGate Component={Onboarding} />}
        />
        <Route
          exact
          path="/onboarding/learner"
          element={<AuthGate Component={LearnerOnboarding} />}
        />
        <Route
          exact
          path="/onboarding/mentor"
          element={<AuthGate Component={MentorOnboarding} />}
        />
        <Route
          exact
          path="/confirm_subscription"
          element={<NewsletterConfirmation />}
        />
        <Route exact path={'/profile/:username'} element={<Profile />} />
        <Route
          exact
          path="/bookings"
          element={<AuthGate Component={Bookings} />}
        />
        <Route
          exact
          path="/bookings/:hashCode"
          element={<AuthGate Component={BookingDetail} />}
        />
        <Route exact path="/quiz" element={<Pathfinder />} />
        {/* <Route exact path="/about" element={<About />} /> */}
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/become-a-mentor" element={<MentorLanding />} />
        <Route
          exact
          path="/m/home"
          element={<AuthGate Component={MentorsDashboard} />}
        />
        <Route
          exact
          path="/submissions"
          element={<AuthGate Component={Submissions} />}
        />
        <Route
          exact
          path="/connections"
          element={<AuthGate Component={Connections} />}
        />
        <Route
          exact
          path="/profiles/:profile/switch"
          element={<ProfileSwitchHandler />}
        />
        <Route exact path="/logout" element={<Logout />} />
        {/* Checkout */}
        <Route
          exact
          path="/checkout/success"
          element={<AuthGate Component={CheckoutSuccess} />}
        />
        <Route
          exact
          path="/checkout/cancelled"
          element={<AuthGate Component={CheckoutCancelled} />}
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);

export default ThemedApp;
