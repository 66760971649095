import React from 'react';
import { Avatar, Box, Flex, Link, Text } from '@chakra-ui/react';
import GradeBadge from './GradeBadge';

const GradeBox = ({ assignedMentor, grade, comment }) => {
  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} pb={2}>
        <Avatar
          mr={2}
          size="sm"
          name={assignedMentor.firstname + ' ' + assignedMentor.lastname}
          src={assignedMentor.image}
          bgColor="brand.darkBlue"
          textColor={'white'}
        />
        <Link
          href={`/mentors/${assignedMentor.username}`}
          textColor={'orange.600'}
          fontWeight={'light'}
          fontSize={'sm'}
          pr="2"
        >
          {assignedMentor.firstname + ' ' + assignedMentor.lastname}
        </Link>
        <Text fontSize={'sm'} pr={1}>
          graded your work as{' '}
        </Text>
        <GradeBadge grade={grade} />
      </Flex>
      <Box
        rounded={'md'}
        border={'1px'}
        borderColor="gray.100"
        _hover={{ shadow: 'sm' }}
        minHeight={'16'}
      >
        <Text p={2} fontSize={'sm'} noOfLines={1}>
          {comment}
        </Text>
      </Box>
    </Flex>
  );
};

export default GradeBox;
