import React from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Flex, Box, Text, Icon, Stack } from "@chakra-ui/react";
import { FaAngleUp, FaReply } from "react-icons/fa";

const DiscussionItem = ({ discussion }) => {

    const navigate = useNavigate();
    const { replies, slug, upvotes } = discussion;
    const viewDiscusssion = (event) => {
        event.preventDefault();
        navigate(`/discussions/${slug}`);
    };

    return (
        <>
            <Flex
                shadow={"md"}
                p={["10px", "20px"]}
                color="#718096"
                mb="10px"
                rounded="sm"
                alignItems="center"
                cursor="pointer"
                _hover={{ bg: "gray.50", cursor: "pointer" }}
                onClick={(event) => viewDiscusssion(event)}
            >
                <Flex pr="10px" fontSize="15px">
                    <Avatar src={discussion.author.image} name={discussion.author.fullname}></Avatar>
                </Flex>
                <Flex
                    w="100%"
                    justifyContent={["flex-start", "space-between"]}
                    direction={["column", "column", "column", "row"]}
                >
                    <Flex px="10px" alignItems="center" justifyContent="space-between">
                        <Box
                            justifyContent={["center", "center", "center", "flex-start"]}
                            flexDirection="column"
                        >
                            <Stack>
                                <Text fontWeight="semibold" color="gray.700" fontSize="14px">
                                    {discussion.title}
                                </Text>
                                <Text fontSize="xs">{discussion.author.fullname} in <span className="text-orange-500">{discussion.topic.name}</span></Text>
                            </Stack>
                        </Box>
                    </Flex>
                    <Flex mt={["5px", "5px", "5px", "0px"]}>
                        <Flex px="10px" color="#718096" textAlign="center" justifyContent="center" justifyItems="center">
                            <Stack isInline spacing={4}>
                                <Box fontSize="sm" pt="3px">
                                    <Text>
                                    <Icon as={FaAngleUp} size="sm" variant="ghost" aria-label="Upvote" ></Icon> {" "}
                                    {upvotes}
                                    </Text>
                                </Box>
                                <Box fontSize="sm" pt="3px">
                                    <Text>
                                        <Icon as={FaReply} size="14px" aria-label="Comment"></Icon>{" "}
                                        {replies.length}
                                    </Text>
                                </Box>
                            </Stack>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}

export default DiscussionItem;