import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Skeleton,
  StackDivider,
  Tag,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import SEO from '../components/Common/SEO';
import TrackService from '../services/track-service';
import { Mixpanel } from '../utils/Mixpanel';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import DashboardNavbar from '../components/DashboardNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faExternalLinkSquare,
  faPuzzlePiece,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import {
  ChallengeCard,
  ChallengeCardSkeleton,
} from '../components/ChallengeCard';
import MarkdownToHtml from '../components/ReactMarkdownItem';
import withRouter from '../utils/withRouter.js';
import {
  PuzzlePieceIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline';
import Resources from '../components/Track/Resources';

const SkillDetailWithLessons = () => {
  const navigate = useNavigate();
  const { key: keyLocation } = useLocation();
  const [currentTrack, setCurrentTrack] = useState({
    lessons: [],
    learners: [],
  });
  const [currentLesson, setCurrentLesson] = useState({
    challenges: [],
    resources: [],
    title: '',
  });
  const { trackId: slug } = useParams();
  const toast = useToast();
  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  useEffect(() => {
    async function getCurrentTrack() {
      try {
        const response = await TrackService.getTrack(slug);
        setCurrentTrack(response.track);
        setCurrentLesson(response.track.lessons[activeStep]);
        Mixpanel.track('Viewed track with lessons', {
          track: response.track.title,
        });
      } catch (err) {
        Mixpanel.track('Failed View track');
        toast({
          title: 'Oops!',
          description: 'Unable to fetch track. Please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getCurrentTrack();
  }, []);

  const handleLessonNavClick = step => {
    setCurrentLesson(currentTrack.lessons[step]);
    setStep(step);
  };

  const handleNextLesson = event => {
    event.preventDefault();
    setCurrentLesson(currentTrack.lessons[activeStep + 1]);
    nextStep();
  };

  const handlePreviousLesson = event => {
    event.preventDefault();
    setCurrentLesson(currentTrack.lessons[activeStep - 1]);
    prevStep();
  };

  return (
    <>
      <SEO title={'Learn in-demand skills'} />
      <Sidebar />
      <div className="relative md:ml-64 ">
        <DashboardNavbar title="Skill" hideBecomeAMentorButton={true} />
        {/* Header */}

        <div className="px-4 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4 mx-4">
            <div className="relative w-full">
              <Button
                size={'sm'}
                rounded={'full'}
                variant="ghost"
                colorScheme={'gray'}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                    style={{ marginRight: '4px' }}
                  />
                }
                marginBottom="12px"
                cursor={'pointer'}
                onClick={() => navigate(`/skills/${currentTrack?.slug}`)}
              >
                Back to skill home
              </Button>
              <div
                className="shadow-sm border border-gray-100 rounded-lg"
                style={{ marginBottom: '40px', padding: '32px' }}
              >
                <div className="mt-5 p-2">
                  <Skeleton isLoaded={currentTrack ? true : false}>
                    <Flex flexDirection={'column'}>
                      <Link
                        onClick={() =>
                          navigate(`/skills/${currentTrack?.slug}`)
                        }
                        className="underline"
                        color={'orange.600'}
                        fontSize={'xs'}
                      >
                        {currentTrack.title}
                      </Link>
                      <h1
                        className="capitalize font-bold text-gray-700"
                        style={{ fontSize: '24px', marginBottom: '16px' }}
                      >
                        {currentLesson.title}
                      </h1>
                    </Flex>

                    <Text
                      className="text-gray-700 md:w-3/4"
                      fontSize="md"
                      lineHeight="32px"
                    >
                      {currentLesson.explainer}
                    </Text>
                    {currentTrack && (
                      <Flex
                        alignItems={{ base: 'flex-start', md: 'center' }}
                        flexDirection={{ base: 'column', md: 'row', xl: 'row' }}
                        fontSize="22px"
                        mt="16px"
                      >
                        {/* <Text className="text-sm text-gray-700 flex items-center mt-2 md:mt-0 mr-4">
                          <PuzzlePieceIcon className="w-4 h-4 mr-2" />
                          {currentLesson.challenges.length} Practice challenges
                        </Text> */}
                        <p className="text-sm text-gray-700 flex items-center mt-2 md:mt-0 mr-4">
                          <Square3Stack3DIcon className="w-4 h-4 mr-2" />
                          {currentLesson.resources.length} Recommend resources
                        </p>
                      </Flex>
                    )}
                  </Skeleton>
                </div>
              </div>
            </div>

            <div className="w-full xl:w-8/12 mb-12 xl:mb-0 md:px-4">
              {currentTrack && currentLesson.title && (
                <Box paddingBottom={8}>
                  <Flex
                    justifyContent={'space-between'}
                    className="content-center items-center"
                  >
                    <Box>
                      <Text
                        letterSpacing={'wider'}
                        className="text-gray-600"
                        fontSize={'xs'}
                        pb={3}
                        textTransform={'uppercase'}
                        fontWeight="semibold"
                      >
                        Lesson {activeStep + 1}
                      </Text>
                    </Box>
                  </Flex>
                  <MarkdownToHtml
                    input={currentLesson.explainer}
                    classname="text-sm text-gray-700"
                  />
                  <Divider pb={2} />
                </Box>
              )}
              <Flex direction={'column'} pb={10}>
                <HStack pb={4}>
                  <Square3Stack3DIcon className="h-5 w-5 text-gray-600" />
                  <Text textColor={'gray.600'} fontWeight="semibold">
                    Recommend resources
                  </Text>
                </HStack>
                <Resources resources={currentLesson.resources} />
              </Flex>
              {/* {currentTrack && currentLesson.challenges.length > 0 && (
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                  <div className="rounded-t mb-0 py-3 border-0">
                    <div className="flex flex-col items-left justify-center">
                      <Text textColor={'gray.600'} fontWeight="semibold">
                        <FontAwesomeIcon
                          icon={faPuzzlePiece}
                          className="mr-2"
                        />
                        Challenges
                      </Text>
                      <p className="text-md text-gray-500">
                        Micro-tasks to help you improve your skills and build
                        your portfolio.
                      </p>
                      <SimpleGrid
                        mt="25px"
                        columns={[1, 2, 2, 2]}
                        justifyContent="space-evenly"
                        spacing="20px"
                        mb="20px"
                        w="100%"
                      >
                        {currentTrack === '' && (
                          <>
                            <ChallengeCardSkeleton />
                            <ChallengeCardSkeleton />
                          </>
                        )}

                        {currentLesson.challenges.map((challenge, index) => (
                          <ChallengeCard
                            challengeDetails={challenge}
                            key={index}
                          />
                        ))}
                      </SimpleGrid>
                    </div>
                  </div>
                </div>
              )} */}

              <Flex py={8} justifyContent="space-between">
                <Box justifyContent={'flex-start'}>
                  {activeStep > 0 ? (
                    <Button
                      onClick={event => handlePreviousLesson(event)}
                      fontSize={'sm'}
                      rounded={'full'}
                      variant={'outline'}
                      leftIcon={
                        <FontAwesomeIcon
                          className="h-4 w-4"
                          icon={faArrowLeft}
                        />
                      }
                    >
                      Previous lesson
                    </Button>
                  ) : (
                    <></>
                  )}
                </Box>

                <Box justifyContent={'flex-end'}>
                  {activeStep < currentTrack.lessons.length - 1 ? (
                    <Button
                      onClick={event => handleNextLesson(event)}
                      fontSize={'sm'}
                      rounded={'full'}
                      variant={'outline'}
                      rightIcon={
                        <FontAwesomeIcon
                          className="h-4 w-4"
                          icon={faArrowRight}
                        />
                      }
                    >
                      Next lesson
                    </Button>
                  ) : null}
                </Box>
              </Flex>
            </div>
            <div className="w-full xl:w-4/12 px-4 border border-gray-100 rounded-lg mb-28">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                <div className="rounded-t mb-0 border-0">
                  <div className="flex flex-wrap items-start text-sm text-gray-600">
                    <VStack spacing={2} alignItems={'flex-start'} pt={4}>
                      <Text
                        textColor={'gray.600'}
                        textTransform={'uppercase'}
                        fontWeight="semibold"
                      >
                        Lessons
                      </Text>
                      <Divider />
                      <Flex>
                        <Steps
                          onClickStep={step => handleLessonNavClick(step)}
                          size={'sm'}
                          orientation="vertical"
                          colorScheme={'gray'}
                          activeStep={activeStep}
                        >
                          {currentTrack &&
                            currentTrack.lessons.map(item => (
                              <Step
                                icon={() => <FontAwesomeIcon icon={faTasks} />}
                                label={item.title}
                                key={item.title}
                              />
                            ))}
                        </Steps>
                      </Flex>
                    </VStack>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto"></div>
              </div>
            </div>
          </div>

          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(SkillDetailWithLessons);
