import React, { useState, useEffect } from 'react';
import { Flex, Button, Skeleton, useToast, Spinner } from '@chakra-ui/react';
import TextInput from './TextInput';
import Validator from '../utils/validator';
import SubmissionService from '../services/submissions-service';

export const SubmissionForm = ({ submission, slug }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const [solutionLink, setSolutionLink] = useState('');
  const [notes, setNotes] = useState('');
  const [id, setId] = useState('');

  useEffect(() => {
    if (submission) {
      const { work_link, additional_information, id } = submission;
      setSolutionLink(work_link);
      setNotes(additional_information);
      setId(id);
    }
  }, [submission]);

  const [showSolutionLinkError, setShowSolutionLinkError] = useState(false);
  const [showNotesInputError, setShowNotesInputError] = useState(false);

  const validateSubmission = isError => {
    const isSolutionLinkValid = Validator.isUrlValid(solutionLink);
    const isNotesInputValid = Validator.isValueValid(notes);

    setShowSolutionLinkError(!isSolutionLinkValid);
    setShowNotesInputError(!isNotesInputValid);

    if (!isSolutionLinkValid || !isNotesInputValid) {
      return (isError = true);
    }

    return isError;
  };

  const submitSolution = async () => {
    let isError = false;

    isError = validateSubmission(isError);

    if (isError) {
      return;
    }

    const payload = {
      work_link: solutionLink,
      additional_information: notes,
      challenge_slug: slug,
    };

    setIsLoading(true);

    try {
      const response = await SubmissionService.makeSubmission(payload);
      if (response.status === 201) {
        toast({
          title: 'Success!',
          description: 'Your solution has been successfully submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        window.location.reload();
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to submit your solution, please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const updateSubmission = async () => {
    let isError = false;

    isError = validateSubmission(isError);

    if (isError) {
      return;
    }

    const payload = {
      work_link: solutionLink,
      additional_information: notes,
      challenge_slug: slug,
    };

    setIsLoading(true);

    try {
      const response = await SubmissionService.updateSubmission(id, payload);
      if (response.status === 200) {
        toast({
          title: 'Success!',
          description: 'Your solution has been successfully updated.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to update your solution, please try again',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Flex flexDirection="column">
        {/* Notes */}
        <Flex mb="20px">
          <TextInput
            title={'Submission Content'}
            value={notes}
            element="textArea"
            showError={showNotesInputError}
            placeholder="Enter any notes or comments about your submission"
            errorMessage={'This field cannot be blank'}
            onChangeFunction={event => setNotes(event.target.value)}
          />
        </Flex>

        {/* Solution Link or Github */}
        <Flex>
          <TextInput
            title={'Link'}
            value={solutionLink}
            onChangeFunction={event => setSolutionLink(event.target.value)}
            type="url"
            placeholder="Link to your solution"
            showError={showSolutionLinkError}
            errorMessage={'Please provide a valid url to your solution'}
          />
        </Flex>

        {/* Submit */}
        <Flex mt="20px" mx="10px">
          <Button
            _focus={{ outline: 'none' }}
            bgColor="brand.darkBlue"
            textColor={'white'}
            onClick={id ? updateSubmission : submitSolution}
            isLoading={isLoading}
            loadingText={'Wait...'}
            _hover={{
              bgColor: 'brand.darkBlue',
              textColor: 'white',
              opacity: 0.8,
              cursor: 'pointer',
              outline: 'none',
              shadow: 'small',
            }}
            w={['30%', '20%']}
          >
            {id ? 'Update' : 'Submit'}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export const SubmissionFormSkeleton = () => {
  return (
    <Flex
      w="100%"
      mt={['40px', '40px', '40px', '0px']}
      p={['10px', '10px', '10px', '20px']}
      shadow="lg"
      flexDirection="column"
    >
      <Flex mx="10px">
        <Skeleton w="100%" h="50px"></Skeleton>
      </Flex>
      <Flex mt="20px" flexDirection="column">
        {/* Solution Link or Github */}
        <Flex mt="20px" mx="10px" flexDirection="column">
          <Skeleton w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="40px"></Skeleton>
        </Flex>

        {/* Notes */}
        <Flex mt="20px" mx="10px" flexDirection="column">
          <Skeleton w="100%" h="20px"></Skeleton>
          <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
        </Flex>

        {/* Submit */}
        <Flex mt="20px" mx="10px">
          <Skeleton w={['30%', '20%']} h="40px"></Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
};
