import React from 'react';
import { Box, Flex, Text, Progress } from '@chakra-ui/react';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MilestoneCard = ({ actual, target, trophyColor }) => (
  <Flex
    bgColor={'white'}
    mb="15px"
    w="100%"
    p="20px"
    shadow="sm"
    border={'1px'}
    borderColor={'gray.100'}
    flexDirection={'row'}
    alignItems="center"
    rounded="md"
  >
    <Box mr={2}>
      <FontAwesomeIcon icon={faTrophy} className={`h-12 w-12 ${trophyColor}`} />
    </Box>
    <Flex flexDirection={'column'} width="100%">
      <Text fontSize="sm" fontWeight="bold" pb={2}>
        {target} Feedback Requests Milestone
      </Text>
      {actual < target ? (
        <Progress
          rounded={'full'}
          size="xs"
          value={(actual / target) * 100}
          mb={2}
          colorScheme="orange"
        />
      ) : null}

      {actual >= target ? (
        <Text fontSize="xs" textColor={'gray.600'}>
          Given {target} Feedback Requests
        </Text>
      ) : (
        <Text fontSize="xs" textColor={'gray.600'}>
          Review {target - actual} more submissions.
        </Text>
      )}
    </Flex>
  </Flex>
);

export default MilestoneCard;
