import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast, Text, Flex, Button } from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import queryString from 'query-string';
import { Formik } from 'formik';
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader';
import Navbar from '../components/Navbar.js';
import FooterSmall from '../components/FooterSmall.js';
import AuthService from '../services/auth-service';
import { Mixpanel } from '../utils/Mixpanel';
import withRouter from '../utils/withRouter.js';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

import SEO from '../components/Common/SEO.js';
import useProfile from '../utils/auth/useProfile.js';

const Login = () => {
  Mixpanel.track('Login Page');
  splitbee.track('Login Page');

  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const { setProfileFromUser } = useProfile();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(8, 'Too short!').required('Required'),
  });

  const handleLogin = async values => {
    const payload = {
      user: { email: values.email, password: values.password },
    };
    try {
      await AuthService.login(payload);
      const user = await AuthService.fetchCurrentUser();
      setProfileFromUser(user);
      Mixpanel.identify(user.id);
      Mixpanel.track('Login Success');
      splitbee.user.set({
        id: user.id,
        email: user.email,
        name: user.firstname,
      });
      splitbee.track('Login Success');
      const { redirectTo } = queryString.parse(location.search);
      const defaultHomeUrl = user.is_mentor ? '/m/home' : '/home';
      navigate(redirectTo === null ? defaultHomeUrl : redirectTo);
      window.Intercom('update', {
        user_id: user.id,
        email: user.email,
        name: user.firstname,
      });
      window.location.reload();
    } catch (error) {
      Mixpanel.track('Failed Login');
      toast({
        title: 'Something went wrong',
        description:
          'Email or password is incorrect, please check and try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleForgotPassword = event => {
    event.preventDefault();
    navigate('/forgot-password');
  };

  return (
    <>
      <SEO
        title="Welcome back! Log into your account | Techstarta"
        url="https://techstarta.com/login"
        description={'Login to save your learning progress'}
      />
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#1a202c"
        minHeight="100%"
        position="static"
        overflow="auto"
        backgroundColor="white"
        backgroundSize="100% auto"
        backgroundRepeat="repeat"
        backgroundPosition="center"
      >
        <Navbar transparent={false} showMenu={true} />
        <Flex
          w="auto"
          minHeight="calc(100vh - 60px)"
          justifyContent="center"
          alignContent="center"
        >
          <Flex
            m="30px"
            mt="100px"
            rounded="5px"
            w={['100%', '80%', '60%', '40%']}
            alignItems="center"
            justifyContent="center"
          >
            <Flex w={['100%', '80%', '60%', '70%']} flexDirection="column">
              <main>
                <section className="w-full h-full">
                  <div className="container mx-auto h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="w-full">
                        <div className="relative flex flex-col min-w-0 break-words w-full  shadow-lg rounded-lg bg-white border-0">
                          <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center mb-3">
                              <h6 className="text-gray-700 text-sm uppercase font-bold">
                                Welcome back!
                              </h6>
                            </div>
                          </div>
                          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <Formik
                              initialValues={{ email: '', password: '' }}
                              validationSchema={LoginSchema}
                              onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true);
                                await handleLogin(values);
                                setSubmitting(false);
                              }}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                              }) => (
                                <form method="post" onSubmit={handleSubmit}>
                                  <div className="relative w-full mb-3">
                                    <label
                                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                      htmlFor="email"
                                    >
                                      Email
                                    </label>
                                    <input
                                      id="email"
                                      type="email"
                                      name="email"
                                      value={values.email}
                                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                                      placeholder="Enter your email address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      style={{ transition: 'all .15s ease' }}
                                      required={true}
                                    />
                                    <Text
                                      borderRadius="md"
                                      fontSize="sm"
                                      mt="1"
                                      textColor="red.500"
                                    >
                                      {errors.email && touched.email
                                        ? errors.email
                                        : null}
                                    </Text>
                                  </div>
                                  <div className="mb-3">
                                    <div className="relative w-full">
                                      <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="password"
                                      >
                                        Password
                                      </label>
                                      <input
                                        id="password"
                                        type={
                                          showPassword ? 'text' : 'password'
                                        }
                                        name="password"
                                        value={values.password}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm border focus:outline-none focus:shadow-outline w-full"
                                        placeholder="Enter your password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ transition: 'all .15s ease' }}
                                        required={true}
                                      />
                                      <span
                                        className="cursor-pointer absolute"
                                        style={{ bottom: '15px', right: '5px' }}
                                        onClick={() => {
                                          setShowPassword(!showPassword);
                                        }}
                                      >
                                        {showPassword ? (
                                          <AiOutlineEye />
                                        ) : (
                                          <AiOutlineEyeInvisible />
                                        )}
                                      </span>
                                    </div>
                                    <Text
                                      borderRadius="md"
                                      fontSize="sm"
                                      mt="1"
                                      textColor="red.500"
                                    >
                                      {errors.password && touched.password
                                        ? errors.password
                                        : null}
                                    </Text>
                                  </div>

                                  <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                      <input
                                        id="customCheckLogin"
                                        type="checkbox"
                                        className="form-checkbox text-gray-800 ml-1 w-5 h-5"
                                        style={{ transition: 'all .15s ease' }}
                                      />
                                      <span className="ml-2 text-sm font-semibold text-gray-700">
                                        Remember me
                                      </span>
                                    </label>
                                  </div>

                                  <div className="text-center mt-6">
                                    <Button
                                      width={'100%'}
                                      rounded={'full'}
                                      type="submit"
                                      bgColor={'brand.darkBlue'}
                                      textColor={'white'}
                                      _hover={{ shadow: 'lg' }}
                                      fontSize={'sm'}
                                      textTransform={'uppercase'}
                                      isLoading={isSubmitting}
                                      spinner={
                                        <BeatLoader size={8} color="white" />
                                      }
                                      disabled={isSubmitting}
                                    >
                                      Log in
                                    </Button>
                                  </div>
                                </form>
                              )}
                            </Formik>

                            <div className="flex flex-wrap mt-6">
                              <div className="w-1/2">
                                <a
                                  href="/forgot-password"
                                  onClick={event => handleForgotPassword(event)}
                                  className="text-gray-800"
                                >
                                  <small>Forgot password?</small>
                                </a>
                              </div>
                              <div className="w-1/2 text-right">
                                <a
                                  href="/get-started"
                                  className="text-gray-800"
                                >
                                  <small>Create new account</small>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </Flex>
          </Flex>
        </Flex>
        <FooterSmall />
      </Flex>
    </>
  );
};

export default withRouter(Login);
