import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Icon, Text, Box, Stack } from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';
import { FcCollaboration, FcReadingEbook } from 'react-icons/fc';
import SEO from '../components/Common/SEO.js';
import Navbar from '../components/Navbar.js';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';

const Onboarding = () => {
  const navigate = useNavigate();
  return (
    <>
      <SEO
        title="Onboarding | Techstarta"
        url="https://techstarta.com/onboarding"
        description={'Welcome to Techstarta. Setup your account'}
      />
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#1a202c"
        minHeight="100%"
        backgroundColor="white"
      >
        <Navbar transparent={false} showMenu={false} />
        <div className="flex items-center justify-between setup w-full">
          <div className="flex w-full md:w-2/3 items-center justify-center setup-buttons onboarding-glass-bg">
            <React.Fragment>
              <Flex
                p={[2, 16, 16, '24']}
                flexDirection="column"
                className="width-80"
              >
                <Box p={4}>
                  <Text fontSize={'2xl'} fontWeight="semibold" pb={4}>
                    We're glad you are here! How do you want to be part of
                    Techstarta?{' '}
                  </Text>
                </Box>
                <Flex p={4} flexDirection={['row', 'row', 'row', 'column']}>
                  <Stack
                    spacing={4}
                    direction={['column', 'row', 'row', 'row']}
                  >
                    <Box
                      as="button"
                      className="flex flex-col justify-center items-center"
                      h="auto"
                      width={64}
                      p="20px"
                      boxShadow={'md'}
                      _hover={{ bgColor: 'orange.50' }}
                      borderRadius={'lg'}
                      onClick={event => {
                        event.preventDefault();
                        navigate('/onboarding/learner');
                      }}
                    >
                      <Icon as={FcReadingEbook} mb="20px" w={100} h={100} />
                      <Text fontSize="20px" fontWeight={'semibold'}>
                        As an early-career techie
                      </Text>
                      <Text fontSize={'xs'}>
                        Build your skill & portfolio to qualify for job in tech.
                      </Text>
                    </Box>
                    <Box
                      as="button"
                      className="flex flex-col justify-center items-center"
                      h="auto"
                      p="20px"
                      width={64}
                      boxShadow={'md'}
                      _hover={{ bgColor: 'teal.50' }}
                      borderRadius={'lg'}
                      onClick={event => {
                        event.preventDefault();
                        navigate('/onboarding/mentor');
                      }}
                    >
                      <Icon as={FcCollaboration} mb="20px" w={100} h={100} />
                      <Text fontSize="20px" fontWeight={'semibold'}>
                        As a mentor
                      </Text>
                      <Text fontSize={'xs'}>
                        Mentor early-career techies looking to build careers in
                        tech
                      </Text>
                    </Box>
                  </Stack>
                </Flex>
              </Flex>
            </React.Fragment>
          </div>
          <div className="hidden sm:block w-full xl:w-1/3">
            <Flex
              height="100%"
              align={'center'}
              flexDirection="column"
              justifyContent={'center'}
            >
              <Text className="text-left text-lg font-semibold pt-8">
                Techstarta Community
              </Text>
              <Stack spacing={'4'} p={'12'}>
                <Stack
                  bg={'white'}
                  key={1}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Join 900+ techies</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Join a community of techies from all over the world and get
                    support from our mentors.
                  </Text>
                </Stack>
                <Stack
                  key={2}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Build your skills & portfolio</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Solve real-world challenges to build your portfolio and
                    boost your confidence.
                  </Text>
                </Stack>
                <Stack
                  key={3}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Get Feedback & guidance</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Get feedback and access to 1-on-1 mentoring sessions with
                    industry experts.
                  </Text>
                </Stack>

                <Stack
                  key={4}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Learn with bite-sized lessons</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Learn with high-quality resources hand-picked for you to
                    learn effectively.
                  </Text>
                </Stack>
              </Stack>
            </Flex>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default withRouter(Onboarding);
