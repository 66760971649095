import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';

import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import discussionService from '../services/discussion-service.js';
import {
  DiscussionWithReplies,
  DiscussionWithRepliesSkeleton,
} from '../components/Discussion/DiscussionWithReplies.js';
import { ContentAuthorCard } from '../components/Common/ContentAuthorCard.js';
import { SocialShareBox } from '../components/Common/SocialShareBox.js';
import CommentBox from '../components/Common/CommentBox.js';

const DiscussionDetail = () => {
  const { slug } = useParams();
  const [discussion, setDiscussion] = useState('');
  const [replies, setReplies] = useState([]);
  const toast = useToast();

  useEffect(() => {
    async function getDiscussion() {
      try {
        const response = await discussionService.getDiscussion(slug);
        setDiscussion(response.data.discussion);
        setReplies(response.data.discussion.replies);
      } catch (err) {
        toast({
          title: 'Oops!',
          description:
            'Unable to fetch this discussion, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getDiscussion();
  }, []);

  const upvoteReply = async replyId => {
    try {
      const response = await discussionService.upvoteReply(replyId);
      const updatedReplies = replies.map(reply =>
        reply.id === replyId
          ? { ...reply, upvotes: response.data.reply.upvotes }
          : reply
      );
      setReplies(updatedReplies);
    } catch (err) {}
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={`Discussion | ${discussion.title}`} />
        <title>{`Techstarta | Discussions`}</title>
      </Helmet>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar title="Discussion" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="relative bg-white md:pt-32 pb-16"></div>
        <div className="md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg">
                <div className="rounded-lg mb-0 border-0">
                  <Box
                    p={['10px', '10px']}
                    shadow="md"
                    mt={['30px', '40px', '40px', '0px']}
                    mx={['10px']}
                    display="flex"
                    flexDirection="column"
                    rounded="md"
                  >
                    {discussion ? (
                      <DiscussionWithReplies discussion={discussion} />
                    ) : (
                      <DiscussionWithRepliesSkeleton />
                    )}
                    {discussion ? (
                      <Box p={['10px', '10px']} marginTop="20px">
                        <Flex direction="column">
                          <Text
                            fontWeight="semibold"
                            fontSize="xl"
                            letterSpacing="normal"
                            color="brand.darkBlue"
                          >
                            {' '}
                            Replies
                          </Text>
                        </Flex>
                        {replies.length > 0 ? (
                          replies.map(reply => (
                            <CommentBox
                              key={reply.id}
                              comment={reply}
                              upvoteFn={upvoteReply}
                            />
                          ))
                        ) : (
                          <Text fontSize="sm" color="gray.500">
                            There are no replies yet.
                          </Text>
                        )}
                      </Box>
                    ) : null}
                  </Box>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-4/12">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                <div className="flex flex-wrap items-center text-xs px-4">
                  {discussion ? (
                    <ContentAuthorCard author={discussion.author} />
                  ) : null}
                  {discussion ? (
                    <SocialShareBox
                      contentType="discussion"
                      contentTitle={discussion.title}
                      boxTitle="Share this discussion"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(DiscussionDetail);
