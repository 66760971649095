import React, { useState, useEffect } from "react";
import ActivityService from "../services/activity-service"
import { RecentActivity, RecentActivitySkeleton } from "./RecentActivity"
import {
    Flex,
    Text,
} from "@chakra-ui/react";

const RecentActivityList = (props) => {

    const [activities, setActivities] = useState([])
    const { setNumberOfNewActivities, recentActivitiesPage, pageNum } = props;
    const [isLoading, setIsLoading] = useState(true)

    useEffect(
        () => {
            async function getActivities() {
                const response = await ActivityService.getRecentActivities()
                if (response.status === 200) {
                    setActivities(response.data.activities)
                    setIsLoading(false)
                }
            }
            getActivities()
        }, []
    )

    useEffect(
        () => {
            if (recentActivitiesPage) {
                setNumberOfNewActivities(activities.length)
            }
        }, [setNumberOfNewActivities, activities, recentActivitiesPage]
    )

    const filteredRecentActivities = activities.filter((activity, index) => {
        switch (pageNum) {
            case 1:
                if (index <= 9)
                    return activity
                break;
            case 2:
                if (index >= 10 && index <= 20)
                    return activity
                break;
            case 3:
                if (index >= 20 && index <= 30)
                    return activity
                break;
            case 4:
                if (index >= 30 && index <= 40)
                    return activity
                break;
            case 5:
                if (index >= 40 && index <= 50)
                    return activity
                break;
            default:
                return null
        }
    })

    return (
        <>
            {/* Recent activity loading state */}
            {
                isLoading && <RecentActivitySkeleton recentActivitiesPage={recentActivitiesPage} />
            }

            {/* IF no recent activity exist */}
            {
                !isLoading && activities.length < 1 && <Flex w="100%">
                    <Text fontSize={'xs'} fontWeight="normal" color="gray.500"> Your activities will appear here.</Text>
                </Flex>
            }

            {/* If there are recent activities on Recent activity page */}
            {
                !isLoading && activities.length > 0 && recentActivitiesPage && filteredRecentActivities.map((activity, index) => <RecentActivity recentActivitiesPage={recentActivitiesPage} activity={activity} key={index} />)
            }

            {/* If there are recent activities on Dashboard page */}

            {
                !isLoading && activities.length > 0 && !recentActivitiesPage && activities.filter((activity, index) => index < 5).map((activity, index) => <RecentActivity recentActivitiesPage={recentActivitiesPage} activity={activity} key={index} />)
            }
        </>
    )
}

export default RecentActivityList;
