import React from 'react';
import TechstartaIcon from '../images/techstarta-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faLinkedinIn,
  faMediumM,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  return (
    <>
      <footer className="relative bg-slate-800 pt-16 pb-6 text-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12">
              <div className="w-3/4">
                <img
                  src={TechstartaIcon}
                  alt="Techstarta"
                  className="h-12 w-12 mx-2 mb-4"
                />
                <h4 className="text-xl font-semibold px-4 mb-2">
                  Kickstart you tech career
                </h4>
                <h6 className="text-sm font-normal px-4">
                  Techstarta helps you learn the tech skills you need to land
                  your first job. We help you build the right skillsets and a
                  portfolio to show potential employers.
                </h6>
                <div className="mt-6 px-4 pb-4">
                  <a
                    href="https://twitter.com/intent/follow?original_referer=https://techstarta.com&region=follow_link&screen_name=techstarta"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="w-5 h-5 mr-3"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/techstarta"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="w-5 h-5 mr-3"
                    />
                  </a>
                  <a
                    href="https://linkedin.com/company/techstarta"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="w-5 h-5 mr-3"
                    />
                  </a>
                  <a
                    href="https://medium.com/techstarta"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faMediumM}
                      className="w-5 h-5 mr-3"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto pb-6">
                  <span className="block text-gray-400 text-md font-semibold mb-2">
                    Contribute
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/become-a-mentor"
                      >
                        Become a mentor
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="mailto:hello@techstarta.com"
                      >
                        Share career your story
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="mailto:hello@techstarta.com"
                      >
                        Partner with us
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400  block pb-2 text-md"
                        rel="noopener noreferrer"
                        href="https://github.com/techstarta/talentmine/issues/new"
                      >
                        Report an issue
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4 ml-auto pb-6">
                  <span className="block text-gray-400 text-md font-semibold mb-2">
                    Keep in touch
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/stories"
                      >
                        Stories
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="mailto:hello@techstarta.com"
                      >
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4 ml-auto pb-6">
                  <span className="block text-gray-400 text-md font-semibold mb-2">
                    Get started
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/quiz"
                      >
                        Discover your career fit
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/get-started"
                      >
                        Get started
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-100 hover:text-gray-400 block pb-2 text-md"
                        href="/login"
                      >
                        Login
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-400" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-200 font-semibold py-1">
                © {new Date().getFullYear()}, Techstarta. All right reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
