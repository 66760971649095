import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useToast,
  Button,
  Skeleton,
  Text,
  HStack,
  Flex,
  Stack,
  Link,
  VStack,
  Avatar,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  StackDivider,
  ListItem,
  UnorderedList,
  Image,
} from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import Sidebar from '../components/Sidebar';
import DashboardNavbar from '../components/DashboardNavbar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import TrackService from '../services/track-service';
import AuthService from '../services/auth-service';
import ProfileService from '../services/profile-service';
import { Mixpanel } from '../utils/Mixpanel';
import withRouter from '../utils/withRouter.js';
import SEO from '../components/Common/SEO';
import {
  ClockIcon,
  PlayCircleIcon,
  QueueListIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import LessonCard from '../components/Track/LessonCard';

const SkillDetail = () => {
  splitbee.track('Skill Detail Page');
  Mixpanel.track('Skill Detail Page');

  const { isOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const size = 'lg';

  const toast = useToast();
  const { trackId: slug } = useParams();
  const [currentTrack, setCurrentTrack] = useState('');
  const [hasJoinedTrack, setHasJoinedTrack] = useState(null);
  const [title, setTitle] = useState('Learn tech skills for free | Techstarta');
  const [profile, setProfile] = useState('');
  const user = JSON.parse(AuthService.getCurrentUser());
  const username = user ? user.username : null;
  const profileSlug = profile && profile.tracks[0]?.slug;

  useEffect(() => {
    async function getCurrentTrack() {
      try {
        const response = await TrackService.getTrack(slug);
        setCurrentTrack(response.track);
        setHasJoinedTrack(response.track.hasJoined);
        setTitle(`Learn ${response.track.title} for free | Techstarta`);
        Mixpanel.track('Viewed track', { track: response.track.title });
      } catch (err) {
        Mixpanel.track('Failed View track');
        toast({
          title: 'Oops!',
          description: 'Unable to fetch track. Please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getCurrentTrack();
  }, [slug, toast]);

  useEffect(() => {
    async function fetchProfile() {
      if (username) {
        const response = await ProfileService.getProfile(username);
        setProfile(response.data.profile);
      }
    }
    fetchProfile();
  }, [username]);

  const joinTrack = async event => {
    event.preventDefault();
    try {
      const username = user.username;
      const response = await TrackService.joinTrack(slug, username);
      if (response === 200) {
        toast({
          title: 'Bravo!',
          description: "You're on a journey to success!",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setHasJoinedTrack(true);
        await AuthService.fetchCurrentUser();
        navigate(`/skills/${slug}/lessons`);
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast({
          title: 'Session expired!',
          description: 'You need login again to view this page',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
      } else {
        toast({
          title: 'Login required',
          description: 'You need to login to save your progress',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
      }
    }
  };

  const leaveTrack = async event => {
    event.preventDefault();
    setIsAlertDialogOpen(false);
    try {
      const user = JSON.parse(AuthService.getCurrentUser());
      const username = user.username;
      const response = await TrackService.deleteTrack(profileSlug, username);
      if (response.status === 200) {
        toast({
          title: 'Done.',
          description: 'You joined this track',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        setHasJoinedTrack(false);
        await AuthService.fetchCurrentUser();
        navigate(`/skills/${slug}/lessons`);
      }
    } catch (err) {
      if (err.status === 403) {
        toast({
          title: 'Login required',
          description: 'Please login and then try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong.',
          description:
            'Unable to remove you from this track, please try again later ',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const changeTrack = event => {
    leaveTrack(event);
    joinTrack(event);
  };

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState();
  const onAlertDialogClose = () => setIsAlertDialogOpen(false);
  const cancelRef = useRef();
  const openAlertDialogBox = event => {
    event.preventDefault();
    setIsAlertDialogOpen(true);
  };

  const goToLesson = () => {
    navigate(`/skills/${slug}/lessons`);
  };

  return (
    <>
      <SEO
        title={title}
        url={`https://techstarta.com/skills/${slug}`}
        description={'Build your tech skills with Techstarta'}
      />
      <Sidebar />
      <div className="relative md:ml-64">
        <DashboardNavbar hideBecomeAMentorButton={true} />

        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4 mx-4">
            <div className="relative w-full">
              <Button
                size={'sm'}
                rounded={'md'}
                variant="ghost"
                colorScheme={'gray'}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                    style={{ marginRight: '4px' }}
                  />
                }
                marginBottom="12px"
                cursor={'pointer'}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <div
                className="shadow-sm border border-gray-100 rounded-lg"
                style={{ marginBottom: '50px', padding: '32px' }}
              >
                <div className="mt-5 p-2">
                  <Skeleton isLoaded={currentTrack ? true : false}>
                    <Flex justifyContent={'space-between'}>
                      <Flex flexDirection={'column'}>
                        <Flex>
                          <h1
                            className="capitalize font-bold"
                            style={{ fontSize: '24px', marginBottom: '16px' }}
                          >
                            {currentTrack.title}
                          </h1>
                        </Flex>
                        <Text
                          className="text-gray-700"
                          fontSize="md"
                          lineHeight="32px"
                          fontWeight="500"
                        >
                          {currentTrack.description}
                        </Text>
                        {currentTrack && (
                          <Flex
                            alignItems={{ base: 'flex-start', md: 'center' }}
                            flexDirection={{
                              base: 'column',
                              md: 'row',
                              xl: 'row',
                            }}
                            fontSize="22px"
                            mt="16px"
                          >
                            <Text className="text-sm text-gray-700 flex items-center mt-2 md:mt-0 mr-4">
                              <UserIcon className="w-4 h-4 mr-2" />
                              {currentTrack.learners.length} Learners
                            </Text>
                            <p className="text-sm text-gray-700 flex items-center mt-2 md:mt-0 mr-4">
                              <QueueListIcon className="w-4 h-4 mr-2" />
                              {currentTrack.lessons.length} Lessons
                            </p>
                            <p className="text-sm text-gray-700 flex items-center mt-2 md:mt-0">
                              <ClockIcon className="w-4 h-4 mr-2" />
                              Self-paced
                            </p>
                          </Flex>
                        )}

                        {!hasJoinedTrack && (
                          <div className="flex w-full mt-5">
                            <Button
                              fontSize="sm"
                              shadow="md"
                              _hover={{ shadow: 'lg' }}
                              _focus={{ outline: 'none' }}
                              bg="brand.darkBlue"
                              rounded={'full'}
                              textColor={'white'}
                              onClick={
                                user
                                  ? openAlertDialogBox
                                  : event => joinTrack(event)
                              }
                              rightIcon={<PlayCircleIcon className="w-4 h-4" />}
                            >
                              {user
                                ? 'Start learning'
                                : 'Login to start learning'}
                            </Button>
                          </div>
                        )}

                        {hasJoinedTrack && (
                          <div className="flex w-full mt-5 p-2">
                            <Stack isInline spacing={2}>
                              <Button
                                fontSize="sm"
                                shadow="md"
                                _hover={{ shadow: 'lg' }}
                                _focus={{ outline: 'none' }}
                                bg="brand.darkBlue"
                                style={{
                                  borderRadius: '16px',
                                  letterSpacing: '0.01em',
                                  textTransform: 'uppercase',
                                  padding: '12px 24px',
                                }}
                                textColor={'white'}
                                onClick={goToLesson}
                                rightIcon={
                                  <PlayCircleIcon className="w-4 h-4" />
                                }
                              >
                                RESUME LESSONS
                              </Button>
                            </Stack>
                          </div>
                        )}
                      </Flex>
                      <Image
                        h={{ md: '104px', xl: '104px' }}
                        className="hidden md:block"
                        src={currentTrack.image}
                        alt={currentTrack.title}
                      />
                    </Flex>
                  </Skeleton>
                </div>
              </div>
            </div>

            <div className="w-full xl:w-8/12 mb-12 xl:mb-0">
              <div className="flex flex-col min-w-0 break-words bg-white w-full">
                <Text
                  textColor="gray.600"
                  pb={4}
                  fontWeight="semibold"
                  fontSize="lg"
                >
                  Lessons
                </Text>
                <VStack
                  className="flex flex-col items-left justify-center"
                  divider={<StackDivider borderColor="gray.200" />}
                >
                  {currentTrack &&
                    currentTrack.lessons.map((lesson, index) => (
                      <LessonCard
                        key={index}
                        lesson={lesson}
                        trackSlug={currentTrack.slug}
                      />
                    ))}
                </VStack>
              </div>
            </div>
            <div className="w-full xl:w-4/12 mb-12 xl:mb-0 xl:pl-4 mt-5 pb-12">
              <Flex flexDirection={'column'}>
                {currentTrack && currentTrack.mentors.length > 0 && (
                  <Flex
                    flexDirection={'column'}
                    rounded={'lg'}
                    width="100%"
                    border={'1px'}
                    borderColor="gray.100"
                    p={4}
                  >
                    <Text
                      className="text-md text-gray-600 font-bold"
                      letterSpacing="0.01em"
                      mb="16px"
                    >
                      Available Mentors
                    </Text>
                    {currentTrack.mentors.map(mentor => (
                      <Flex
                        key={mentor.username}
                        alignItems="center"
                        py={4}
                        fontWeight="500"
                      >
                        <Avatar
                          mr="10px"
                          name={`${mentor.firstname.toUpperCase()} ${mentor.lastname.toUpperCase()}`}
                          src={AuthService.buildImageUrl(mentor.image)}
                        />
                        <Flex flexDirection="column">
                          <Text
                            fontSize={'sm'}
                            color="#303740"
                            letterSpacing="0.01em"
                            mb="4px"
                          >
                            {`${mentor.firstname}  ${mentor.lastname}`}
                          </Text>
                          <HStack spacing={2}>
                            <BriefcaseIcon className="opacity-75 text-sm w-4 h-4" />
                            <Text fontSize={'sm'} color="#455A64" noOfLines={1}>
                              {mentor.current_role} at {mentor.company}
                            </Text>
                          </HStack>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </Flex>
              <Flex flexDirection={'column'} paddingTop="4">
                {currentTrack && currentTrack.tools.length > 0 && (
                  <Flex
                    flexDirection={'column'}
                    rounded={'lg'}
                    width="100%"
                    border={'1px'}
                    borderColor="gray.100"
                    p={4}
                  >
                    <Text
                      className="text-md text-gray-600 font-bold"
                      letterSpacing="0.01em"
                      mb="16px"
                    >
                      Tools you need
                    </Text>
                    <UnorderedList>
                      {currentTrack.tools.map(tool => (
                        <ListItem key={tool.name} alignItems="center" mb="10px">
                          <Link
                            fontSize="sm"
                            textColor="orange.600"
                            textDecoration={'underline'}
                            letterSpacing="0.01em"
                            target="blank"
                            href={tool.link}
                            isExternal
                          >
                            {tool.name}
                          </Link>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Flex>
                )}
              </Flex>
            </div>

            <Drawer onClose={onClose} isOpen={isOpen} size={size}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerHeader>{`${size} drawer contents`}</DrawerHeader>
                <DrawerBody>
                  {size === 'full'
                    ? `You're trapped 😆, refresh the page to leave`
                    : null}
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </div>
          <DashboardFooter />
        </div>
      </div>
      <AlertDialog
        isOpen={isAlertDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertDialogClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Confirmation
          </AlertDialogHeader>

          <AlertDialogBody fontSize="md" textColor="gray.600">
            {profile &&
              profile.tracks.length === 0 &&
              'You are about to enroll for this learning path. 🙂'}
            {profile &&
              profile.tracks.length === 1 &&
              'You are currently registered for another course'}
          </AlertDialogBody>

          {profile && profile.tracks.length === 0 && (
            <AlertDialogFooter>
              <Button
                size="sm"
                rounded="full"
                ref={cancelRef}
                onClick={onAlertDialogClose}
              >
                No
              </Button>
              <Button
                size="sm"
                rounded="full"
                colorScheme="red"
                onClick={event => joinTrack(event)}
                ml={3}
              >
                Yes
              </Button>
            </AlertDialogFooter>
          )}
          {profile && profile.tracks.length === 1 && (
            <AlertDialogFooter>
              <Link
                color="orange.500"
                ml={3}
                onClick={event => changeTrack(event)}
              >
                Change this
              </Link>
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default withRouter(SkillDetail);
