import React from 'react';
import { Link as ReachLink } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Image,
  Link,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

export const CareerPathCard = ({ path }) => {
  return (
    <>
      <Box
        maxW="sm"
        boxShadow="md"
        borderRadius="lg"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100px"
        overflow="hidden"
      >
        <Image src={path.imageUrl} alt={path.title} />
        <Flex
          color="#2d3748"
          w={['100%', '100%', '100%', '100%']}
          flexDirection="column"
        >
          <Flex
            p={4}
            alignItems="center"
            pb={['0px', '10px']}
            flexDirection="column"
          >
            <Flex
              w={['100%', '100%', '100%', '100%']}
              pt="10px"
              justifyContent={['center', 'center', 'center', 'center']}
              flexDirection="column"
            >
              <Text
                as={'h3'}
                className="text-slate-600 text-xl font-semibold py-2"
              >
                {path.title}
              </Text>
              <Text
                overflow="hidden"
                maxHeight="82px"
                minHeight="63px"
                className="line-clamp text-slate-700 text-md"
              >
                {path.description}
              </Text>
            </Flex>
            <Flex w="100%" py={4} direction={'column'}>
              <Text className="line-clamp text-slate-700 text-md font-semibold pb-2">
                If you like
              </Text>
              <UnorderedList color="gray.600" className="text-md">
                {path.likes.map((like, index) => (
                  <ListItem key={index}>{like}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          px={4}
          pb={4}
          w="100%"
          direction={'column'}
          justifyContent="space-between"
        >
          <Text className="line-clamp text-slate-700 text-md font-semibold pb-2">
            Specializations
          </Text>
          <UnorderedList className="line-clamp text-md">
            {path.specializations.map((specialization, index) => (
              <ListItem key={index}>
                <Link
                  color="orange.500"
                  as={ReachLink}
                  to={specialization.slug}
                >
                  {specialization.title}
                </Link>
              </ListItem>
            ))}
          </UnorderedList>
        </Flex>
      </Box>
    </>
  );
};
