import React, { useState, useEffect } from "react";
import DashboardNavbar from "../components/DashboardNavbar.js";
import Sidebar from "../components/Sidebar.js";
import DashboardFooter from "../components/DashboardFooter.js";
import {
    Flex,
    Text,
    Divider,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useToast
} from "@chakra-ui/react";
import { useParams } from "react-router-dom"
import { TrackTab, MemberTab, EditTeamTab, ChallengeTab, TrackTabSkeleton, MemberTabSkeleton } from "../components/TeamDetails";
import TeamService from "../services/team-service";
import { CreateTeamFormSkeleton } from "../components/CreateTeamForm"
import TrackService from "../services/track-service";

const Teams = () => {

    const toast = useToast()
    const slug = useParams().teamId
    const [team, setTeam] = useState("")
    const [tracks, setTracks] = useState("");
    const [challengesList, setChallengesList] = useState([]);

    useEffect(
        () => {
            async function getTeam() {
                try {
                    const response = await TeamService.getTeam(slug)
                    if (response.status === 200) {
                        setTeam(response.data.team)
                    }
                    const fetchedTracks = await TrackService.fetchAllTracks()
                    setTracks(fetchedTracks.tracks);

                    const fetchedChallenges = await TrackService.getChallenges();
                    setChallengesList(fetchedChallenges.challenges)
                } catch (err) {
                    toast({
                        title: "Ooops!",
                        description: "Unable to get teams. Please reload page",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    })
                }
            }
            getTeam()
        }, [slug, toast]
    )

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-white">
                <DashboardNavbar title="Team" hideBecomeAMentorButton={true} />
                {/* Header */}
                <div className="relative bg-white md:pt-32 pb-16"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full  mb-12 xl:mb-0 px-2">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                                <div className="rounded-t mb-0 px-2 border-0">
                                    <Flex mt={["40px", "40px", "40px", "0px"]} p={["10px", "10px", "10px", "20px"]} shadow="lg" flexDirection="column">
                                        <Flex justifyContent="flex-start" alignItems="center" mx="10px">
                                            <Flex alignItems="flex-end">
                                                <Text fontSize="25px" ml="10px" color="#2d3748" fontWeight="bold" > {team ? `${team.name}` : null}  </Text>
                                            </Flex>
                                        </Flex>
                                        <Divider mt="10px" />
                                        <Tabs color="#718096" colorScheme="orange" isFitted>
                                            <TabList alignItems="flex-end">
                                                <Tab px={["5px", "auto"]} _focus={{ outline: "none" }}>Assigned Tracks</Tab>
                                                <Tab px={["5px", "auto"]} _focus={{ outline: "none" }}>Assigned Challenges</Tab>
                                                <Tab px={["5px", "auto"]} _focus={{ outline: "none" }}>Members</Tab>
                                                <Tab px={["5px", "auto"]} _focus={{ outline: "none" }}>Edit Team</Tab>
                                            </TabList>

                                            <TabPanels>
                                                <TabPanel>
                                                    {team ? <TrackTab tracks={tracks} team={team} /> : <TrackTabSkeleton />}
                                                </TabPanel>
                                                <TabPanel>
                                                    {team ? <ChallengeTab challenges={challengesList} team={team} /> : <TrackTabSkeleton />}
                                                </TabPanel>
                                                <TabPanel>
                                                    {team ? <MemberTab members={team.members} numOfMembers={team.members_count} invitationCode={team.invitation_code} />
                                                        : <MemberTabSkeleton />}
                                                </TabPanel>
                                                <TabPanel>
                                                    {team ? <EditTeamTab team={team} /> : <CreateTeamFormSkeleton />}
                                                </TabPanel>
                                            </TabPanels>
                                        </Tabs>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DashboardFooter title="Teams" />
                </div>
            </div>
        </>
    )
}

export default Teams;
