import React from "react";
import { Box, Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";

const DiscussionItemSkeleton = () => {
    return (
        <>
            <Box display={'flex'} padding='6' boxShadow='md' bg='white' justifyContent={"space-between"} mb={4}>
                <SkeletonCircle size='12' />
                <Skeleton>
                    <SkeletonText mt='4' noOfLines={2} spacing='4' />
                </Skeleton>
            </Box>
        </>
    )
}

const DiscussionListSkeleton = () => {
    return (
        <>
            <DiscussionItemSkeleton />
            <DiscussionItemSkeleton />
            <DiscussionItemSkeleton />
            <DiscussionItemSkeleton />
        </>
    )
}

export { DiscussionListSkeleton }