import React from "react";
import {
    Flex,
    Text,
    Skeleton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom"

export const TeamItem = ({ team }) => {

    return (
        <>
            <Flex textAlign="center" p="20px" shadow="lg" justifyContent="center" alignItems="center" flexDirection="column">
                <Link to={`/teams/${team.slug}`}>
                    <Text fontWeight="bold" color="#2d3748"> {team.name} </Text>
                </Link>
                <Text fontSize="sm" mt="10px" color="#718096"> {team.description} </Text>
            </Flex>
        </>
    )
}

export const TeamItemSkeleton = () => {
    return (
        <>
            <Flex minHeight="151px" textAlign="center" p="20px" shadow="lg" justifyContent="center" alignItems="center" flexDirection="column">
                <Skeleton w="100%" h="20px"> </Skeleton>
                <Skeleton mt="10px" w="100%" h="20px"> </Skeleton>
            </Flex>
        </>
    )
}
