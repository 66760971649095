import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Button,
  Heading,
  Text,
  Alert,
  AlertIcon,
  Stack,
  FormLabel,
  FormControl,
  Select,
  Checkbox,
  Input,
  useToast,
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Form, Formik } from 'formik';
import { FiCheckCircle } from 'react-icons/fi';

import {
  BriefcaseIcon,
  CheckBadgeIcon,
  LightBulbIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  AcademicCapIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import * as Yup from 'yup';
import SEO from '../components/Common/SEO.js';
import Navbar from '../components/Navbar';
import AuthService from '../services/auth-service';
import { trackData } from '../data/trackList';
import TrackService from '../services/track-service';
import withRouter from '../utils/withRouter.js';

const tracks = trackData;

const LearnerOnboarding = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const careerGoalOptions = [
    'Learn a new skill',
    'Improve my skills',
    'Build my portfolio',
    'Build real-world projects',
    'Get mentored by an industry expert',
  ];

  const institutionTypes = [
    {
      label: 'Attending a school',
      value: 'school',
    },
    {
      label: 'Working in a company',
      value: 'company',
    },
    {
      label: 'Something else, please  specify',
      value: 'others',
    },
  ];
  const [data, setData] = useState({
    career_goal: [],
    institution_type: '',
    institution_name: '',
    track: '',
    personalWebsite: '',
    linkedIn: '',
  });

  const handleNext = newData => {
    setData(prev => ({ ...prev, ...newData }));
    nextStep();
  };

  const handlePrev = newData => {
    setData(prev => ({ ...prev, ...newData }));
    prevStep();
  };

  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const CareerGoalStep = props => {
    const CareerSchema = Yup.object().shape({
      career_goal: Yup.array()
        .min(2, 'Select at least 2')
        .max(2, 'Select at most 2')
        .required('Select your top 2 goals'),
    });
    const handleSubmit = values => {
      props.next(values);
    };

    return (
      <Formik
        initialValues={props.data}
        validationSchema={CareerSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="w-full flex flex-col justify-center">
            <FormControl mt="30px" mb="30px" w={['100%', '100%']}>
              <FormLabel color="#2d3748" mb="10px">
                What are your top two goals?
              </FormLabel>
              <Stack>
                {careerGoalOptions.map(item => (
                  <Checkbox
                    value={item}
                    name="career_goal"
                    key={item}
                    onChange={handleChange}
                  >
                    {item}
                  </Checkbox>
                ))}
              </Stack>
              {errors.career_goal && touched.career_goal ? (
                <Alert borderRadius="md" fontSize="sm" mt="4" status="warning">
                  <AlertIcon size="13px" />
                  {errors.career_goal}
                </Alert>
              ) : null}
            </FormControl>

            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                rounded={'full'}
                colorScheme={'gray'}
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
                leftIcon={<ChevronLeftIcon className="w-4 h-4" />}
              >
                Previous
              </Button>
              <Button
                rounded={'full'}
                variant={'solid'}
                colorScheme={'gray'}
                size="sm"
                type="submit"
              >
                {activeStep === steps.length - 1 ? (
                  'Finish'
                ) : (
                  <>
                    Next
                    <ChevronRightIcon className="w-4 h-4" />
                  </>
                )}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const InstitutionStep = props => {
    const InstitutionSchema = Yup.object().shape({
      institution_type: Yup.mixed()
        .oneOf(['school', 'company', 'others'])
        .label('Selected Institution')
        .required('Select an institution'),
      institution_name: Yup.string()
        .min(2, 'Too short!')
        .max(50, 'Too long!')
        .required('Please input the name of your institution '),
    });

    const handleSubmit = values => {
      props.next(values);
    };

    return (
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={InstitutionSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="w-full flex flex-col justify-center">
            <Flex width={'100%'} flexDirection="column" mt="30px" mb="30px">
              <FormControl>
                <FormLabel color="#2d3748" mb="5px">
                  What's your current occupation?
                </FormLabel>
                <Select
                  name="institution_type"
                  focusBorderColor="#dd6b20"
                  value={values.institution_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="#2d3748"
                  fontSize="sm"
                  placeholder="Select one"
                  options={institutionTypes}
                >
                  {institutionTypes.map(item => (
                    <option
                      key={item.value}
                      value={item.value}
                      className="capitalize"
                    >
                      {item.label}
                    </option>
                  ))}
                </Select>
                {errors.institution_type && touched.institution_type ? (
                  <Alert
                    borderRadius="md"
                    fontSize="sm"
                    mt="1"
                    status="warning"
                  >
                    <AlertIcon size="13px" />
                    {errors.institution_type}
                  </Alert>
                ) : null}
              </FormControl>

              <FormControl mt="30px">
                <FormLabel>
                  {values.institution_type === 'school' &&
                    'Enter the name of school'}
                  {values.institution_type === 'company' &&
                    'Enter the name of the institution'}
                  {values.institution_type === 'others' &&
                    'What are you doing now?'}
                  {values.institution_type === '' && 'Instituition name'}
                </FormLabel>
                <Input
                  name="institution_name"
                  type="text"
                  focusBorderColor="#dd6b20"
                  value={values.institution_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.institution_name && touched.institution_name ? (
                  <Alert
                    borderRadius="md"
                    fontSize="sm"
                    mt="1"
                    status="warning"
                  >
                    <AlertIcon size="13px" />
                    {errors.institution_name}
                  </Alert>
                ) : null}
              </FormControl>
            </Flex>

            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                rounded={'full'}
                colorScheme={'gray'}
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
                leftIcon={<ChevronLeftIcon className="w-4 h-4" />}
              >
                Previous
              </Button>
              <Button
                rounded={'full'}
                variant={'solid'}
                colorScheme={'gray'}
                size="sm"
                type="submit"
              >
                {activeStep === steps.length - 1 ? (
                  'Finish'
                ) : (
                  <>
                    Next
                    <ChevronRightIcon className="w-4 h-4" />
                  </>
                )}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const TracksStep = props => {
    const TrackSchema = Yup.object().shape({
      track: Yup.mixed()
        .oneOf([
          'android-development',
          'backend-development',
          'frontend-development',
          'ui-ux-design',
          'product-management',
          'content-marketing',
          'dont-know',
        ])
        .label('Selected Path')
        .required('Please select an option'),
    });
    const handleSubmit = values => {
      props.next(values);
    };

    return (
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={TrackSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="w-full flex flex-col justify-center">
            <Flex flexDirection="column" mt="30px" mb="30px">
              <FormControl w={['100%', '100%']}>
                <FormLabel color="#2d3748" mb="5px">
                  What do you want to learn?
                </FormLabel>
                <Select
                  focusBorderColor="#dd6b20"
                  value={values.track}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="#2d3748"
                  fontSize="sm"
                  options={tracks}
                  placeholder="Select a track"
                  name="track"
                >
                  {tracks.map(item => (
                    <option key={item.name} value={item.slug}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {errors.track && touched.track ? (
                <Alert borderRadius="md" fontSize="sm" mt="1" status="warning">
                  <AlertIcon size="13px" />
                  {errors.track}
                </Alert>
              ) : null}
            </Flex>
            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                rounded={'full'}
                colorScheme={'gray'}
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
                leftIcon={<ChevronLeftIcon className="w-4 h-4" />}
              >
                Previous
              </Button>
              <Button
                rounded={'full'}
                variant={'solid'}
                colorScheme={'gray'}
                size="sm"
                type="submit"
              >
                {activeStep === steps.length - 1 ? (
                  'Finish'
                ) : (
                  <>
                    Next
                    <ChevronRightIcon className="w-4 h-4" />
                  </>
                )}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const ElsewhereStep = props => {
    const regMatch =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const LinkSchema = Yup.object().shape({
      linkedIn: Yup.string().matches(regMatch, 'Please provide a valid URL'),
      website: Yup.string().matches(regMatch, 'Please provide a valid URL'),
    });
    return (
      <Formik
        initialValues={props.data}
        validationSchema={LinkSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          try {
            const payload = {
              career_goal: values.career_goal,
              institution_type: values.institution_type,
              institution_name: values.institution_name,
              linkedIn: values.linkedIn,
              track: values.track,
              personalWebsite: values.personalWebsite,
            };
            const userProfile = new FormData();
            const user = JSON.parse(AuthService.getCurrentUser());
            const username = user.username;

            let response;
            if (payload.track !== 'dont-know') {
              response = await TrackService.joinTrack(payload.track, username);
            }

            userProfile.append('user', JSON.stringify(payload));

            const result = await AuthService.updateUserProfile(userProfile);

            if (response && result === 200) {
              toast({
                title: 'Great!',
                description: 'Your profile has been setup!',
                status: 'success',
                duration: 3000,
                isClosable: true,
              });
            }
            navigate('/home');
          } catch (error) {
            if (error.status === 400 || 403) {
              setErrors(error.data.errors);
              toast({
                title: 'Error!',
                description: 'Profile not setup.',
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
            }
          }

          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="w-full flex flex-col justify-center">
            <FormControl mt="30px">
              <FormLabel>LinkedIn (optional)</FormLabel>
              <Input
                value={values.linkedIn}
                name="linkedIn"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="https://www.linkedin.com/in/yourname/"
                focusBorderColor="#dd6b20"
              />
            </FormControl>
            {errors.linkedIn && touched.linkedIn ? (
              <Alert borderRadius="md" fontSize="sm" mt="1" status="warning">
                <AlertIcon size="13px" />
                {errors.linkedIn}
              </Alert>
            ) : null}

            <FormControl mt="30px" mb="30px">
              <FormLabel>Personal website (optional)</FormLabel>
              <Input
                name="personalWebsite"
                value={values.personalWebsite}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="https://"
                focusBorderColor="#dd6b20"
              />
            </FormControl>
            {errors.personalWebsite && touched.personalWebsite ? (
              <Alert borderRadius="md" fontSize="sm" mt="1" status="warning">
                <AlertIcon size="13px" />
                {errors.personalWebsite}
              </Alert>
            ) : null}
            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                rounded={'full'}
                colorScheme={'gray'}
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
                leftIcon={<ChevronLeftIcon className="w-4 h-4" />}
              >
                Previous
              </Button>
              <Button
                rounded={'full'}
                variant={'solid'}
                colorScheme={'gray'}
                size="sm"
                type="submit"
              >
                {activeStep === steps.length - 1 ? (
                  'Finish'
                ) : (
                  <>
                    Next
                    <ChevronRightIcon className="w-4 h-4" />
                  </>
                )}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const steps = [
    {
      label: 'Career goals',
      component: <CareerGoalStep data={data} next={handleNext} />,
      icon: <LightBulbIcon className="w-4 h-4" />,
    },
    {
      label: 'Current occupation',
      component: (
        <InstitutionStep data={data} next={handleNext} prev={handlePrev} />
      ),
      icon: <BriefcaseIcon className="w-4 h-4" />,
    },
    {
      label: 'Choose a track',
      component: <TracksStep data={data} next={handleNext} prev={handlePrev} />,
      icon: <AcademicCapIcon className="w-4 h-4" />,
    },
    {
      label: 'Final step',
      component: (
        <ElsewhereStep data={data} next={handleNext} prev={handlePrev} />
      ),
      icon: <RocketLaunchIcon className="w-4 h-4" />,
    },
  ];

  return (
    <>
      <SEO
        title="Learning Onboarding | Techstarta"
        url="https://techstarta.com/onboarding/learner"
        description={'Welcome to Techstarta. Setup your account'}
      />
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#1a202c"
        minHeight="100%"
        backgroundColor="white"
      >
        <Navbar transparent={false} showMenu={false} />

        <div className="flex items-center h-screen justify-between setup w-full">
          <div className="flex w-full md:w-2/3 items-center justify-center setup-buttons onboarding-glass-bg">
            <Flex flexDir="column" className="width-80">
              <Steps
                colorScheme={'gray'}
                activeStep={activeStep}
                checkIcon={FiCheckCircle}
                labelOrientation="vertical"
              >
                {steps.map(({ label, component, icon, index }) => (
                  <Step label={label} key={index} icon={() => icon}>
                    <Flex p={2}>{component}</Flex>
                  </Step>
                ))}
              </Steps>
              {activeStep === steps.length ? (
                <Flex px={4} py={4} width="100%" flexDirection="column">
                  <Heading fontSize="xl" textAlign="center">
                    Woohoo! All steps completed!
                  </Heading>
                  <Button
                    mx="auto"
                    mt={6}
                    size="sm"
                    onClick={() => navigate('/home')}
                  >
                    Continue to your Dashboard
                  </Button>
                </Flex>
              ) : null}
            </Flex>
          </div>
          <div className="hidden sm:block w-full md:w-1/3">
            <Flex
              height="100%"
              align={'center'}
              flexDirection="column"
              justifyContent={'center'}
            >
              <Text className="text-left text-lg font-semibold pt-8">
                Techstarta Community
              </Text>
              <Stack spacing={'4'} p={'12'}>
                <Stack
                  bg={'white'}
                  key={1}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />
                  <Text fontWeight={600}>Join 900+ techies</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Join a community of techies from all over the world and get
                    support from our mentors.
                  </Text>
                </Stack>
                <Stack
                  key={2}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Build your skills & portfolio</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Solve real-world challenges to build your portfolio and
                    boost your confidence.
                  </Text>
                </Stack>
                <Stack
                  key={3}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Get Feedback & guidance</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Get feedback and access to 1-on-1 mentoring sessions with
                    industry experts.
                  </Text>
                </Stack>

                <Stack
                  key={4}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Learn with bite-sized lessons</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Learn with high-quality resources hand-picked for you to
                    learn effectively.
                  </Text>
                </Stack>
              </Stack>
            </Flex>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default withRouter(LearnerOnboarding);
