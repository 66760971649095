import React from "react";

import { Box, Skeleton } from "@chakra-ui/react";

const DetailedStorySkeleton = () => {
    return (
      <>
        <Box color="gray.500" mt="30px">
          <Box fontSize="60px">
            <Skeleton h="40px"> </Skeleton>
          </Box>
          <Box display="flex" mt="10px" flexDirection="column">
            <Skeleton mt="10px" h="20px">
              {" "}
            </Skeleton>
            <Skeleton mt="10px" h="30px">
              {" "}
            </Skeleton>
          </Box>
        </Box>
        <Box mt="40px">
          <Box mt="40px">
            <Box color="#718096">
              <Skeleton mt="20px" h="20px">
                {" "}
              </Skeleton>
              <Skeleton mt="20px" h="40px">
                {" "}
              </Skeleton>
            </Box>
          </Box>
  
          <Box mt="40px">
            <Box color="#718096">
              <Box color="#718096">
                <Skeleton mt="20px" h="20px">
                  {" "}
                </Skeleton>
                <Skeleton mt="20px" h="40px">
                  {" "}
                </Skeleton>
              </Box>
            </Box>
          </Box>
          <Box mt="40px">
            <Skeleton h="30px"> </Skeleton>
            <Box color="#718096">
              <Skeleton mt="20px" h="20px">
                {" "}
              </Skeleton>
              <Skeleton mt="20px" h="40px">
                {" "}
              </Skeleton>
            </Box>
          </Box>
          <Box bg="whitesmoke" mt="40px" p="20px" pt="40px">
            <Skeleton mt="20px" h="30px">
              {" "}
            </Skeleton>
          </Box>
        </Box>
      </>
    );
  };

export default DetailedStorySkeleton;