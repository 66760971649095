import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Tag,
  Text,
} from '@chakra-ui/react';

const MenteeCard = ({ mentee, isLoading, handleAccept, handleIgnore }) => {
  return (
    <>
      <Skeleton isLoaded={!isLoading}>
        <LinkBox>
          <Flex
            p={2}
            flexDir={'column'}
            rounded="lg"
            border={'1px solid'}
            borderColor={'gray.300'}
            _hover={{ shadow: 'lg' }}
          >
            <Box pb={4}>
              <Image
                rounded={'lg'}
                src={mentee.profile.image}
                alt={mentee.profile.fullname}
                objectFit="cover"
                h={'52'}
                w={'full'}
              />
            </Box>
            <Box p={1}>
              <Box d="flex" alignItems="baseline">
                <LinkOverlay href={`/profile/${mentee.profile.username}`}>
                  <Text
                    color="gray.700"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="md"
                    textTransform="capitalize"
                  >
                    {mentee.profile.fullname}
                  </Text>
                </LinkOverlay>
              </Box>
              <Box>
                <Text
                  fontSize={'sm'}
                  textColor={'gray.700'}
                  mt="1"
                  fontWeight="normal"
                  alignItems={'center'}
                  justifyItems={'center'}
                  noOfLines={2}
                  as="h4"
                >
                  {mentee.profile.bio}
                </Text>
              </Box>
              <Box py={4} className="font-normal text-md ">
                <Text color="gray.700" fontSize={'sm'} fontWeight={'semibold'}>
                  Learning
                </Text>
                <Text color="gray.700" fontSize={'sm'}>
                  {mentee.track.title}
                </Text>
              </Box>
              {mentee.status === 'pending' && (
                <Flex pt={2}>
                  <ButtonGroup>
                    <Button
                      onClick={() => handleIgnore(mentee.mentorship_id)}
                      textColor={'gray.600'}
                      rounded={'full'}
                      size="sm"
                      variant={'outline'}
                    >
                      Ignore
                    </Button>
                    <Button
                      _focus={{ borderColor: 'orange.500', borderWidth: '2px' }}
                      _hover={{ borderColor: 'orange.500', borderWidth: '2px' }}
                      rounded={'full'}
                      size="sm"
                      borderColor={'orange.400'}
                      bgColor={'gray.600'}
                      textColor={'white'}
                      onClick={() => handleAccept(mentee.mentorship_id)}
                      variant={'solid'}
                    >
                      Accept
                    </Button>
                  </ButtonGroup>
                </Flex>
              )}
            </Box>
          </Flex>
        </LinkBox>
      </Skeleton>
    </>
  );
};

export default MenteeCard;
