import React from 'react';
import { Tag } from '@chakra-ui/react';
import {
  ArrowPathIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckBadgeIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';

const SubmissionStatus = ({ status }) => {
  switch (status) {
    case 'open_for_review':
      return (
        <Tag
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'green'}
        >
          <ChatBubbleBottomCenterTextIcon className="mr-1 w-4 h-4" />
          Open for Review
        </Tag>
      );
    case 'graded':
      return (
        <Tag
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'purple'}
        >
          <CheckBadgeIcon className="mr-1 w-4 h-4" /> Graded
        </Tag>
      );
    case 'work_in_progress':
      return (
        <Tag
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'gray'}
        >
          <ArrowPathIcon className="mr-1 w-4 h-4" /> Work in progress
        </Tag>
      );
    case 'rejected':
      return (
        <Tag
          fontSize={'xs'}
          fontWeight={'semibold'}
          rounded={'lg'}
          colorScheme={'red'}
        >
          <XCircleIcon className="mr-1 w-4 h-4" /> Rejected
        </Tag>
      );
  }
};

export default SubmissionStatus;
