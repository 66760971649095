import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Tr,
  Th,
  useToast,
  Flex,
  Text,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import withRouter from '../utils/withRouter.js';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import SubmissionService from '../services/submissions-service';
import {
  SubmissionItem,
  SubmissionItemSkeleton,
} from '../components/SubmissionItem.js';

const Submissions = () => {
  const toast = useToast();
  const [openSubmissions, setOpenSubmissions] = useState([]);
  const [gradedSubmissions, setGradedSubmissions] = useState([]);

  useEffect(() => {
    async function fetchOpenReviews() {
      try {
        const response = await SubmissionService.listSubmissions(
          null,
          'open_for_review'
        );
        if (response.status === 200) {
          const data = response.data.submissions;
          setOpenSubmissions(data);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          toast({
            title: 'Oops!',
            description: 'An error occurred.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
    fetchOpenReviews();
  }, [toast]);

  useEffect(() => {
    async function fetchGradedSubmission() {
      try {
        const response = await SubmissionService.listSubmissions(
          null,
          'graded'
        );
        if (response.status === 200) {
          const data = response.data.submissions;
          setGradedSubmissions(data);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          toast({
            title: 'Oops!',
            description: 'An error occurred.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
    fetchGradedSubmission();
  }, [toast]);

  return (
    <>
      <Helmet>
        <meta name="description" />
        <title>{`Submissions to real-world challenges | Techstarta`}</title>
      </Helmet>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar title="Submissions" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg">
                <div className="rounded-t mb-0 border-0">
                  <Flex
                    className="bg-gradient-to-bl from-cyan-400 to-gray-100  rounded-lg overflow-hidden border"
                    p={['10px', '30px', '40px', '40px']}
                    w="auto"
                    mt={['50px', '50px', '50px', '0px']}
                    justifyContent={[
                      'center',
                      'center',
                      'center',
                      'space-between',
                    ]}
                    flexDirection={['column', 'column', 'column', 'row']}
                    mb="10px"
                    rounded="lg"
                  >
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      color="gray.700"
                      flexDirection="column"
                    >
                      <Heading textAlign="left" as={'h1'}>
                        Provide feedback on submissions
                      </Heading>

                      <Text textAlign="left" fontSize="sm">
                        Help learners improve their skills by providing
                        constructive feedback on their submissions. Click on a
                        submission to view and provide feedback.
                      </Text>
                    </Flex>
                  </Flex>
                  <Box mt={8} display="flex" flexDirection="column">
                    <Tabs colorScheme={'gray'}>
                      <TabList>
                        <Tab _focus={{ outline: 'none' }}>Open for review</Tab>
                        <Tab _focus={{ outline: 'none' }}>Graded</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel ml={'-6'}>
                          <Flex
                            w={['100%', '100%', '100%', '70%']}
                            flexDirection="column"
                          >
                            {openSubmissions ? (
                              openSubmissions.map((submission, index) => {
                                return (
                                  <SubmissionItem
                                    key={index}
                                    submission={submission}
                                    index={index}
                                  />
                                );
                              })
                            ) : (
                              <>
                                <SubmissionItemSkeleton />
                                <SubmissionItemSkeleton />
                                <SubmissionItemSkeleton />
                                <SubmissionItemSkeleton />
                                <SubmissionItemSkeleton />
                              </>
                            )}
                          </Flex>
                          {openSubmissions && openSubmissions.length === 0 && (
                            <Flex h={'16'} p={4}>
                              <Text textColor={'gray.600'}>
                                Submissions that require your feedback will
                                appear here.
                              </Text>
                            </Flex>
                          )}
                        </TabPanel>
                        <TabPanel ml={'-6'}>
                          <Flex
                            w={['100%', '100%', '100%', '70%']}
                            flexDirection="column"
                          >
                            {gradedSubmissions ? (
                              gradedSubmissions.map((submission, index) => {
                                return (
                                  <SubmissionItem
                                    key={index}
                                    submission={submission}
                                    index={index}
                                  />
                                );
                              })
                            ) : (
                              <>
                                <SubmissionItemSkeleton />
                                <SubmissionItemSkeleton />
                                <SubmissionItemSkeleton />
                                <SubmissionItemSkeleton />
                                <SubmissionItemSkeleton />
                              </>
                            )}
                          </Flex>
                          {gradedSubmissions &&
                            gradedSubmissions.length === 0 && (
                              <Flex h={'16'} p={4}>
                                <Text textColor={'gray.600'}>
                                  Submissions that you have graded will appear
                                  here.
                                </Text>
                              </Flex>
                            )}
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Box>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Submissions);
