import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Avatar,
  Text,
  Box,
  Skeleton,
  Spacer,
  Divider,
  Center,
  Show,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  InputGroup,
  InputRightElement,
  Input,
  useClipboard,
  LinkBox,
  IconButton,
  Link,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faMeteor,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { FaLink, FaLinkedin, FaTwitter } from 'react-icons/fa';

export const ProfileSection = ({ profile, currentUser, username }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const profileLink = `https://www.techstarta.com/profile/${profile.username}`;
  const { hasCopied, onCopy } = useClipboard(profileLink);
  const shareTitle = 'Check out my Techstarta portfolio';

  const topSkill = profile.skills.reduce(
    (prev, current) => {
      return prev.tier.level > current.tier.level ? prev : current;
    },
    { title: '', tier: { level: 0 } }
  );

  const CustomOverlay = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const SkillRanking = ({ bgColor, icon, skill, tier }) => (
    <Flex border="1px" borderColor={'gray.300'} rounded={'md'} p={4}>
      <Flex
        className={`${bgColor}`}
        height={'10'}
        width="10"
        justify={'center'}
        alignItems="center"
        rounded={'md'}
        mr="2"
      >
        {icon}
      </Flex>
      <VStack alignItems={'flex-start'} spacing="-1">
        <Text
          fontSize={'md'}
          fontWeight="semibold"
          textColor={'brand.darkBlue'}
        >
          {skill}
        </Text>
        <Text fontSize={'sm'} fontWeight={'semibold'}>
          {tier}
        </Text>
      </VStack>
    </Flex>
  );

  return (
    <>
      <Flex
        p={['10px', '10px', '20px', '20px']}
        color="#718096"
        flexWrap="wrap"
        justify={['center', 'center', 'center', 'space-between']}
      >
        <Flex mr={['0px', '10px', '15px', '20px']} flexDirection="column">
          <Avatar
            w="200px"
            h="200px"
            mb="25px"
            fontSize="45px"
            borderRadius="50%"
            name={profile.fullname}
            src={profile.image}
            shadow="lg"
          />
          <Flex alignSelf={'center'}>
            <HStack>
              <Link
                href={`https://twitter.com/${profile.twitter}`}
                target="_blank"
              >
                <IconButton
                  rounded={'full'}
                  icon={<FaTwitter className="w-4 h-4 text-gray-700" />}
                />
              </Link>
              <Link href={profile.linkedin} target="_blank">
                <IconButton
                  rounded={'full'}
                  icon={<FaLinkedin className="w-4 h-4 text-gray-700" />}
                />
              </Link>
              <Link href={profile.personal_website} target="_blank">
                <IconButton
                  rounded={'full'}
                  icon={<FaLink className="w-4 h-4 text-gray-700" />}
                />
              </Link>
            </HStack>
          </Flex>
        </Flex>

        <Flex
          fontSize="14px"
          mt={['20px', '10px', '0px']}
          flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
          direction={['column']}
        >
          <Box mb="25px" textAlign={['center', 'start', 'center', 'start']}>
            <Text color="#2d3748" fontSize="25px" fontWeight="700">
              {profile.fullname}{' '}
              {profile.is_mentor && (
                <FontAwesomeIcon aria-label="mentor" icon={faCheckCircle} />
              )}
            </Text>
            <Text>
              {'Member since ' +
                moment(profile.member_since).format('MMMM, YYYY')}
            </Text>
          </Box>
          <Box mb="25px">
            <Text mb="5px" fontSize="15px" fontWeight="700" color="#2d3748">
              Bio
            </Text>
            <Text>{profile.bio}</Text>
          </Box>
          <Box>
            <Text mb="5px" fontSize="15px" fontWeight="700" color="#2d3748">
              Learning
            </Text>
            <Text>{profile.tracks.map(item => item.title).join(', ')}</Text>
          </Box>
        </Flex>
        <Show above="sm">
          <Center height="250px">
            <Divider orientation="vertical" />
          </Center>
        </Show>
        <Flex
          flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 20%']}
          mt={['20px', '20px', '20px', '0px']}
          ml={['0px', '0px', '0px', '20px']}
          direction={['column']}
        >
          <SkillRanking
            icon={
              <FontAwesomeIcon
                icon={faMeteor}
                className="h-8 w-8 text-cyan-500"
              />
            }
            skill={topSkill.title}
            bgColor={'bg-cyan-100'}
            tier={topSkill.tier.label}
          />

          <Spacer my="25px" height="50px" />
          <HStack alignSelf="right" justify={'flex-end'}>
            <Button
              onClick={onOpen}
              size="sm"
              bgColor={'gray.700'}
              _hover={{ shadow: 'md' }}
              color="white"
              rounded={'lg'}
              _focus={{ outlineColor: 'gray' }}
              rightIcon={
                <FontAwesomeIcon
                  icon={faShareAlt}
                  className="text-white"
                  _focus={{ outline: 'none', outlineColor: 'gray' }}
                />
              }
            >
              Share
            </Button>
            {currentUser && username === currentUser.username && (
              <Button
                _active={{ outline: 'none' }}
                onClick={event => {
                  event.preventDefault();
                  navigate('/settings');
                }}
                size="sm"
                variant={'outline'}
                color="white"
                textColor={'gray.600'}
                rounded={'lg'}
              >
                Edit
              </Button>
            )}
          </HStack>
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <CustomOverlay />
            <ModalContent>
              <ModalHeader alignSelf={'center'} justifySelf="center">
                Share your portfolio with your social community
              </ModalHeader>
              <ModalCloseButton _focus={{ outline: 'none' }} />
              <ModalBody>
                <HStack spacing={2} pb="4">
                  <LinkedinShareButton
                    title={shareTitle}
                    summary="I'm building my portfolio with Techstarta"
                    url={profileLink}
                    source={profileLink}
                  >
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                  <TwitterShareButton
                    title={shareTitle}
                    url={profileLink}
                    via="techstarta"
                    hashtags={['LearnWithTechstarta', 'BuildWithTechstarta']}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  <WhatsappShareButton title={shareTitle} url={profileLink}>
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                  <EmailShareButton subject={shareTitle} body={profileLink}>
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                </HStack>
                <Text
                  textColor={'gray.600'}
                  fontSize={'sm'}
                  fontWeight="semibold"
                >
                  or copy link
                </Text>
                <Box py={4}>
                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      type={'text'}
                      bgColor="gray.100"
                      readOnly={true}
                      value={profileLink}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={onCopy}>
                        {hasCopied ? 'Copied' : 'Copy'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      </Flex>
    </>
  );
};

export const ProfileSkeleton = () => {
  return (
    <>
      <Flex
        p={['10px', '10px', '20px', '20px']}
        color="#718096"
        flexWrap="wrap"
        justify={['center', 'center', 'center', 'space-between']}
      >
        <Box mr={['0px', '10px', '15px', '20px']}>
          <Skeleton
            w="200px"
            h="200px"
            mb="25px"
            rounded="50%"
            shadow="lg"
          ></Skeleton>
          <Skeleton height="20px"></Skeleton>
        </Box>

        <Flex
          fontSize="14px"
          mt={['20px', '10px', '0px']}
          flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
          direction={['column']}
        >
          <Box mb="25px" textAlign={['center', 'start', 'center', 'start']}>
            <Skeleton mb="10px" height="30px"></Skeleton>
            <Skeleton height="15px"></Skeleton>
          </Box>
          <Box>
            <Skeleton height="20px"></Skeleton>
            <Skeleton mt="10px" height="100px"></Skeleton>
          </Box>
        </Flex>

        <Flex
          flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 0 20%']}
          mt={['20px', '20px', '20px', '0px']}
          ml={['0px', '0px', '0px', '20px']}
          direction={['column']}
        >
          <Box>
            <Skeleton mb="25px" height="50px"></Skeleton>
          </Box>
          <Box>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
            <Skeleton mb="5px" height="10px"></Skeleton>
          </Box>
          <Skeleton my="25px" height="50px"></Skeleton>

          <Skeleton height="50px"></Skeleton>
        </Flex>
      </Flex>
    </>
  );
};
