const filterSearch = {
    FILTER_BY_ANY: "FILTER_BY_ANY",
    FILTER_BY_NAME: "FILTER_BY_NAME",
    FILTER_BY_INDUSTRY: "FILTER_BY_INDUSTRY",
    FILTER_BY_CURRENT_ROLE: "FILTER_BY_CURRENT_ROLE",
    FILTER_BY_MALE: "FILTER_BY_MALE",
    FILTER_BY_FEMALE: "FILTER_BY_FEMALE",
    FILTER_BY_TODAY: "FILTER_BY_TODAY",
    FILTER_BY_WEEK: "FILTER_BY_WEEK",
    FILTER_BY_MONTH: "FILTER_BY_MONTH",
    FILTER_BY_YEAR: "FILTER_BY_YEAR",
    FILTER_BY_ALL_TIME: "FILTER_BY_ALL_TIME"
}

export default filterSearch;
