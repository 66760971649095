import React from "react";
import {
    Box,
    Input,
    Alert,
    AlertIcon,
    Text,
    Textarea,
    FormControl,
    FormLabel,
} from "@chakra-ui/react";

const TextInput = ({
    title,
    element,
    type,
    value,
    onChangeFunction,
    placeholder,
    minValue,
    isreadonly,
    addMarginTop,
    showError,
    errorMessage,
    maxlength,
    isRequired,
    helperText,
}) => {
    return (
        <Box
            mx="10px"
            w={element === "textArea" ? ["98%"] : ["100%", "100%"]}
            mt={addMarginTop ? ["20px", "20px", "20px", "0px"] : null}
        >
            <FormControl isRequired={isRequired}>
                <FormLabel>{title}</FormLabel>
                {element === "textArea" ? (
                    <Textarea
                        focusBorderColor="#dd6b20"
                        color="#2d3748"
                        onChange={onChangeFunction}
                        isRequired={isRequired}
                        placeholder={placeholder}
                        maxLength="255"
                        value={value}
                        isReadOnly={isreadonly}
                        fontSize={"sm"}
                    />
                ) : (
                    <Input
                        focusBorderColor="#dd6b20"
                        onChange={onChangeFunction}
                        color="#2d3748"
                        type={type}
                        min={minValue}
                        placeholder={placeholder}
                        value={value}
                        isReadOnly={isreadonly}
                        maxLength={maxlength}
                        fontSize={"sm"}
                    />
                )}
                {/* { helperText ? (<FormHelperText>{helperText}</FormHelperText>): ""} */}
                { showError && <Text
                    borderRadius="md"
                    fontSize="sm"
                    mt="1"
                    textColor="red.500"
                >
                    {errorMessage}
                </Text>}
            </FormControl>
        </Box>
    );
};

export default TextInput;
