import React, { useState, useEffect } from 'react';
import AuthService from '../services/auth-service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';


const AccountVerificationHandler = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isUserVerified, setUserVerification] = useState(false);

  const location = useLocation().search;
  const queryValues = queryString.parse(location);

  useEffect(() => {
    async function confirmSubscription() {
      const payload = {
        email: queryValues.email,
        verification_code: queryValues.code,
      };
      try {
        setLoading(true);
        const response = await AuthService.confirmEmailVerification(payload);
        await AuthService.fetchCurrentUser();
        if (response.status === 200) {
          setUserVerification(true);
          setLoading(false)
        }
      } catch (err) {
        if (err) {
          setMessage(err.data.user.message);
          setUserVerification(false);
          setLoading(false);
        }
      }
    }
    confirmSubscription();
  }, []);

  return (
    <div className="h-screen">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full md:w-8/12 px-4">
          <>
            {!isLoading && isUserVerified && (
              <Box
                rounded="md"
                bg="white"
                p="20px"
                boxShadow="lg"
                textAlign={'center'}
              >
                <Flex
                  flexDirection={'column'}
                  p="20px"
                  align="center"
                  justify="center"
                >
                  <Text mt="4" className="text-xl md:text-2xl font-black">
                    Your email is now verified!
                  </Text>

                  <Text mt="5" mb="2">
                    <span className="underline text-orange-500">
                      <Link to="/login" color="orange.700">
                        Continue
                      </Link>
                    </span>
                  </Text>
                </Flex>
              </Box>
            )}

            {!isLoading && !isUserVerified && (
                    <Alert
                      m="3"
                      status="error"
                      variant="subtle"
                      flexDirection="column"
                      justifyContent="center"
                      textAlign="center"
                      height="200px"
                      boxShadow="lg"
                    >
                      <AlertIcon size="40px" mr={0} />
                      <AlertTitle mt={4} mb={1} fontSize="lg">
                        Oops! We couldn not verify your email address.
                      </AlertTitle>
                      <AlertDescription maxWidth="sm">
                        {message}
                      </AlertDescription>
                    </Alert>
            )}


            {isLoading && (
              <Flex
                pt="10px"
                w="100%"
                h="100%"
                align="center"
                justify="center"
              >
                <Box
                  rounded="md"
                  w={['100%', '100%', '100%', '70%']}
                  h="50%"
                  bg="white"
                >
                  <Flex p="20px" align="center" justify="center">
                    <Spinner
                      size="xl"
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="orange.500"
                    />
                  </Flex>
                  <Text
                    textAlign="center"
                    color="gray"
                    p="10px"
                    fontSize="sm"
                  >
                    {' '}
                    Please wait while we verify your email...{' '}
                  </Text>
                </Box>
              </Flex>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default AccountVerificationHandler;
