import React from 'react';
import {
  Flex,
  Heading,
  Text,
  Select,
  Stack,
  useToast,
  Button,
} from '@chakra-ui/react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import { FaLightbulb } from 'react-icons/fa';
import SEO from '../components/Common/SEO.js';
import withRouter from '../utils/withRouter.js';

const Projects = () => {
  return (
    <>
      <SEO
        title="Solve real-world challenges | Techstarta"
        url="http://techstarta.com/challenges"
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar title="Opportunities" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="relative bg-white md:pt-32 pb-16"></div>
        <div className="md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 border-0">
                  <Flex
                    bg="#152340"
                    p={['10px', '30px', '40px', '50px']}
                    w="auto"
                    mt={['50px', '50px', '50px', '0px']}
                    justifyContent={[
                      'center',
                      'center',
                      'center',
                      'space-between',
                    ]}
                    flexDirection={['column', 'column', 'column', 'row']}
                    mx={['10px', '20px']}
                    mb="10px"
                    rounded="lg"
                  >
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      color="#ffffff"
                      flexDirection="column"
                    >
                      <Heading
                        textAlign={['center', 'center', 'center', 'left']}
                      >
                        {' '}
                        Real-world problems. Gain hands-on experience.{' '}
                        <span role="img" aria-label="muscle">
                          {' '}
                          &#128170;{' '}
                        </span>{' '}
                      </Heading>
                      <Text
                        textAlign={['center', 'center', 'center', 'left']}
                        fontSize="sm"
                      >
                        {' '}
                        We launch challenges on a regular basis so that you can
                        hone your skills, develop your confidence, and build
                        your portfolio. Find a new challenge below and submit
                        your solution.
                      </Text>
                      <Stack py="15px" isInline spacing="10px">
                        <Button
                          rightIcon={<FaLightbulb />}
                          size="sm"
                          variant="outline"
                          borderRadius="full"
                          _focus={{ outline: 'none' }}
                          _hover={{
                            variant: 'outline',
                            shadow: 'lg',
                            textColor: 'brand.lightBlue',
                          }}
                        >
                          See solutions
                        </Button>
                      </Stack>
                    </Flex>
                  </Flex>
                  <Flex mt="20px" flexDirection="column">
                    <Flex
                      mt={['50px', '50px', '50px', '20px']}
                      py="20px"
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Flex>
                        <Heading
                          color="#2d3748"
                          size="lg"
                          mx="20px"
                          justifyContent="space-between"
                        >
                          Join a Community Project
                        </Heading>
                      </Flex>
                    </Flex>
                    <Flex px={4} flexDirection={'column'}>
                      <div className="flex flex-wrap items-center mt-20">
                        <iframe
                          className="airtable-embed"
                          src="https://airtable.com/embed/shrtXpOQBrTPcwebD?backgroundColor=orange&layout=card&viewControls=on"
                          frameBorder="0"
                          width="100%"
                          height="533"
                          style={{
                            background: 'transparent',
                            border: '1px solid #ccc',
                          }}
                        ></iframe>
                      </div>
                    </Flex>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Projects);
