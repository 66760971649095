import React from 'react';
import moment from 'moment';
import { Avatar, Flex, Icon, Stack, Text } from '@chakra-ui/react';

export const ContentAuthorCard = ({ author }) => {
  return (
    <>
      <Flex pb={4} rounded="md" width="100%" direction="column">
        <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
          <Avatar name={author.fullname} src={author.image} alt={'Author'} />
          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            <Text fontWeight={500}>Curated by {author.fullname}</Text>
            <Text color={'gray.600'} noOfLines={'1'}>
              {author.bio}
            </Text>
          </Stack>
        </Stack>
        {/* {author.date_joined && author.total_points ? (
          <Flex mt="4">
            <Stack direction={'row'}>
              <Stack isInline>
                <Icon color="gray.500" as={FaCalendarAlt}></Icon>
                <Text color={'gray.500'} fontSize="xs">
                  {' '}
                  Joined {moment(author.date_joined).format('MMMM YYYY')}
                </Text>
              </Stack>
              <Text>&bull;</Text>
              <Stack isInline>
                <Text color={'gray.500'} fontSize="xs">
                  {' '}
                  {author.total_points + ' '} points
                </Text>
              </Stack>
            </Stack>
          </Flex>
        ) : null} */}
      </Flex>
    </>
  );
};
