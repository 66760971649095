import React from "react";
import moment from "moment";
import { Avatar, Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import Linkify from 'react-linkify';
import { FaAngleUp } from "react-icons/fa";

const CommentBox = ({ comment, upvoteFn }) => {
  return (
    <>
      <Flex p={["10px", "10px", "10px", "20px"]}>
        <Flex>
          <Avatar size="sm" name={comment.author.fullname} src={comment.author.image}/>
          <div>
            <Box marginX="10px" padding="10px" rounded="lg" bg="blue.50" width={["90%", "95%", "95%", "100%"]}>
              <div className="font-semibold text-sm leading-relaxed">{comment.author.fullname}</div>
              <div className="text-base leading-snug md:leading-normal pt-5">

                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a className="text-orange-600" target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
                        {decoratedText}
                    </a>
                )}>{comment.body}</Linkify>
              </div>
            </Box>
            <HStack mx="10px" my="1">
                <Button onClick={(event) => { event.preventDefault(); upvoteFn(comment.id);}} leftIcon={<FaAngleUp />} size="xs" variant="ghost">Upvote ({comment.upvotes})</Button>
                <Text paddingX="10px" fontSize="xs" color="gray.600">
                {moment(comment.created_at).fromNow()}
                </Text>
            </HStack>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default CommentBox;
