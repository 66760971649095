import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import DiscussionItem from "../Discussion/DiscussionItem";

const DiscussionList = ({ discussions }) => {

    return (
        <>
            { discussions.length === 0 
            ? <Flex> <Text>Published discussions will appear here. </Text></Flex> 
            : discussions.map(discussion => (<DiscussionItem key={discussion.slug} discussion={discussion} />))}
        </>
    )
}

export default DiscussionList; 