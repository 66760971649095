import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Text,
  Button,
  Skeleton,
  HStack,
  Heading,
} from '@chakra-ui/react';
import moment from 'moment';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export const SubmissionItem = ({ submission, index }) => {
  const navigate = useNavigate();

  return (
    <>
      <Flex
        rounded={'lg'}
        border="1px"
        borderColor={'gray.100'}
        p="4"
        m={'4'}
        flexDirection="column"
      >
        <Text textColor={'gray.500'} fontSize={'sm'} pb="2">
          {submission.challenge.track}
        </Text>

        <Heading fontSize={'md'} as="h4" className="mb-2 text-gray-600">
          {submission.challenge.title}
        </Heading>
        <HStack textAlign={'center'} pt={2}>
          <Text textColor={'gray.600'} fontSize="sm">
            Submitted by{' '}
            <a
              className="font-semibold text-stone-600 hover:underline"
              href="#"
            >
              {submission.submitter.fullname}
            </a>
          </Text>
        </HStack>
        <Flex pt={4} justifyContent="space-between" alignItems="center">
          <HStack>
            <CalendarDaysIcon className="h-4 w-4 text-gray-500" />
            <Text textColor={'gray.500'} fontSize="sm">
              {moment(`${submission.updated_at}`.split('T')[0])
                .utc()
                .format('MMM D, YYYY')}
            </Text>
          </HStack>
          <Button
            size={'sm'}
            rounded={'full'}
            variant={'outline'}
            rightIcon={<ChevronRightIcon className="h-4 w-4" />}
            onClick={event => {
              event.preventDefault();
              navigate(`/submissions/${submission.id}`);
            }}
          >
            Details
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export const SubmissionItemSkeleton = () => {
  return <Skeleton></Skeleton>;
};
