import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Text,
  FormLabel,
  FormControl,
  Box,
  Center,
  HStack,
  Skeleton,
  Link,
  FormHelperText,
  Progress,
  useToast,
  SimpleGrid,
  Image,
  Heading,
} from '@chakra-ui/react';
import { RadioGroup } from '@headlessui/react';
import SEO from '../components/Common/SEO';
import CareerFitQuestions from '../data/careerFitQuestions.json';
import CategoryToPaths from '../data/careerCategoryToPaths.json';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import TrackService from '../services/track-service';
import { groupBy } from '../utils/groupBy';
import withRouter from '../utils/withRouter.js';
import YoutubeEmbed from '../components/YoutubeEmbed';
import BeatLoader from 'react-spinners/BeatLoader';
import { Mixpanel } from '../utils/Mixpanel';
import Navbar from '../components/Navbar';
import FooterSmall from '../components/FooterSmall';
import AuthService from '../services/auth-service';
import { PlayCircleIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import quizImage from '../images/pexels-christina-morillo-1181414.jpg';
import careerFitResultImage from '../images/pexels-andrea-piacquadio-3762940.jpg';
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import splitbee from '@splitbee/web';

const Pathfinder = () => {
  Mixpanel.track('Career Fit Quiz');
  splitbee.track('Career Fit Quiz');
  const navigate = useNavigate();
  const toast = useToast();
  const [questions, setQuestions] = useState(CareerFitQuestions.questions);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [careerFit, setCareerFit] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openQuiz, setOpenQuiz] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selected, setSelected] = useState(null);
  const [nextQuestionLoading, setNextQuestionLoading] = useState(false);
  const quizLink = 'https://www.techstarta.com/quiz';

  const showQuiz = () => {
    setOpenQuiz(true);
  };

  const handleOnAnswerSelected = value => {
    setSelected(value);
    setSelectedAnswer(value);
    setShowErrorMessage(false);
    setAnswers([
      ...answers,
      questions[currentQuestionIndex].answers.find(
        answer => answer.id === value
      ),
    ]);
    setProgress(((currentQuestionIndex + 1) / questions.length) * 100);
    if (currentQuestionIndex < questions.length - 1) {
      setNextQuestionLoading(true);
      setTimeout(() => {
        setSelectedAnswer(null);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setNextQuestionLoading(false);
      }, 1000);
    }
  };

  const handlePrevious = event => {
    event.preventDefault();
    if (currentQuestionIndex > 0) {
      setSelectedAnswer(null);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      answers.pop();
      setAnswers(answers);
    }
  };

  const handleSeeCareerFit = event => {
    Mixpanel.track('Found Career Fit');

    if (selectedAnswer !== null) {
      setIsLoading(true);
      event.preventDefault();
      const answersByCategory = groupBy(answers, 'category');
      const careerCategoryScores = [];
      for (const [key, value] of Object.entries(answersByCategory)) {
        const categoryScore = {};
        categoryScore[key] = value.length;
        careerCategoryScores.push(categoryScore);
      }
      careerCategoryScores.sort((a, b) => b.key - a.key);
      const careerFit =
        CategoryToPaths[Object.keys(careerCategoryScores[0])[0]];
      setTimeout(() => {
        setCareerFit(careerFit);
        setShowResult(true);
        setIsLoading(false);
      }, 1000);
    } else {
      setShowErrorMessage(true);
    }
  };

  const handleJoinTrack = async () => {
    const user = JSON.parse(AuthService.getCurrentUser());
    if (user) {
      try {
        const username = user.username;
        const response = await TrackService.joinTrack(
          careerFit.primaryTrack.slug,
          username
        );
        if (response === 200) {
          toast({
            title: 'Great!',
            description: 'Learning Path joined successfully!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
        navigate('/home');
      } catch (error) {
        if (error.status === 400 || 403) {
          alert(error.data.errors);
          toast({
            title: 'Error!',
            description: 'Can not join learning path. Please try again!',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } else {
      navigate('/login');
    }
  };

  const Result = (
    <Flex
      className=""
      p={{ xs: '30px', sm: '30px', md: '10px' }}
      w="100%"
      justifyContent={'center'}
      alignItems="center"
      flexDirection={'column'}
      mx={'auto'}
      mb="10px"
    >
      <Flex
        flexDir={'column'}
        justifyContent="center"
        alignContent="center"
        color="gray.700"
        mt="60px"
        w="100%"
        mx="auto"
      >
        <Flex
          border={'1px solid #e2e8f0'}
          flexDirection={['column', 'column', 'row', 'row']}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width={['100%', '100%', '50%', '50%']} p={4}>
            <Box>
              <Skeleton isLoaded={careerFit}>
                {careerFit && (
                  <Heading
                    as={'h1'}
                    textColor={'gray.700'}
                    fontSize={'2xl'}
                    fontWeight={'semibold'}
                  >
                    Your Tech Career fit is{' '}
                    <span className="underline text-gray-700">
                      {careerFit.primaryTrack.name}
                    </span>
                  </Heading>
                )}
              </Skeleton>
            </Box>
            <Box py={2}>
              <Skeleton isLoaded={careerFit}>
                {careerFit && (
                  <div>
                    <Text
                      fontSize={'sm'}
                      letterSpacing="0.01em"
                      textColor={'gray.700'}
                    >
                      {careerFit.primaryTrack.description}
                    </Text>
                    <Text
                      fontSize={'sm'}
                      pt={2}
                      letterSpacing="0.01em"
                      textColor={'gray.700'}
                    >
                      To learn more about this career path, watch the video
                      below.
                    </Text>
                    <Flex pt={8} pb={4} flexDirection="column">
                      <Text fontSize={'sm'} fontWeight="semibold">
                        Share your career fit
                      </Text>
                      <HStack spacing={2} py="4">
                        <LinkedinShareButton
                          title={'Techstarta Career Quiz'}
                          summary={`Hey! I just found my tech career fit using Techstarta Career quiz. I got ${careerFit.primaryTrack.name}! Find out which tech career fits you.`}
                          url={quizLink}
                          source={quizLink}
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <TwitterShareButton
                          title={`Hey! I just found my tech career fit using Techstarta Career quiz. I got ${careerFit.primaryTrack.name}! Find out which tech career fits you.`}
                          url={quizLink}
                          via="techstarta"
                          hashtags={['SucceedInTech', 'Techstarta']}
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton
                          title={`Hey! I just found my tech career fit using Techstarta Career quiz. I got ${careerFit.primaryTrack.name}! Find out which tech career fits you.`}
                          url={quizLink}
                        >
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <EmailShareButton
                          subject={`Hey! I just found my tech career fit using Techstarta Career quiz. I got ${careerFit.primaryTrack.name}! Find out which tech career fits you.`}
                          body={quizLink}
                        >
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                      </HStack>
                    </Flex>
                  </div>
                )}
              </Skeleton>
            </Box>
            <Box pt={4}>
              {careerFit && careerFit.primaryTrack.slug && (
                <Button
                  size={'md'}
                  fontSize={'sm'}
                  mr={4}
                  bgColor={'brand.darkBlue'}
                  colorScheme="blue"
                  onClick={handleJoinTrack}
                  _hover={{ shadow: 'md' }}
                  borderRadius="16px"
                  textTransform="uppercase"
                  mb="12px"
                >
                  Start learning
                </Button>
              )}
            </Box>
          </Box>
          <Image
            w={['100%', '100%', '50%', '50%']}
            src={careerFitResultImage}
            alt="career fit result"
            objectFit="contain"
          />
        </Flex>
        <Box mt="72px" rounded={'lg'}>
          <Skeleton isLoaded={careerFit}>
            {careerFit && (
              <YoutubeEmbed videoId={careerFit.primaryTrack.videoId} />
            )}
          </Skeleton>
        </Box>

        <Box pb={8}>
          <Skeleton isLoaded={careerFit}>
            <Box mb={'24px'} mt="60px">
              {careerFit && (
                <Text
                  textColor={'gray.600'}
                  fontSize="lg"
                  letterSpacing="wide"
                  fontWeight={'semibold'}
                  lineHeight="28px"
                >
                  Related Specializations
                </Text>
              )}
            </Box>

            {careerFit && (
              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
                {careerFit.adjacentTracks.map((track, index) => (
                  <Flex
                    flexDir={'column'}
                    justifyContent="space-between"
                    key={index}
                    shadow={'md'}
                    border={'1px solid #e2e8f0'}
                    rounded={'lg'}
                    py={6}
                    px={4}
                  >
                    <Flex flexDir={'column'} mb="24px">
                      <Text
                        textAlign="left"
                        textColor={'gray.700'}
                        fontWeight={'semibold'}
                        mb="12px"
                        fontSize={'md'}
                        lineHeight="32px"
                      >
                        {track.name}
                      </Text>
                      <Text
                        textAlign="left"
                        lineHeight={'24px'}
                        fontSize={'sm'}
                        textColor={'gray.700'}
                      >
                        {track.description}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Box>
                        {track.learnMoreUrl && (
                          <Link
                            textColor={'orange.500'}
                            href={track.learnMoreUrl}
                            target="_blank"
                            fontSize={'xs'}
                            rel="noopener noreferrer"
                            textTransform={'uppercase'}
                          >
                            Learn more
                          </Link>
                        )}
                        {track.startLearningUrl && (
                          <Link
                            textColor={'orange.500'}
                            href={track.startLearningUrl}
                            target="_blank"
                            fontSize={'xs'}
                            rel="noopener noreferrer"
                            textTransform={'uppercase'}
                          >
                            Learn more
                          </Link>
                        )}
                      </Box>
                      {track.slug && (
                        <Button
                          variant="solid"
                          bgColor={'brand.darkBlue'}
                          textTransform={'uppercase'}
                          textColor={'white'}
                          fontSize={'xs'}
                          onClick={async () => {
                            const user = JSON.parse(
                              AuthService.getCurrentUser()
                            );
                            if (user) {
                              try {
                                const username = user.username;
                                const response = await TrackService.joinTrack(
                                  track.slug,
                                  username
                                );
                                if (response === 200) {
                                  toast({
                                    title: 'Great!',
                                    description:
                                      'Learning Path joined successfully!',
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                  });
                                }
                                navigate('/home');
                              } catch (error) {
                                if (error.status === 400 || 403) {
                                  alert(error.data.errors);
                                  toast({
                                    title: 'Error!',
                                    description:
                                      'Can not join learning path. Please try again!',
                                    status: 'error',
                                    duration: 3000,
                                    isClosable: true,
                                  });
                                }
                              }
                            } else {
                              navigate('/login');
                            }
                          }}
                        >
                          Start learning
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                ))}
              </SimpleGrid>
            )}
          </Skeleton>
        </Box>
      </Flex>
    </Flex>
  );

  return (
    <>
      <SEO
        title={'Discover your tech career fit'}
        url={'https://techstarta.com/quiz'}
        description={
          'Take the quiz to find out which tech career fits you the most.'
        }
      />

      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#faf9f6"
        position="static"
        overflow="auto"
        backgroundColor="white"
        backgroundSize="100% auto"
        backgroundRepeat="repeat"
        backgroundPosition="center"
      >
        <Navbar transparent={false} showMenu={false} />
        <div className="relative bg-white min-h-screen">
          <div className="lg:px-0 px-4 mx-auto w-full lg:w-10/12 h-full">
            <div className="flex flex-wrap mt-4">
              <div className="w-full mb-12 xl:mb-0 h-full">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                  <div className="rounded-t mb-0 border-0">
                    {!openQuiz && (
                      <Flex
                        className=""
                        w="auto"
                        justifyContent={'center'}
                        alignItems="center"
                        flexDirection={'column'}
                        mb="10px"
                      >
                        <Flex
                          alignContent="center"
                          justifyContent="center"
                          color="gray.700"
                          flexDirection="column"
                          textAlign={'center'}
                          h="100%"
                          mt="60px"
                        >
                          <Box
                            py="80px"
                            px={['20px', '40px', '40px', '40px']}
                            bgImage={quizImage}
                            bgRepeat="no-repeat"
                            rounded="lg"
                            shadow={'md'}
                            mt="40px"
                          >
                            <Text
                              fontSize={'32px'}
                              textColor="gray.700"
                              fontWeight="bold"
                              mb="32px"
                            >
                              Discover your tech career fit
                            </Text>
                            <Text
                              fontSize="lg"
                              mb="32px"
                              w={['100%', '80%']}
                              textColor="gray.700"
                              mx="auto"
                            >
                              A career in tech can be a rewarding and exciting
                              path, with many opportunities for personal and
                              professional growth. We have designed this quiz to
                              help you identify your interests and goals. So
                              take your time to answer the questions as honestly
                              as you can.
                            </Text>
                            <Button
                              bgColor={'brand.darkBlue'}
                              textColor={'white'}
                              rounded="full"
                              mx="auto"
                              p={['12px', '24px']}
                              _hover={{ shadow: 'lg' }}
                              rightIcon={<PlayCircleIcon className="w-4 h-4" />}
                              onClick={showQuiz}
                            >
                              START QUIZ
                            </Button>
                          </Box>
                        </Flex>
                      </Flex>
                    )}

                    {openQuiz && !showResult && (
                      <Flex
                        className=""
                        w="auto"
                        justifyContent={'center'}
                        alignItems="center"
                        flexDirection={'column'}
                        mx={['10px', '20px']}
                      >
                        <Flex direction={'column'} w="auto" mt="60px" pt="30px">
                          <Progress
                            colorScheme={'brand.darkBlue'}
                            rounded={'md'}
                            value={progress}
                          />
                          <Flex
                            mx={['10px', '20px']}
                            direction={'column'}
                            mt="40px"
                          >
                            <Flex
                              alignContent={'start'}
                              alignItems="flex-start"
                              width="100%"
                            >
                              <FormControl as="fieldset" width="100%">
                                <FormLabel
                                  fontSize={'lg'}
                                  textAlign="center"
                                  fontWeight="bold"
                                  mb="32px"
                                >
                                  {questions[currentQuestionIndex].question}
                                </FormLabel>
                                <RadioGroup
                                  value={selected}
                                  onChange={value => {
                                    handleOnAnswerSelected(value);
                                  }}
                                >
                                  <RadioGroup.Label className="sr-only">
                                    {questions[currentQuestionIndex].question}
                                  </RadioGroup.Label>
                                  <div className="space-y-2">
                                    {questions[
                                      currentQuestionIndex
                                    ].answers.map(option => (
                                      <RadioGroup.Option
                                        key={option.id}
                                        value={option.id}
                                        className={({ active, checked }) =>
                                          `${
                                            active
                                              ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300 '
                                              : ''
                                          }
                  ${
                    checked
                      ? 'bg-cyan-700 bg-opacity-75 text-white'
                      : 'bg-white border border-gray-100 p-4 text-sm font-medium shadow-sm hover:border-gray-300'
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                                        }
                                      >
                                        {({ active, checked }) => (
                                          <>
                                            <div className="flex w-full items-center justify-between">
                                              <div className="flex items-center">
                                                <div className="text-sm">
                                                  <RadioGroup.Label
                                                    as="p"
                                                    className={`font-medium  ${
                                                      checked
                                                        ? 'text-white'
                                                        : 'text-gray-900'
                                                    }`}
                                                  >
                                                    {option.text}
                                                  </RadioGroup.Label>
                                                </div>
                                              </div>
                                              {checked && (
                                                <div className="shrink-0 text-white">
                                                  <CheckCircleIcon className="h-6 w-6" />
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </RadioGroup.Option>
                                    ))}
                                  </div>
                                </RadioGroup>

                                {nextQuestionLoading && (
                                  <Flex
                                    pt={4}
                                    width="100%"
                                    alignContent={'center'}
                                    justifyContent="center"
                                  >
                                    <BeatLoader size={10} color="gray" />
                                  </Flex>
                                )}
                              </FormControl>
                            </Flex>

                            <Center pt={8}>
                              <HStack spacing={3}>
                                {currentQuestionIndex ===
                                  questions.length - 1 && (
                                  <Button
                                    isLoading={isLoading}
                                    spinner={
                                      <BeatLoader size={8} color="white" />
                                    }
                                    onClick={handleSeeCareerFit}
                                    bgColor={'brand.darkBlue'}
                                    textColor={'white'}
                                    _hover={{ shadow: 'lg' }}
                                    rightIcon={
                                      <LightBulbIcon className="w-5 h-5" />
                                    }
                                    p={['12px', '24px']}
                                  >
                                    {' '}
                                    See Career Fit
                                  </Button>
                                )}
                              </HStack>
                            </Center>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}

                    {showResult && Result}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterSmall />
      </Flex>
    </>
  );
};

export default withRouter(Pathfinder);
