import React, { useState } from 'react';
import AuthService from '../services/auth-service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
  Box,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const AccountVerficationRequired = ({ user }) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const toast = useToast();

  const { email } = user;
  const sendEmailVerificationRequest = async event => {
    event.preventDefault();
    const payload = {
      email: email,
    };
    try {
      setLoading(true);
      const response = await AuthService.createEmailVerification(payload);
      toast({
        title: 'Email sent',
        description: 'Please check your email for your activation link.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full md:w-8/12 px-4">
          <>
            {isLoading && (
              <Flex pt="10px" w="100%" h="100%" align="center" justify="center">
                <Box
                  rounded="md"
                  w={['100%', '100%', '100%', '70%']}
                  h="50%"
                  bg="white"
                >
                  <Flex p="20px" align="center" justify="center">
                    <Spinner
                      size="xl"
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="orange.500"
                    />
                  </Flex>
                  <Text textAlign="center" color="gray" p="10px" fontSize="sm">
                    {' '}
                    Sending your verification code to your email...{' '}
                  </Text>
                </Box>
              </Flex>
            )}
            {!isLoading && (
              <Box
                rounded="md"
                bg="white"
                p="20px"
                boxShadow="lg"
                textAlign={'center'}
              >
                <Flex
                  flexDirection={'column'}
                  p="20px"
                  align="center"
                  justify="center"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ height: '48px', width: '48px' }}
                    className="text-orange-500"
                  />
                  <Text mt="4" className="text-xl md:text-2xl font-black">
                    Please verify your email address.
                  </Text>
                  <Text className="font-sm" mt="2">
                    We've sent an email to{' '}
                    <span className="text-orange-500">{email}</span> for
                    verification.
                  </Text>
                  <Text mt="5" mb="2">
                    Did not receive?{' '}
                    <span className="underline text-orange-500">
                      <Link onClick={sendEmailVerificationRequest}>
                        Resend verification link.
                      </Link>
                    </span>
                  </Text>
                  <Link fontSize={'md'} href="/logout">
                    Sign in as a different user
                  </Link>
                </Flex>
              </Box>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default AccountVerficationRequired;
