import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Center,
  Flex,
  Heading,
  Image,
  Text,
  FormControl,
  Input,
  Stack,
  useToast,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Tabs,
  TabList,
  Tab,
  useDisclosure,
  Textarea,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import {
  FaAddressBook,
  FaAndroid,
  FaChartLine,
  FaCode,
  FaDesktop,
  FaFingerprint,
  FaFire,
  FaLaptopCode,
  FaTrello,
} from 'react-icons/fa';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import DiscussionList from '../components/Discussion/DiscussionList.js';
import discussionService from '../services/discussion-service.js';
import authService from '../services/auth-service';

import { DiscussionListSkeleton } from '../components/Discussion/DiscussionSkeleton.js';
import TechstartaLogo from '../images/Techstarta Logo blue text - png.png';
import SEO from '../components/Common/SEO.js';
import discussionTopics from '../data/discussionTopics.json';
import { Mixpanel } from '../utils/Mixpanel.js';
import withRouter from '../utils/withRouter.js';

const Discussions = () => {
  Mixpanel.track('Discussions Page');
  const currentUser = JSON.parse(authService.getCurrentUser());
  const [discussionBody, setDiscussionBody] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [discussions, setDiscussions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLoginModalOpen,
    onOpen: onOpenLoginModal,
    onClose: onCloseLoginModal,
  } = useDisclosure();
  const initialRef = React.useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { topic } = queryString.parse(location.search);

  const [title, setTitle] = useState('');
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isBodyValid, setIsBodyValid] = useState(true);
  const [topicForNewDiscussion, setTopicForNewDiscussion] = useState(topic);

  const toast = useToast();

  useEffect(() => {
    async function fetchDiscussions() {
      try {
        setIsLoading(true);
        const response = await discussionService.listDiscussions(topic);
        setDiscussions(response.data.discussions);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to load discussions, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    fetchDiscussions();
  }, []);

  const postDiscussion = async event => {
    event.preventDefault();
    if (title.trim() === '') {
      setIsTitleValid(false);
      return;
    }

    if (discussionBody.trim() === '') {
      setIsBodyValid(false);
      return;
    }

    setIsTitleValid(true);
    setIsBodyValid(true);
    const payload = {
      title: title,
      body: discussionBody,
      topic_slug: topicForNewDiscussion,
    };
    setIsLoading(true);
    const response = await discussionService.postDiscussion(payload);
    setIsLoading(false);
    setDiscussions([response.data.discussion, ...discussions]);
    onClose();
  };

  const handlePostNewDiscussion = event => {
    event.preventDefault();
    if (currentUser === null) {
      onOpenLoginModal();
    } else {
      onOpen();
    }
  };

  const Topic = ({ text, icon, iconBg, slug }) => {
    return (
      <LinkBox>
        <Stack
          direction={'row'}
          align={'center'}
          p={2}
          _hover={{ bg: 'orange.50', cursor: 'pointer' }}
        >
          <Flex
            w={8}
            h={8}
            align={'center'}
            justify={'center'}
            rounded={'full'}
            bg={iconBg}
          >
            {icon}
          </Flex>
          <LinkOverlay href={`/discussions?topic=${slug}`}>
            <Text fontWeight="semibold">
              {text}
            </Text>
          </LinkOverlay>
        </Stack>
      </LinkBox>
    );
  };

  return (
    <>
      <SEO
        title="Join the community. Learn with others | Techstarta"
        description="Discuss what you're learning, the challenges you're attempting to solve – this includes sharing lessons, asking questions, and more."
        url="https://techstarta.com/discussions"
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Discussions"
          url={'https://techstarta.com/discussions'}
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="relative bg-white md:pt-32 pb-16"></div>
        <div className="md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 border-0">
                  <Flex
                    bg="#fef6f2"
                    p={['10px', '30px', '40px', '50px']}
                    w="auto"
                    mt={['50px', '50px', '50px', '0px']}
                    justifyContent={['left', 'left', 'left', 'space-between']}
                    flexDirection={['column', 'column', 'column', 'row']}
                    mx={['10px', '20px']}
                    mb="10px"
                    rounded="lg"
                  >
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      color="brand.blue"
                      flexDirection="column"
                    >
                      <Heading
                        textAlign="left"
                        as="h1"
                      >
                        {discussionTopics[topic]} Discussions{' '}
                        <span role="img" aria-label="muscle">
                          💬
                        </span>
                      </Heading>
                      <Text textAlign="left" fontSize="sm">
                        Discuss what you're learning, the challenges you're
                        attempting to solve – this includes sharing lessons,
                        asking questions, and more.
                      </Text>
                      <Stack py="15px" isInline spacing="10px">
                        <Button
                          onClick={handlePostNewDiscussion}
                          variant="solid"
                          borderRadius="full"
                          colorScheme="orange"
                          color="white"
                          _focus={{ outline: 'none' }}
                        >
                          Start a discussion
                        </Button>
                      </Stack>
                    </Flex>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 px-2 border-0">
                  <Tabs
                    variant="soft-rounded"
                    colorScheme="orange"
                    size="sm"
                    paddingBottom="10px"
                    fontSize="xs"
                  >
                    <TabList>
                      <Tab _focus={{ outline: 'none' }}>New</Tab>
                      <Tab _focus={{ outline: 'none' }}>Popular</Tab>
                    </TabList>
                  </Tabs>
                  {isLoading ? (
                    <DiscussionListSkeleton />
                  ) : (
                    <DiscussionList discussions={discussions} />
                  )}
                </div>
              </div>
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader >
                  New Discussion
                </ModalHeader>
                <ModalCloseButton _focus={{ outline: 'none' }} />
                <ModalBody >
                  <FormControl>
                    <Input
                      ref={initialRef}
                      placeholder="Title of Discussion"
                      value={title}
                      onChange={event => {
                        event.preventDefault();
                        setIsTitleValid(event.target.value.trim() !== '');
                        setTitle(event.target.value);
                      }}
                      isRequired
                    />
                    <Text
                      style={{
                        display: isTitleValid ? 'none' : 'block',
                      }}
                      fontSize="sm"
                      color="red.500"
                    >
                      {' '}
                      Title cannot be blank
                    </Text>
                  </FormControl>

                  <FormControl mt={4}>
                    <Textarea
                      placeholder="Write your message"
                      value={discussionBody}
                      onChange={event => {
                        event.preventDefault();
                        setIsBodyValid(event.target.value.trim() !== '');
                        setDiscussionBody(event.target.value);
                      }}
                      isRequired={true}
                    />
                    <Text
                      style={{
                        display: isBodyValid ? 'none' : 'block',
                      }}
                      fontSize="sm"
                      color="red.500"
                    >
                      {' '}
                      Message cannot be blank
                    </Text>
                  </FormControl>

                  <FormControl mt={4}>
                    <Select
                      size="md"
                      onChange={event => {
                        event.preventDefault();
                        setTopicForNewDiscussion(event.target.value);
                      }}
                      value={topic}
                      placeholder={'Select topic (optional)'}
                    >
                      <option key={'getting-started'} value="getting-started">
                        Getting started
                      </option>
                      <option
                        key={'software-development'}
                        value="software-development"
                      >
                        Software Development
                      </option>
                      <option
                        key={'backend-development'}
                        value="backend-development"
                      >
                        Backend Development
                      </option>
                      <option
                        key={'frontend-development'}
                        value="frontend-development"
                      >
                        Frontend Development
                      </option>
                      <option key={'ui-ux-design'} value="ui-ux-design">
                        UI/UX Design
                      </option>
                      <option
                        key={'product-management'}
                        value="product-management"
                      >
                        Product Management
                      </option>
                      <option key={'interviewing'} value="interviewing">
                        Interviewing
                      </option>
                      <option key={'career-growth'} value="career-growth">
                        Career Growth
                      </option>
                      <option key={'other'} value="other">
                        Other
                      </option>
                    </Select>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    loadingText="Working..."
                    variant="solid"
                    rounded="md"
                    size="md"
                    colorScheme="orange"
                    _focus={{ outline: 'none' }}
                    onClick={postDiscussion}
                  >
                    Post
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal isOpen={isLoginModalOpen} onClose={onCloseLoginModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader >
                  Login to continue
                </ModalHeader>
                <ModalCloseButton _focus={{ outline: 'none' }} />
                <ModalBody >
                  <Flex direction="row">
                    <Image src={TechstartaLogo} height="40px" width="auto" />
                  </Flex>
                  <Flex px="4" mt="4">
                    <Text>
                      We are a community of learners, building careers in tech,
                      solving real problems, and sharing knowledge.
                    </Text>
                  </Flex>
                  <Stack px="4" mt="4">
                    <Button
                      onClick={() =>
                        navigate(`/login?redirectTo=${location.pathname}`)
                      }
                      w={'full'}
                      colorScheme={'orange'}
                      bgColor="orange.500"
                      variant="solid"
                      leftIcon={<FaFingerprint />}
                    >
                      <Center>
                        <Text>Login</Text>
                      </Center>
                    </Button>
                    <Button
                      onClick={() => navigate('/get-started')}
                      w={'full'}
                      colorScheme={'orange'}
                      variant="ghost"
                      leftIcon={<FaFire />}
                    >
                      <Center>
                        <Text>Create new account</Text>
                      </Center>
                    </Button>

                    <Center pt="8" pb="8">
                      <Text fontSize="sm">
                        We value your privacy and don’t ask for too much.
                      </Text>
                    </Center>
                  </Stack>
                </ModalBody>
              </ModalContent>
            </Modal>

            <div className="w-full xl:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-md rounded">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex flex-wrap items-center text-sm text-gray-600">
                    <Box
                      className="flex flex-wrap"
                      display="flex"
                      flexDirection="column"
                    >
                      <Text
                        className="uppercase text-xs font-bold"
                        transform="uppercase"
                        paddingY="10px"
                      >
                        Topics
                      </Text>
                      <Stack spacing={2}>
                        <Topic
                          icon={<Box as={FaLaptopCode} color="orange.700" />}
                          iconBg={'orange.100'}
                          text={'Getting Started'}
                          slug={'getting-started'}
                        />
                        <Topic
                          icon={<Box as={FaCode} color="orange.700" />}
                          iconBg={'orange.100'}
                          text={'Backend Development'}
                          slug={'backend-development'}
                        />
                        <Topic
                          icon={<Box as={FaCode} color="orange.700" />}
                          iconBg={'orange.100'}
                          text={'Frontend Development'}
                          slug={'frontend-development'}
                        />
                        <Topic
                          icon={<Box as={FaAndroid} color="orange.700" />}
                          iconBg={'orange.100'}
                          text={'Android Development'}
                          slug={'android-development'}
                        />
                        <Topic
                          icon={<Box as={FaDesktop} color="orange.700" />}
                          iconBg={'orange.100'}
                          text={'User Interface & Experience Design'}
                          slug={'ui-ux-design'}
                        />
                        <Topic
                          icon={<Box as={FaTrello} color="orange.700" />}
                          iconBg={'orange.100'}
                          text={'Product Management'}
                          slug={'product-management'}
                        />
                        <Topic
                          icon={<Box as={FaAddressBook} color="orange.700" />}
                          iconBg={'orange.100'}
                          text={'Interviewing'}
                          slug={'interviewing'}
                        />
                        <Topic
                          icon={<Box as={FaChartLine} color="orange.700" />}
                          iconBg={'orange.100'}
                          text={'Career Growth'}
                          slug={'career-growth'}
                        />
                      </Stack>
                    </Box>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto"></div>
              </div>
            </div>
          </div>
          <DashboardFooter title="Challenges" />
        </div>
      </div>
    </>
  );
};

export default withRouter(Discussions);
