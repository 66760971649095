import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Flex, Heading, Text, useToast } from '@chakra-ui/react';

import withRouter from '../utils/withRouter.js';
import SEO from '../components/Common/SEO.js';
import subscriptionService from '../services/subscription-service.js';
import authService from '../services/auth-service.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';

const Membership = () => {
  const currentUser = JSON.parse(authService.getCurrentUser());

  const SUBSCRIBTION_PLAN_STARTER = 'price_1MQnG4EyLuaj2xNpNeEGgxCT';
  const SUBSCRIBTION_PLAN_PRO = 'price_1M6F7tEyLuaj2xNp0bspDdVn';

  const toast = useToast();
  const goToCheckout = async priceId => {
    Mixpanel.track('Pro Subscription Clicked', currentUser);

    const payload = { price_id: priceId };
    if (currentUser) {
      payload.customer_email = currentUser.email;
    }
    const response = await subscriptionService.getCheckoutUrl(payload);
    const checkoutSessionUrl = response.checkout.url;
    window.open(checkoutSessionUrl, '_blank', 'noopener noreferrer');
  };

  return (
    <>
      <SEO
        title="Learn the latest tech skills. Explore learning paths | Techstarta"
        url="https://techstarta.com/membership"
        description="Become a developer, product manager or a product designer through high-quality learning resources."
      />

      <div className="relative bg-white">
        <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
        <Container p={['5', '20', '20', '20']} maxW={'6xl'}>
          <Flex
            className="bg-gradient-to-bl from-cyan-400 to-gray-100 rounded-lg overflow-hidden border"
            p={['10px', '30px', '40px', '40px']}
            w="auto"
            mt={['20', '20', '20', '10']}
            flexDirection={'column'}
            rounded="lg"
          >
            <Heading as={'h2'} fontSize="3xl" fontWeight="semibold">
              A smarter way to start a new career journey.
            </Heading>
            <Text py={4} fontSize="md">
              Personalized feedback from industry leaders complements our
              excellent learning resources.
            </Text>
          </Flex>
          <div className="mx-auto mt-8 max-w-2xl rounded-lg ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                3 months membership
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Get access to our private community, real-world challenges,
                mentorship sessions, mock interviews and more.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-cyan-600">
                  What’s included
                </h4>
                <div className="h-px flex-auto bg-gray-100"></div>
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-cyan-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Access to our community
                </li>

                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-cyan-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Access to 1:1 mentorship
                </li>

                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-cyan-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Unlimited access to our real-world challenges
                </li>

                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-cyan-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Access to our curated resources
                </li>
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    Pay once, own it for 3 months
                  </p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                      $30
                    </span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                      USD
                    </span>
                  </p>
                  <a
                    href="#"
                    onClick={() => {
                      goToCheckout();
                    }}
                    className="mt-10 block w-full rounded-full brand-dark-blue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                  >
                    Upgrade to Pro
                  </a>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    We're so confident that you'll love this product, that we
                    offer a 30-day money-back guarantee.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <Flex flexDirection="column" pt={8} pb={8}>
                  <Heading pt={2} size="md">
                    What people are saying about Techstarta
                  </Heading>
                  <Text>
                    Still not convinced? Checkout what people are saying about
                    Techstarta
                  </Text>

                  <SimpleGrid
                    columns={{ md: 1, lg: 3 }}
                    spacing={4}
                    alignItems="flex-start"
                    pt={4}
                  >
                    <Flex
                      flexDirection="column"
                      borderRadius="16px"
                      style={{ border: '1px solid #e8eaea' }}
                      px={4}
                      py={4}
                    >
                      <Text pb={2} textColor="gray.600">
                        "Something like this would have helped me a lot when I
                        started out, but I'm happy that it exists for those who
                        are starting out now!"
                      </Text>
                      <Flex
                        flex="1"
                        gap="4"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Avatar
                          name="Taiwo Owosakin"
                          src="https://bit.ly/sage-adebayo"
                        />

                        <Box>
                          <Heading size="sm">Taiwo Owosakin</Heading>
                          <Text>Product, Delivery Hero</Text>
                        </Box>
                      </Flex>
                    </Flex>

                    <Flex
                      flexDirection="column"
                      borderRadius="16px"
                      style={{ border: '1px solid #e8eaea' }}
                      px={4}
                      py={4}
                    >
                      <Text pb={2} textColor="gray.600">
                        "Something like this would have helped me a lot when I
                        started out, but I'm happy that it exists for those who
                        are starting out now!"
                      </Text>
                      <Flex
                        flex="1"
                        gap="4"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Avatar
                          name="Taiwo Owosakin"
                          src="https://res.cloudinary.com/techstarta/image/upload/v1673246139/IMG_9651_xyqpqa.jpg"
                        />

                        <Box>
                          <Heading size="sm">Taiwo Owosakin</Heading>
                          <Text>Product, Delivery Hero</Text>
                        </Box>
                      </Flex>
                    </Flex>

                    <Flex
                      bg="teal.600"
                      textColor="white"
                      flexDirection="column"
                      borderRadius="16px"
                      style={{ border: '1px solid #e8eaea' }}
                      px={4}
                      py={4}
                    >
                      <Text pb={2}>
                        "Something like this would have helped me a lot when I
                        started out, but I'm happy that it exists for those who
                        are starting out now!"
                      </Text>
                      <Flex
                        flex="1"
                        gap="4"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Avatar
                          name="Taiwo Owosakin"
                          src="https://bit.ly/sage-adebayo"
                        />

                        <Box>
                          <Heading size="sm">Taiwo Owosakin</Heading>
                          <Text>Product, Delivery Hero</Text>
                        </Box>
                      </Flex>
                    </Flex>
                  </SimpleGrid>
                </Flex> */}

          {/* <Flex
                  bgColor="brand.darkBlue"
                  textColor="white"
                  justify={['flex-start', 'space-between']}
                  pt={6}
                  p={4}
                  borderRadius="16px"
                  style={{ border: '1px solid #e8eaea' }}
                >
                  <Flex flexDirection="column">
                    <Heading pt={2} size="md">
                      Still have questions?
                    </Heading>
                    <Text>Chat with someone on the Techstarta team.</Text>
                  </Flex>
                  <Button
                    variant="outline"
                    leftIcon={
                      <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                    }
                    _hover={{ shadow: 'md' }}
                  >
                    Chat
                  </Button>
                </Flex> */}
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Membership);
