import React, { useState } from 'react';
import {
  Flex,
  Text,
  Avatar,
  Divider,
  SimpleGrid,
  Checkbox,
  Skeleton,
  Button,
  Input,
  Box,
  Icon,
  useToast,
  Alert,
  AlertIcon,
  Tooltip,
} from '@chakra-ui/react';
import { CreateTeamForm } from './CreateTeamForm';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';
import { ChallengeCard } from './ChallengeCard';
import AvailableTrack from './AvailableTrack';
import { useNavigate } from 'react-router-dom';
import TeamService from '../services/team-service';
import useClipboard from '../utils/useClipboard';

export const TrackTab = ({ team, tracks }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [trackTitle, setTrackTitle] = useState('');
  const [showTrackError, setShowTrackError] = useState(false);

  const viewTrack = slug => {
    navigate(`/skills/${slug}`);
  };

  const assignTrack = async () => {
    if (trackTitle.length < 1) {
      setShowTrackError(true);
      return;
    } else {
      setShowTrackError(false);
    }

    const trackSlug = tracks.filter(track => {
      if (trackTitle === track.title) {
        return track;
      }
    })[0].slug;

    try {
      const response = await TeamService.assignTrack(team.slug, trackSlug);
      if (response.status === 201) {
        toast({
          title: 'Great!',
          description: 'This track has been assigned to your team',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        window.location.reload();
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description:
          'Unable to assign this track to your team, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex mt="20px" direction="column">
        <Flex direction="column">
          <Text fontWeight="bold" color="#2d3748" fontSize="lg">
            {' '}
            Assigned Tracks{' '}
          </Text>
          <Text color="#718096"> See tracks assigned to your team. </Text>
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex mt="20px" direction="column">
          {team.tracks.length > 0 &&
            team.tracks.map((track, index) => (
              <AvailableTrack
                key={index}
                item={track}
                trackActionFn={() => viewTrack(track.slug)}
              />
            ))}
          {team.tracks.length < 1 && (
            <Box
              shadow="md"
              d="flex"
              alignContent="center"
              rounded="md"
              flexDirection="row"
              w="100%"
              h="100%"
              bg="white"
              mb="15px"
            >
              <Box p="3" w="5%" d="flex" justify="center" align="center">
                <Icon name="warning" size="32px" color="yellow.300" />
              </Box>
              <Text
                p="3"
                pt="4"
                ml="4"
                color="#2d3748"
                fontWeight="bold"
                fontSize="sm"
                w="95%"
              >
                {' '}
                No tracks have been assigned to your team.{' '}
              </Text>
            </Box>
          )}
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex direction="column">
          <Text fontWeight="bold" color="#2d3748" fontSize="lg">
            {' '}
            Assign Tracks{' '}
          </Text>
          <Text color="#718096"> Assign tracks to your team. </Text>
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex w={['100%', '70%']} mt="10px" direction="column">
          <Flex mt="10px" direction="column">
            <Text color="#2d3748" mb="5px">
              {' '}
              Pick a track{' '}
            </Text>
            <Input
              // w={["100%", "70%"]}
              type="text"
              list="tracks"
              focusBorderColor="#dd6b20"
              color="#2d3748"
              placeholder="Enter track"
              onChange={e => setTrackTitle(e.target.value)}
            />
            <datalist id="tracks">
              {tracks &&
                tracks.map((track, index) => (
                  <option key={index} value={track.title}>
                    {' '}
                  </option>
                ))}
            </datalist>
            <Alert
              borderRadius="md"
              fontSize="sm"
              style={{
                display: showTrackError ? 'block' : 'none',
              }}
              mt="1"
              status="error"
            >
              <AlertIcon size="13px" />
              Track is required
            </Alert>
          </Flex>
          <Flex mt="20px">
            <Button
              fontSize="sm"
              shadow="md"
              _hover={{ shadow: 'lg' }}
              rounded="9999px"
              _focus={{ outline: 'none' }}
              colorScheme="orange"
              onClick={assignTrack}
            >
              {' '}
              Assign to team{' '}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export const ChallengeTab = ({ team, challenges }) => {
  const toast = useToast();

  const [challengeTitle, setChallengeTitle] = useState('');
  const [showChallengeError, setShowChallengeError] = useState(false);

  const assignChallenge = async () => {
    if (challengeTitle.length < 1) {
      setShowChallengeError(true);
      return;
    } else {
      setShowChallengeError(false);
    }

    const challengeSlug = challenges.filter(challenge => {
      if (challengeTitle === challenge.title) {
        return challenge;
      }
    })[0].slug;

    try {
      const response = await TeamService.assignChallenge(
        team.slug,
        challengeSlug
      );
      if (response.status === 201) {
        toast({
          title: 'Great!',
          description: 'This challenge has been assigned to your team',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        window.location.reload();
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description:
          'Unable to assign this challenge to your team, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Flex mt="20px" direction="column">
        <Flex direction="column">
          <Text fontWeight="bold" color="#2d3748" fontSize="lg">
            {' '}
            Assigned Challenges{' '}
          </Text>
          <Text color="#718096"> See challenges assigned to your team. </Text>
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex mt="20px" direction="column">
          {team.challenges.length > 0 &&
            team.challenges.map((challenge, index) => (
              <ChallengeCard challengeDetails={challenge} key={index} />
            ))}
          {team.challenges.length < 1 && (
            <Box
              shadow="md"
              d="flex"
              alignContent="center"
              rounded="md"
              flexDirection="row"
              w="100%"
              h="100%"
              bg="white"
              mb="15px"
            >
              <Box p="3" w="5%" d="flex" justify="center" align="center">
                <Icon name="warning" size="32px" color="yellow.300" />
              </Box>
              <Text
                p="3"
                pt="4"
                ml="4"
                color="#2d3748"
                fontWeight="bold"
                fontSize="sm"
                w="95%"
              >
                {' '}
                No challenges have been assigned to your team.{' '}
              </Text>
            </Box>
          )}
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex direction="column">
          <Text fontWeight="bold" color="#2d3748" fontSize="lg">
            {' '}
            Assign Challenges{' '}
          </Text>
          <Text color="#718096"> Assign challenges to your team. </Text>
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex mt="10px" direction="column">
          <Flex w={['100%', '70%']} mt="10px" direction="column">
            <Text color="#2d3748" mb="5px">
              {' '}
              Pick a challenge{' '}
            </Text>
            <Input
              type="text"
              list="challenges"
              focusBorderColor="#dd6b20"
              color="#2d3748"
              placeholder="Enter challenge"
              onChange={e => setChallengeTitle(e.target.value)}
            />
            <datalist id="challenges">
              {challenges &&
                challenges.map((challenges, index) => (
                  <option key={index} value={challenges.title}>
                    {' '}
                  </option>
                ))}
            </datalist>
            <Alert
              borderRadius="md"
              fontSize="sm"
              style={{
                display: showChallengeError ? 'block' : 'none',
              }}
              mt="1"
              status="error"
            >
              <AlertIcon size="13px" />
              Challenge is required
            </Alert>
          </Flex>
          <Flex mt="20px">
            <Button
              fontSize="sm"
              shadow="md"
              _hover={{ shadow: 'lg' }}
              rounded="9999px"
              _focus={{ outline: 'none' }}
              colorScheme="orange"
              onClick={assignChallenge}
            >
              {' '}
              Assign to team{' '}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export const MemberTab = ({ members, numOfMembers, invitationCode }) => {
  const { hasCopied, copyToClipboard } = useClipboard();

  return (
    <>
      <Flex mt="20px" direction="column">
        <Flex direction="column">
          <Text fontWeight="bold" color="#2d3748" fontSize="lg">
            {' '}
            Your Team{' '}
          </Text>
          <Text color="#718096">
            {' '}
            Manage your team and invite new members.{' '}
          </Text>
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex mt="20px" direction="column">
          <Flex p="10px" mb="20px">
            <SimpleGrid
              alignItems="flex-end"
              columns={[1, 2, 3, 4]}
              spacing={10}
              w="100%"
            >
              {members.map((member, index) => (
                <Flex
                  p="10px"
                  direction="column"
                  shadow="md"
                  fontWeight="bold"
                  color="#2d3748"
                  justifyItems="center"
                  alignItems="center"
                  key={index}
                >
                  <Avatar
                    name={`${member.firstname} ${member.lastname}`}
                    src={member.image}
                  />
                  <Text textAlign="center" mt="10px">
                    {' '}
                    {`${member.firstname} ${member.lastname}`}{' '}
                  </Text>
                  <Text fontWeight="bold" color="#718096" mt="10px">
                    {' '}
                    {member.role
                      ? capitalizeFirstLetter(member.role)
                      : null}{' '}
                  </Text>
                </Flex>
              ))}
            </SimpleGrid>
          </Flex>
          <Divider borderColor="#ed8936" />
          <Flex color="#2d3748" alignItems="center">
            <Flex direction="column">
              <Text fontWeight="bold" color="#2d3748" fontSize="lg">
                {' '}
                Settings{' '}
              </Text>
              <Flex mt="10px">
                <Checkbox
                  _active={{ outline: 'none' }}
                  size="lg"
                  colorScheme="orange"
                ></Checkbox>
                <Text ml="10px" color="#718096">
                  {' '}
                  Allow joining via UR{' '}
                </Text>
              </Flex>
              <Flex wordBreak="break-all" direction="column" w="100%" mt="10px">
                <Text color="#718096">
                  {' '}
                  Share this link with people you want to join your team.{' '}
                </Text>
                <Tooltip
                  label={
                    hasCopied
                      ? 'Copied to clipboard!'
                      : 'Click to copy to clipboard'
                  }
                  placement="bottom"
                >
                  <Text
                    cursor="pointer"
                    mt="5px"
                    p="5px"
                    id="joinUrl"
                    border="1px solid #ed8936"
                    color="#718096"
                    onClick={() => copyToClipboard('joinUrl')}
                  >
                    {' '}
                    {`https://talentmine.io/teams/${invitationCode}/join`}{' '}
                  </Text>
                </Tooltip>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export const EditTeamTab = ({ team }) => {
  return (
    <>
      <Flex mt="20px" direction="column">
        <Flex direction="column">
          <Text fontWeight="bold" color="#2d3748" fontSize="lg">
            {' '}
            Edit team{' '}
          </Text>
          <Text color="#718096"> Edit the {team.name} team </Text>
        </Flex>
        <Divider borderColor="#ed8936" />
        <CreateTeamForm team={team} editTeam={true} />
      </Flex>
    </>
  );
};

export const TrackTabSkeleton = () => {
  return (
    <>
      <Flex mt="20px" direction="column">
        <Flex direction="column">
          <Skeleton mb="10px" w={['60%', '40%', '30%', '20%']} h="20px">
            {' '}
          </Skeleton>
          <Skeleton w={['80%', '70%', '60%', '50%']} h="20px">
            {' '}
          </Skeleton>
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex
          my="20px"
          direction={['column', 'column', 'column', 'row']}
          shadow="md"
          p={['10px', '20px']}
          justifyContent="space-between"
        >
          <Flex direction="column" w={['100%', '100%', '100%', '70%']}>
            <Skeleton mb="10px" w="100%" h="20px">
              {' '}
            </Skeleton>
            <Skeleton w="100%" h="50px">
              {' '}
            </Skeleton>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            w={['100%', '100%', '100%', '20%']}
            mt={['10px', '10px', '10px', '0px']}
          >
            <Skeleton w={['50%', '30%', '30%', '50%']} rounded="full" h="40px">
              {' '}
            </Skeleton>
          </Flex>
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex direction="column">
          <Skeleton mb="10px" w="20%" h="20px">
            {' '}
          </Skeleton>
          <Skeleton w="50%" h="20px">
            {' '}
          </Skeleton>
        </Flex>
        <Divider borderColor="#ed8936" />
      </Flex>
    </>
  );
};

export const MemberTabSkeleton = () => {
  return (
    <>
      <Flex mt="20px" direction="column">
        <Flex direction="column">
          <Skeleton mb="10px" w="20%" h="20px">
            {' '}
          </Skeleton>
          <Skeleton w="50%" h="20px">
            {' '}
          </Skeleton>
        </Flex>
        <Divider borderColor="#ed8936" />
        <Flex mt="20px" direction="column">
          <SimpleGrid
            mb="10px"
            alignItems="flex-end"
            columns={[1, 2, 3, 4]}
            spacing={10}
            w="100%"
          >
            <Flex
              p="10px"
              direction="column"
              shadow="md"
              fontWeight="bold"
              color="#2d3748"
              alignItems="center"
            >
              <Skeleton w="50px" h="50px" rounded="50%">
                {' '}
              </Skeleton>
              <Skeleton my="20px" w="100%" h="20px">
                {' '}
              </Skeleton>
            </Flex>
          </SimpleGrid>
          <Divider borderColor="#ed8936" />
          <Flex mt="10px" w="100%" direction="column" p="10px">
            <Skeleton w="50%" h="20px">
              {' '}
            </Skeleton>
            <Skeleton
              w="70%"
              mt="5px"
              p="5px"
              border="1px solid #ed8936"
              h="20px"
            >
              {' '}
            </Skeleton>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
