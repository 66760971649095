import React from "react";
import { LeaderboardItem, LeaderboardItemSkeleton } from "./LeaderboardItem"

const LeaderboardList = ({ userRanking, noAuth }) => {

    return (
        <>
            {userRanking ?
                userRanking.map((user, index) => <LeaderboardItem user={user} position={noAuth ? index + 1 : index + 4} key={index} />)
                :
                <>
                    <LeaderboardItemSkeleton />
                    <LeaderboardItemSkeleton />
                </>
            }
        </>
    )
}

export default LeaderboardList;
