import {API_BASE_URL, connection} from "./index"
import AuthService from "./auth-service";

const listDiscussions = async (topic) => {
    const path = topic ? "discussions?topic=" + topic : "discussions";
    return await connection.get(API_BASE_URL + path);
}

const getDiscussion = async (slug) => {
    return await connection.get(API_BASE_URL + "discussions/" + slug)
}

const postDiscussion = async (payload) => {
    const token = AuthService.getAuthToken();
    return await connection.post(API_BASE_URL + "discussions", {discussion: payload},
        {
            headers: {
                "Authorization": `token ${token}`
            }
        });
}

const postReply = async (discussionSlug, payload) => {
    const token = AuthService.getAuthToken();
    return await connection.post(API_BASE_URL + "discussions/" + discussionSlug + "/replies", {reply: payload}, {
        headers: {
            "Authorization": `token ${token}`
        }
    });
}

const upvoteDiscussion = async (slug) => {
    const token = AuthService.getAuthToken();
    return await connection.post(API_BASE_URL + "discussions/" + slug + "/vote", {}, {
        headers: {
            "Authorization": `token ${token}`
        }
    });
}

const upvoteReply = async (replyId) => {
    const token = AuthService.getAuthToken();
    return await connection.post(API_BASE_URL + "replies/" + replyId + "/vote", {}, {
        headers: {
            "Authorization": `token ${token}`
        }
    });
}

export default { listDiscussions, getDiscussion, postReply, upvoteDiscussion, upvoteReply, postDiscussion }