import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Style } from 'react-style-tag';

const RichEditor = ({
  editorValue,
  setEditorValue,
  editorPlaceholder,
  editorRef,
}) => {
  const EditorModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      // ['link', 'image', 'video'],
      ['link'],
      ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const EditorFormats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  const onChangeFn = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setEditorValue(text);
  };

  return (
    <div>
      <ReactQuill
        ref={editorRef}
        theme="snow"
        className="rounded-lg"
        onChange={onChangeFn}
        defaultValue={editorValue}
        modules={EditorModules}
        formats={EditorFormats}
        placeholder={editorPlaceholder}
      />

      <Style>
        {`.ql-snow.ql-container { min-height: 200px; max-height: 400px; overflow-y: auto; border-bottom-left-radius: 0.5em; border-bottom-right-radius: 0.5em; } .ql-snow.ql-toolbar {
        display: block;
        background: #eaecec;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        }`}
      </Style>
    </div>
  );
};

export default RichEditor;
