import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Image,
  Skeleton,
  Icon,
  Stack,
  Button,
  Tag,
  LinkOverlay,
  LinkBox,
} from '@chakra-ui/react';

import { FaAngleUp, FaRegComments } from 'react-icons/fa';

export const StoryItem = ({ storyDetail }) => {
  const {
    slug,
    title,
    body,
    category,
    featured_image,
    comments,
    upvotes,
    guest_name,
    guest_current_role,
  } = storyDetail;

  return (
    <>
      <LinkBox href={`/stories/${slug}`}>
        <Flex
          className="story"
          boxShadow="sm"
          borderRadius="lg"
          flexDirection="column"
          justifyContent="space-between"
          rounded="lg"
          _hover={{ shadow: 'lg' }}
          cursor="pointer"
          minHeight="100px"
        >
          <Image
            objectFit={'cover'}
            src={featured_image}
            alt={title}
            roundedTop="lg"
            height={'72'}
          />

          <Flex
            color="#2d3748"
            p={['10px', '15px']}
            w={['100%', '100%', '100%', '100%']}
            flexDirection="column"
          >
            <Flex
              w={['100%', '100%', '100%', '100%']}
              pt="10px"
              justifyContent={['center', 'center', 'center', 'center']}
              flexDirection="column"
            >
              <LinkOverlay href={`/stories/${slug}`}>
                <Text cursor="pointer" fontWeight={'bold'} noOfLines="2">
                  {title}
                </Text>
              </LinkOverlay>
              <Text
                pt={2}
                fontWeight={'semibold'}
                textColor={'gray.600'}
                fontSize={'sm'}
                noOfLines={1}
              >
                {guest_name}, {guest_current_role}
              </Text>
            </Flex>

            <Flex
              alignItems={'center'}
              justifyItems={'center'}
              alignContent={'center'}
              justifyContent={'space-between'}
            >
              <Stack py="4" spacing="4" direction="row" alignItems="end">
                {category && (
                  <Tag size="sm" rounded="full" colorScheme="orange" px="2">
                    {category.name}
                  </Tag>
                )}
                {
                  <Text textColor="gray.500" fontSize="xs">
                    <Icon color="gray.500" as={FaAngleUp}></Icon> {upvotes}
                  </Text>
                }

                {comments && (
                  <Text color="green.500" textColor="gray.500" fontSize="xs">
                    <Icon color="gray.500" as={FaRegComments}></Icon>{' '}
                    {comments.length}
                  </Text>
                )}
              </Stack>
            </Flex>
            <Box>
              <Button
                textColor={'gray.600'}
                size={'sm'}
                fontSize={'sm'}
                variant={'outline'}
              >
                Read Story
              </Button>
            </Box>
          </Flex>
        </Flex>
      </LinkBox>
    </>
  );
};

export const StoryItemSkeleton = () => {
  return (
    <Flex
      p="15px"
      flexDirection="column"
      rounded="lg"
      boxShadow="md"
      justifyContent="space-between"
      minHeight="100px"
    >
      <Flex
        w={['100%', '100%', '100%', '100%']}
        color="#2d3748"
        flexDirection="column"
      >
        <Flex
          alignItems="center"
          p="15px"
          pb={['0px', '10px']}
          flexDirection="column"
        >
          <Box
            alignItems="center"
            px="10px"
            py="15px"
            justifyContent={['center', 'center', 'center', 'flex-start']}
          >
            <Skeleton mt={['0px', '20px']} size="70px"></Skeleton>
          </Box>
        </Flex>
        <Box
          w="100%"
          justifyContent={['center', 'center', 'center', 'center']}
          flexDirection="column"
        >
          <Skeleton mt="5px" h="15px"></Skeleton>
          <Skeleton mt="5px" h="12px"></Skeleton>
          <Skeleton mt="5px" h="30px"></Skeleton>
          <Skeleton mt="5px" h="30px"></Skeleton>
        </Box>
      </Flex>
      <Flex
        w="100%"
        justifyContent="space-between"
        mt={['10px', '10px', '10px', '40px']}
      >
        <Flex mt={['10px', '10px', '10px', '0px']} color="#718096">
          <Skeleton size="40px"></Skeleton>
        </Flex>
        <Flex direction="column" color="#718096">
          <Skeleton size="40px"></Skeleton>
        </Flex>
      </Flex>
    </Flex>
  );
};
