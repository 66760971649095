import React from "react";
import DashboardFooter from "../DashboardFooter";
import DashboardNavbar from "../DashboardNavbar";
import Sidebar from "../Sidebar";

const LayoutWithSidebar = ({ children, title }) => {
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-white">
                <DashboardNavbar title={title} hideBecomeAMentorButton={true} />
                {/* Header */}
                <div className="px-2 md:px-10 mx-auto w-full py-8">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full  mb-12 xl:mb-0">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                                <div className="rounded-t mb-0 px-2 border-0">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                    <DashboardFooter />
                </div>
            </div>
        </>
    )
}

export default LayoutWithSidebar;