import React from "react";
import {
    Flex,
    Text,
    Avatar,
    Skeleton, Image
} from "@chakra-ui/react";
import AuthService from "../services/auth-service";

export const TopPerformerItem = ({ userPosition, logo, isFirst, order }) => {

    return (
        <>
            <Flex position="relative" h={isFirst ? "250px" : "205px"} order={order} w="100%" shadow="md" justifyContent="center" p="20px" flexDirection="column" rounded="md">
                <Image top="-20px" right="-15px" position="absolute" boxSize="50px" src={logo} />
                <Flex mb="10px" justifyContent="center">
                    <Avatar name={`${userPosition.firstname.toUpperCase()} ${userPosition.lastname.toUpperCase()}`} src={AuthService.buildImageUrl(userPosition.image)} size="lg" />
                </Flex>
                <Flex mb="10px" flexDirection="column">
                    <Text color="#2d3748" textAlign="center"> <strong> {`${userPosition.firstname} ${userPosition.lastname}`} </strong> </Text>
                    {/* <Text color="#718096" textAlign="center" fontSize="sm"> {userPosition.total_points} pts </Text> */}
                </Flex>
                <Flex mb="10px" flexDirection="column">
                    <Text color="#718096" fontWeight="bold" textAlign="center" fontSize="sm"> {userPosition.total_points} pts </Text>
                </Flex>
            </Flex>
        </>
    )
}

export const TopPerformerItemSkeleton = (isFirst) => {
    return (
        <>
            <Flex position="relative" h={isFirst ? "250px" : "205px"} shadow="md" justifyContent="center" p="20px" flexDirection="column" rounded="md">
                <Skeleton top="-20px" right="-15px" position="absolute" w="50px" h="50px" >  </Skeleton>
                <Flex mb="10px" justifyContent="center">
                    <Skeleton minWidth="64px" minHeight="64px" w="64px" h="64px" rounded="full">  </Skeleton>
                </Flex>
                <Flex mb="10px" flexDirection="column">
                    <Skeleton w="100%" h="20px"></Skeleton>
                </Flex>
                <Flex mb="10px" flexDirection="column">
                    <Skeleton w="100%" h="20px"></Skeleton>
                </Flex>
            </Flex>
        </>
    )
}
