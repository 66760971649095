import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import MentorshipOpportunityImage from '../images/pexels-christina-morillo-1181391.jpg';
import HeroBackground from '../images/pexels-christina-morillo-1181414.jpg';
import {
  Stack,
  Image,
  Center,
  Container,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  UnorderedList,
  ListItem,
  Text,
  Flex,
} from '@chakra-ui/react';

import AuthService from '../services/auth-service';
const MentorLanding = () => {
  const currentUser = JSON.parse(AuthService.getCurrentUser());
  const navigate = useNavigate();

  return (
    <>
      <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: '75vh',
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${HeroBackground})`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                <div className=" pr-12">
                  <h1 className="text-white font-semibold text-6xl pb-4">
                    Achieve your next career goal by{' '}
                    <span className="text-cyan-200">mentoring</span>
                  </h1>
                  <p className="mt-4 text-lg text-gray-200 pb-4">
                    Mentoring is a mutual learning experience that allows both
                    mentor and mentee to develop transferable skills to add to
                    their respective experiences.
                  </p>
                  <Button
                    rounded={'full'}
                    mt={4}
                    size={'lg'}
                    bgColor={'cyan.500'}
                    onClick={() => navigate('/get-started')}
                    colorScheme="whiteAlpha"
                    _hover={{ shadow: 'lg', bgColor: 'cyan.600' }}
                  >
                    Apply now
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: '70px', transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-cyan-400">
                      <i class="fas fa-list-ul"></i>
                    </div>
                    <h6 className="text-lg font-semibold">
                      Solidify your knowledge
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600 text-sm">
                      Sharing your knowledge with others is a great way to
                      solidify your own understanding and learn new things.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-purple-400">
                      <i class="fas fa-comment-dots"></i>
                    </div>
                    <h6 className="text-lg font-semibold">
                      Contribute to learners progress
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600 text-sm">
                      Your feedback can help learners focus on the areas that
                      need the most improvement and help them achieve their
                      goals.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-yellow-400">
                      <i class="fab fa-redhat"></i>
                    </div>
                    <h6 className="text-lg font-semibold">
                      Master your soft skills
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600 text-sm">
                      Mentoring is a great way to develop your soft skills, such
                      as communication, leadership, and empathy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Container p={['5', '20', '20', '20']} maxW={'7xl'}>
          <Center pt={8} width="100%">
            <p className="text-sm text-gray-500 uppercase font-semibold">
              Where our mentors work
            </p>
          </Center>
          <Stack
            overflow="hidden"
            isInline
            spacing="auto"
            width="100%"
            pt="12"
            py={4}
          >
            <Image
              alt="GetYourGuide"
              height="30px"
              width="auto"
              src={
                'https://res.cloudinary.com/techstarta/image/upload/v1621080284/grayscale/image_wvguy5.png'
              }
            ></Image>
            <Image
              alt="Delivery Hero"
              height="30px"
              width="auto"
              src={
                'https://res.cloudinary.com/techstarta/image/upload/v1621085669/grayscale/image_8_urb8y9.png'
              }
            ></Image>
            <Image
              alt="Lieferando"
              height="30px"
              width="auto"
              src={
                'https://res.cloudinary.com/techstarta/image/upload/v1621085083/grayscale/image_7_xsojxd.png'
              }
            ></Image>

            <Image
              alt="Andela"
              height="30px"
              width="auto"
              src={
                'https://res.cloudinary.com/techstarta/image/upload/v1621080505/grayscale/image_1_hmrml8.png'
              }
            ></Image>
            <Image
              alt="Sennder"
              height="30px"
              width="auto"
              src={'//logo.clearbit.com/trybrass.com?size=80&greyscale=true'}
            ></Image>
          </Stack>
        </Container>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: '80px', transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="Popular learning tracks"
                  className="max-w-full rounded-lg shadow-lg"
                  src={MentorshipOpportunityImage}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-cyan-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-cyan-200">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Mentorship Opportunities
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    We have a network of mentors from top companies who are
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-cyan-600 bg-cyan-200 mr-3">
                            <i className="fas fa-code"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Software Engineers</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-cyan-600 bg-cyan-200 mr-3">
                            <i className="fab fa-product-hunt"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Product Managers</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-cyan-600 bg-cyan-200 mr-3">
                            <i className="fa fa-images"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Product Designers</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-cyan-600 bg-cyan-200 mr-3">
                            <i className="fas fa-desktop"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Data Analyst</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-cyan-600 bg-cyan-200 mr-3">
                            <i className="fas fa-money-check-alt"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Business Developer</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="mt-6">
                    <Link
                      type="button"
                      to="/get-started"
                      className="hover:bg-transparent bg-slate-700 text-white font-semibold hover:text-slate-700 py-2 px-4 border hover:border-slate-500 border-transparent rounded-full"
                    >
                      Apply now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">FAQs</h2>
                <p className="text-lg leading-relaxed m-4 text-gray-600">
                  How does it work? What do I need to have to be a mentor?
                </p>
              </div>
            </div>
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          What do I need to have to be a mentor?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        To be a mentor, you must meet the following criteria:
                      </Text>
                      <UnorderedList>
                        <ListItem>
                          Relevant experience in the industry for 3 years
                        </ListItem>
                        <ListItem>
                          Must be in Mid-Level career experience
                        </ListItem>
                        <ListItem>
                          Passionate about impacting the growth of others and
                          sharing your expertise
                        </ListItem>
                      </UnorderedList>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          How does Techstarta work for Mentors
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <UnorderedList>
                        <ListItem>
                          Visibility: Your level of engagement on the platform
                          is reflected by the number of feedback you provide and
                          the reviews from your mentees.
                        </ListItem>
                        <ListItem>
                          Earn: Earning a stipend is not the primary motivation
                          to become a mentor, but it is a way to acknowledge the
                          time and effort invested. We encourage mentors to join
                          for the chance to have a positive impact and share
                          their knowledge.
                        </ListItem>
                        {/* <ListItem>
                          Earn $2 per accepted mentoring call request. To be
                          eligible for earnings, provide feedback to at least
                          one learner per month.
                        </ListItem> */}
                        {/* <ListItem>
                          Set your price for future sessions: Techstarta charges
                          a 1.5% fee on earnings.
                        </ListItem> */}
                        <ListItem>
                          Earn kudos: Kudos are a way to recognize the efforts
                          of a mentor. You can earn kudos by providing feedback
                          to your mentees, sharing your knowledge, and being an
                          active mentor.
                        </ListItem>
                        {/* <ListItem>
                          Host free and paid Group virtual meetings.
                        </ListItem> */}
                        <ListItem>
                          Network: Find and connect with other mentors
                        </ListItem>
                      </UnorderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          How do I stay active as a mentor?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        Mentors are evaluated every three months. An active
                        mentor is one who has provided at least eight pieces of
                        learner feedback.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          How do I get paid?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        Mentors are paid monthly. Payments are made via PayPal.
                        You must have a PayPal account to receive payments.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={'semibold'}
                        >
                          How else can I contribute to Techstarta?
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>
                        You can contribute to Techstarta by sharing resources
                        that you find useful in your career. You can also create
                        challenges for learners to complete.
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <Flex
                  pt={16}
                  alignItems="center"
                  justifyContent={'center'}
                  flexDirection="column"
                >
                  <Text
                    fontWeight={'semibold'}
                    fontSize="2xl"
                    textColor={'gray.600'}
                  >
                    You have different question?
                  </Text>
                  <Text textColor={'gray.700'}>
                    Email us at &nbsp;
                    <a href="mailto:hello@techstarta.com" className="underline">
                      hello@techstarta.com
                    </a>
                  </Text>
                </Flex>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default MentorLanding;
