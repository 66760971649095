import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Text,
  Button,
  ButtonGroup,
  Skeleton,
  Flex,
  Icon,
  useToast,
  Stack,
  Divider,
  Badge,
  Textarea,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FaAngleUp, FaReply } from 'react-icons/fa';
import { Style } from 'react-style-tag';
import Linkify from 'react-linkify';
import authService from '../../services/auth-service';
import discussionService from '../../services/discussion-service';
import moment from 'moment';
import LoginModal from '../Common/LoginModal';

export const DiscussionWithReplies = ({ discussion }) => {
  const { title, topic, slug, upvotes, replies } = discussion;
  const currentUser = JSON.parse(authService.getCurrentUser());
  const [reply, setReply] = useState('');
  const [isReplyValid, setIsReplyValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [votes, setVotes] = useState(upvotes);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const postReply = async event => {
    event.preventDefault();
    if (reply.trim() === '') {
      setIsReplyValid(false);
      return;
    }
    const payload = {
      body: reply,
    };
    setIsReplyValid(true);
    try {
      setIsLoading(true);
      await discussionService.postReply(slug, payload);
      toast({
        title: 'Reply sent',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      window.location.reload();
    } catch (err) {
      toast({
        title: 'Reply not sent',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const upvoteDiscussion = async event => {
    event.preventDefault();
    try {
      const response = await discussionService.upvoteDiscussion(slug);
      setVotes(response.data.discussion.upvotes);
    } catch (err) {}
  };

  const handleCommentBoxClick = event => {
    event.preventDefault();
    if (currentUser == null) {
      onOpen();
    }
  };

  return (
    <>
      <Box p={4} display={{ md: 'flex' }}>
        <Box
          mt={{ base: 4, md: 6 }}
          w={{ base: '80%', sm: '100%', md: '100%' }}
        >
          <Flex justify="space-between">
            <Text
              fontWeight="semibold"
              fontSize="3xl"
              letterSpacing="normal"
              color="brand.darkBlue"
            >
              {title}
            </Text>
            <ButtonGroup size="sm" isAttached variant="outline">
              <IconButton
                onClick={upvoteDiscussion}
                _focus={{ outline: 'none' }}
                aria-label="Upvote"
                icon={<FaAngleUp />}
              />
              <Button _focus={{ outline: 'none' }} mr="-px">
                {votes}
              </Button>
            </ButtonGroup>
          </Flex>

          <Stack
            spacing="8"
            pt="10px"
            w="100%"
            direction={['row', 'row', 'column', 'row']}
            justifyContent="left"
          >
            {topic && (
              <Flex direction="column">
                <Badge size="xs" rounded="full" colorScheme="teal" px="2">
                  {topic.name}
                </Badge>
              </Flex>
            )}
            {
              <Flex mt={['10px', '10px', '10px', '0px']} direction="column">
                <Text textColor="gray.500" fontSize="xs">
                  {moment(discussion.created_at).fromNow()}
                </Text>
              </Flex>
            }
            {replies && (
              <Flex direction="column">
                <Text color="green.500" textColor="gray.500" fontSize="xs">
                  <Icon color="gray.500" as={FaReply}></Icon> {replies.length}{' '}
                  Replies
                </Text>
              </Flex>
            )}
          </Stack>
        </Box>
      </Box>
      <Divider />
      <Box m={{ base: 4, md: 4 }}>
        <Box fontSize="md" color="gray.800">
          <Flex>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  className="text-orange-600"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {discussion.body.split(/\n/).map(line => (
                <>
                  {line}
                  <br />
                </>
              ))}
            </Linkify>
          </Flex>
        </Box>

        <Flex
          flexDirection={['column', 'column']}
          p={['5px', '5px', '5px', '10px']}
          border
        >
          <Flex marginTop="20px">
            <Box pr="5px" className="hidden md:block">
              <Avatar
                src={
                  currentUser
                    ? authService.buildImageUrl(currentUser.image)
                    : ''
                }
                name={
                  currentUser
                    ? currentUser.firstname + ' ' + currentUser.lastname
                    : ''
                }
                size="sm"
              />
            </Box>
            <Box w="100%">
              <Textarea
                fontSize="sm"
                placeholder="Write a reply"
                value={reply}
                onChange={event => {
                  event.preventDefault();
                  setIsReplyValid(event.target.value.trim() !== '');
                  setReply(event.target.value);
                }}
                onClick={event => handleCommentBoxClick(event)}
              />
              <Text
                style={{
                  display: isReplyValid ? 'none' : 'block',
                }}
                fontSize="sm"
                color="red.500"
              >
                {' '}
                Reply cannot be blank
              </Text>
            </Box>
          </Flex>
          <Flex my="10px" justifyContent={['flex-start', 'flex-end']}>
            <Button
              _focus={{ outline: 'none' }}
              bg="orange.500"
              _hover={{ shadow: 'md' }}
              onClick={postReply}
              colorScheme="orange"
              isLoading={isLoading}
              loadingText="Working..."
              rounded="lg"
              size="md"
              fontSize="sm"
            >
              Post reply
            </Button>
          </Flex>
        </Flex>
        <LoginModal
          isOpen={isOpen}
          onClose={onClose}
          redirectTo={location.pathname}
        />
      </Box>

      <Style>
        {`
            p a { 
            color: #ed8936;
            display: inline-block;
            position: relative;
            text-decoration: none;
            &:before {
                background-color: #ed8936;
                content: '';
                height: 1px;
                position: absolute;
                bottom: -1px;
                transition: width 0.3s ease-in-out;
                width: 100%;
            }
            &:hover {
                &:before {
                width: 0;
                }
            }
            &:focus {
                color: blue;
                &:before {
                width: 0;
                }
            }
            }

            .markdown {
            font-size: 14px;
            }
            .markdown a { 
            color: #ed8936;
            display: inline-block;
            position: relative;
            text-decoration: none;
            &:before {
                background-color: #ed8936;
                content: '';
                height: 1px;
                position: absolute;
                bottom: -1px;
                transition: width 0.3s ease-in-out;
                width: 100%;
            }
            &:hover {
                &:before {
                width: 0;
                }
            }
            &:focus {
                color: blue;
                &:before {
                width: 0;
                }
            }
            }

            .markdown ul {
                list-style-type: disc;
                list-style-position: inside;
            }
            `}
      </Style>
    </>
  );
};

export const DiscussionWithRepliesSkeleton = () => {
  return (
    <>
      <Box color="gray.500" mt="30px">
        <Box fontSize="60px">
          <Skeleton h="40px"> </Skeleton>
        </Box>
        <Box display="flex" mt="10px" flexDirection="column">
          <Skeleton mt="10px" h="20px">
            {' '}
          </Skeleton>
          <Skeleton mt="10px" h="30px">
            {' '}
          </Skeleton>
        </Box>
      </Box>
      <Box mt="40px">
        <Box mt="40px">
          <Box color="#718096">
            <Skeleton mt="20px" h="20px">
              {' '}
            </Skeleton>
            <Skeleton mt="20px" h="40px">
              {' '}
            </Skeleton>
          </Box>
        </Box>

        <Box mt="40px">
          <Box color="#718096">
            <Box color="#718096">
              <Skeleton mt="20px" h="20px">
                {' '}
              </Skeleton>
              <Skeleton mt="20px" h="40px">
                {' '}
              </Skeleton>
            </Box>
          </Box>
        </Box>
        <Box mt="40px">
          <Skeleton h="30px"> </Skeleton>
          <Box color="#718096">
            <Skeleton mt="20px" h="20px">
              {' '}
            </Skeleton>
            <Skeleton mt="20px" h="40px">
              {' '}
            </Skeleton>
          </Box>
        </Box>
        <Box bg="whitesmoke" mt="40px" p="20px" pt="40px">
          <Skeleton mt="20px" h="30px">
            {' '}
          </Skeleton>
        </Box>
      </Box>
    </>
  );
};
