import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Heading,
  Text,
  Select,
  Stack,
  useToast,
  Button,
  Box,
  HStack,
  VStack,
  Image,
  Show,
  Skeleton,
} from '@chakra-ui/react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import ChallengesGrid from '../components/ChallengesGrid';
import TrackService from '../services/track-service';
import SEO from '../components/Common/SEO.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import { PRIMARY_FILTER_RECOMMENDED, PRIMARY_FILTER_ALL } from '../constants';
import withRouter from '../utils/withRouter.js';
import authService from '../services/auth-service.js';
import { PuzzlePieceIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import submissionsService from '../services/submissions-service';
import { ChevronRightIcon } from '@chakra-ui/icons';
import challengesIllustration from '../images/challenge.png';
import SubmissionStatus from '../components/Submission/SubmissionStatus.jsx';

const Challenges = () => {
  Mixpanel.track('Viewed Challenges Page');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [challengesList, setChallengesList] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState('');
  const [selectedDifficulty, setSelectedDifficulty] = useState('');
  const toast = useToast();
  const [primaryFilter, setPrimaryFilter] = useState(
    PRIMARY_FILTER_RECOMMENDED
  );
  const [submissions, setSubmissions] = useState([]);
  const currentUser = JSON.parse(authService.getCurrentUser());

  useEffect(() => {
    async function fetchSubmissions() {
      if (currentUser) {
        const response = await submissionsService.listSubmissions(
          currentUser.username
        );
        if (response.status === 200) {
          setSubmissions(response.data.submissions);
        }
      }
    }
    fetchSubmissions();
  }, [currentUser?.username]);

  useEffect(() => {
    async function getChallengesList() {
      try {
        setIsLoading(true);
        const response = await TrackService.getRecommendedChallenges();
        setChallengesList(response.challenges);
        setFilteredResults(response.challenges);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to get challenges, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    getChallengesList();
  }, [toast]);

  const applyPrimaryFilter = async primaryFilter => {
    setPrimaryFilter(primaryFilter);
    setIsLoading(true);
    const response =
      primaryFilter === PRIMARY_FILTER_RECOMMENDED
        ? await TrackService.getRecommendedChallenges()
        : await TrackService.fetchAllChallenges();
    setChallengesList(response.challenges);
    setFilteredResults(response.challenges);
    setIsLoading(false);
  };

  const applyFilterByTrack = (track, challenges) => {
    return challenges.filter(challenge => challenge.track === track);
  };

  const applyFilterByDifficulty = (difficulty, challenges) => {
    return challenges.filter(
      challenge => challenge.difficulty_level === difficulty
    );
  };

  const handleFilterChange = event => {
    const { name, value } = event.target;

    if (name === 'track' && value !== '') {
      setSelectedTrack(value);
      const results = applyFilterByTrack(value, challengesList);
      setFilteredResults(results);

      if (selectedDifficulty !== '') {
        setFilteredResults(
          applyFilterByDifficulty(selectedDifficulty, results)
        );
      }
    } else if (name === 'difficulty' && value !== '') {
      setSelectedDifficulty(value);
      const results = applyFilterByDifficulty(value, challengesList);
      setFilteredResults(results);

      if (selectedTrack !== '') {
        setFilteredResults(applyFilterByTrack(selectedTrack, results));
      }
    } else if (name === 'track' && value === '') {
      setSelectedTrack('');
      setFilteredResults(challengesList);

      if (selectedDifficulty !== '') {
        setFilteredResults(
          applyFilterByDifficulty(selectedDifficulty, challengesList)
        );
      }
    } else if (name === 'difficulty' && value === '') {
      setSelectedDifficulty('');
      setFilteredResults(challengesList);

      if (selectedTrack !== '') {
        setFilteredResults(applyFilterByTrack(selectedTrack, challengesList));
      }
    } else {
      setFilteredResults(challengesList);
    }
  };

  const handleClearFilters = event => {
    event.preventDefault();
    setSelectedTrack('');
    setSelectedDifficulty('');
    setPrimaryFilter(PRIMARY_FILTER_RECOMMENDED);
    setFilteredResults(challengesList);
  };

  return (
    <>
      <SEO
        title="Solve real-world challenges | Techstarta"
        url="http://techstarta.com/challenges"
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white overflow-hidden">
        <DashboardNavbar title="Challenges" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="md:px-10 mx-auto w-full pt-8 overflow-hidden">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 border-0">
                  <Flex
                    className="bg-gradient-to-bl from-cyan-400 to-gray-100"
                    shadow={'sm'}
                    p={['10px', '30px', '40px', '50px']}
                    w="auto"
                    flexDirection={'column'}
                    mx={['10px', '20px']}
                    mb="10px"
                    rounded="lg"
                    border={'1px solid #e2e8f0'}
                  >
                    <Flex justifyContent={'space-between'}>
                      <Flex flexDirection={'column'}>
                        <Heading textAlign="left" pb={4}>
                          Build your skills and portfolio
                        </Heading>
                        <Text textAlign="left" fontSize="md">
                          We break down portfolio projects into small tasks for
                          skill-building and portfolio development.
                        </Text>
                        <Text textAlign="left" fontSize="md">
                          Get mentor feedback and earn badges to showcase your
                          skills.
                        </Text>
                      </Flex>
                      <Show above="sm">
                        <Image
                          alt="Techstarta Challenges"
                          src={challengesIllustration}
                          h={{ md: '104px', xl: '104px' }}
                        />
                      </Show>
                    </Flex>
                    <Box pt={4}>
                      <Button
                        size="md"
                        variant="outline"
                        borderRadius="full"
                        width={'auto'}
                        outlineColor={'gray.300'}
                        _focus={{ outline: '#127881' }}
                        _hover={{
                          variant: 'outline',
                          shadow: 'sm',
                          outlineColor: 'cyan.500',
                        }}
                        _active={{ outlineColor: 'gray.100' }}
                        onClick={event => {
                          event.preventDefault();
                          navigate(`/profile/${currentUser?.username}`);
                        }}
                      >
                        Your Portfolio
                      </Button>
                    </Box>
                  </Flex>
                  {/* My Challenges */}
                  {currentUser && (
                    <Flex mx="4" py={12} flexDirection={'column'}>
                      <HStack pb={6}>
                        <UserCircleIcon className="h-6 w-6 text-gray-600" />
                        <Heading
                          color="#2d3748"
                          size="md"
                          justifyContent="space-between"
                        >
                          My Challenges
                        </Heading>
                      </HStack>

                      <Skeleton isLoaded={!isLoading} rounded={'lg'}>
                        {submissions.length > 0 ? (
                          <VStack>
                            {submissions.map(submission => (
                              <Flex
                                key={submission.id}
                                w="100%"
                                justifyContent={'space-between'}
                                alignItems="center"
                                p="10px"
                                mt="10px"
                                rounded="lg"
                                cursor={'pointer'}
                                border={'1px'}
                                borderColor={'gray.200'}
                                _hover={{
                                  shadow: 'md',
                                }}
                                onClick={event => {
                                  event.preventDefault();
                                  navigate(
                                    `/challenges/${submission.challenge.slug}`
                                  );
                                }}
                              >
                                <Flex alignItems="center">
                                  <Image
                                    src={submission.challenge.image}
                                    alt={submission.challenge.title}
                                    w="50px"
                                    h="50px"
                                    objectFit={'cover'}
                                    rounded="lg"
                                  />
                                  <Flex
                                    flexDirection="column"
                                    ml="10px"
                                    justifyContent="center"
                                  >
                                    <Text
                                      fontSize="sm"
                                      fontWeight="bold"
                                      color="#2d3748"
                                    >
                                      {submission.challenge.title}
                                    </Text>
                                    <Text fontSize="sm" color="#718096">
                                      {submission.challenge.track}
                                    </Text>
                                  </Flex>
                                </Flex>

                                <Flex alignItems={'center'}>
                                  <Show above="sm">
                                    <SubmissionStatus
                                      status={submission.status}
                                    />
                                  </Show>
                                  <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                                </Flex>
                              </Flex>
                            ))}
                          </VStack>
                        ) : (
                          <Text fontSize="sm" color="#718096">
                            You haven't started any challenges yet.
                          </Text>
                        )}
                      </Skeleton>
                    </Flex>
                  )}
                  <Flex mx="4" mt="16" flexDirection="column">
                    <HStack alignItems="center">
                      <PuzzlePieceIcon className="h-6 w-6 text-gray-600" />
                      <Heading
                        color="#2d3748"
                        size="md"
                        mx="20px"
                        justifyContent="space-between"
                      >
                        Portfolio-worthy Challenges
                      </Heading>
                    </HStack>
                    <Flex
                      pt={6}
                      h="20"
                      justifyContent={['flex-start', 'space-between']}
                      overflowX="scroll"
                    >
                      <Stack isInline spacing={2}>
                        <Button
                          _focus={{ outline: 'none' }}
                          size={'md'}
                          variant={
                            primaryFilter === PRIMARY_FILTER_RECOMMENDED
                              ? 'solid'
                              : 'outline'
                          }
                          colorScheme="gray"
                          bgColor={
                            primaryFilter === PRIMARY_FILTER_RECOMMENDED
                              ? 'gray.600'
                              : 'white'
                          }
                          rounded="full"
                          textColor={
                            primaryFilter === PRIMARY_FILTER_RECOMMENDED
                              ? 'white'
                              : 'gray.600'
                          }
                          onClick={() =>
                            applyPrimaryFilter(PRIMARY_FILTER_RECOMMENDED)
                          }
                          _hover={{
                            variant: 'solid',
                            shadow: 'md',
                          }}
                        >
                          Recommended
                        </Button>
                        <Button
                          _focus={{ outline: 'none' }}
                          size={'md'}
                          variant={
                            primaryFilter === PRIMARY_FILTER_ALL
                              ? 'solid'
                              : 'outline'
                          }
                          colorScheme="gray"
                          bgColor={
                            primaryFilter === PRIMARY_FILTER_ALL
                              ? 'gray.600'
                              : 'white'
                          }
                          rounded="full"
                          textColor={
                            primaryFilter === PRIMARY_FILTER_ALL
                              ? 'white'
                              : 'gray.600'
                          }
                          _hover={{
                            shadow: 'md',
                          }}
                          onClick={() => applyPrimaryFilter(PRIMARY_FILTER_ALL)}
                        >
                          All
                        </Button>
                      </Stack>
                      <Stack isInline spacing="2" mx="20px">
                        <Select
                          bg="whitesmoke"
                          color="#2d3748"
                          variant="filled"
                          name="track"
                          rounded="full"
                          value={selectedTrack}
                          size="md"
                          onChange={handleFilterChange}
                        >
                          <option value="">Select track</option>
                          <option value="Android Development (with Kotlin)">
                            Android Development
                          </option>
                          <option value="Backend Development">
                            Backend Development
                          </option>
                          <option value="Frontend Development">
                            Frontend Development
                          </option>
                          <option value="Product Management">
                            Product Management
                          </option>
                          <option value="User Interface and Experience Design">
                            UI/UX Design
                          </option>
                        </Select>
                        <Select
                          bg="whitesmoke"
                          color="#2d3748"
                          variant="filled"
                          name="difficulty"
                          value={selectedDifficulty}
                          rounded="full"
                          size="md"
                          onChange={handleFilterChange}
                        >
                          <option value="">Select level</option>
                          <option value="easy">Easy</option>
                          <option value="intermediate">Intermediate</option>
                          <option value="hard">Hard</option>
                        </Select>
                        <Box>
                          <Button
                            rounded={'full'}
                            onClick={handleClearFilters}
                            fontWeight={'semibold'}
                            colorScheme={'gray'}
                            variant={'ghost'}
                            size="md"
                          >
                            Clear filters
                          </Button>
                        </Box>
                      </Stack>
                    </Flex>
                    <ChallengesGrid
                      challengesList={filteredResults}
                      isLoading={isLoading}
                    />
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Challenges);
