import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import MentorService from '../services/mentor-service';

import {
  Badge,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import SEO from '../components/Common/SEO.js';
import { Mixpanel } from '../utils/Mixpanel.js';
import withRouter from '../utils/withRouter.js';
import splitbee from '@splitbee/web';
import MenteeCard from '../components/Mentorship/MenteeCard.jsx';
import { UsersIcon } from '@heroicons/react/24/solid';
import MentorCard from '../components/Mentorship/MentorCard.jsx';

const Connections = () => {
  Mixpanel.track('Connections Page');
  splitbee.track('Connections Page');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [mentors, setMentors] = useState([]);
  const [mentees, setMentees] = useState([]);
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    async function getMentors() {
      try {
        setIsLoading(true);
        const response = await MentorService.getMentorshipConnections('mentor');
        setMentors(response.data.results);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch mentors, please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    getMentors();
  }, [toast]);

  useEffect(() => {
    async function getMentees() {
      try {
        setIsLoading(true);
        const response = await MentorService.getMentorshipConnections('mentee');
        setMentees(response.data.results);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch mentees, please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    getMentees();
  }, [toast]);

  const handleAcceptMentorship = async mentorshipId => {
    try {
      setIsLoading(true);
      await MentorService.updateMentorshipRequest(mentorshipId, 'accept');
      toast({
        title: 'Success!',
        description: 'Mentorship request accepted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'Oops!',
        description:
          'Unable to accept mentorship request, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const handleRejectMentorship = async mentorshipId => {
    try {
      setIsLoading(true);
      await MentorService.updateMentorshipRequest(mentorshipId, 'reject');
      toast({
        title: 'Success!',
        description: 'Mentorship request rejected.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'Oops!',
        description:
          'Unable to reject mentorship request, please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <SEO
        title="Connections - Mentors & Mentees | Techstarta"
        url="https://techstarta.com/connections"
        description={`Connect with mentors and mentees in the Techstarta community.`}
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Connections"
          url={'https://techstarta.com/connections'}
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full">
          <div className="flex flex-wrap mt-4">
            <div className="w-full  mb-12 xl:mb-0 ">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 px-4 border-0">
                  <Flex
                    className="bg-gradient-to-bl from-cyan-400 to-gray-100  rounded-lg overflow-hidden border"
                    p={['10px', '30px', '40px', '40px']}
                    w="auto"
                    justifyContent={[
                      'center',
                      'center',
                      'center',
                      'space-between',
                    ]}
                    flexDirection={['column', 'column', 'column', 'row']}
                    mb="10px"
                    rounded="lg"
                  >
                    <Flex
                      justifyContent="center"
                      alignContent="center"
                      color="gray.700"
                      flexDirection="column"
                    >
                      <Heading textAlign="left" as={'h1'}>
                        Connect with your mentors and mentees
                      </Heading>

                      <Text textAlign="left" fontSize="md">
                        Mentorship is a two-way street. Connect with your
                        mentors and mentees to get the most out of Techstarta.
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex pt={8} flexDirection="column">
                    <Tabs colorScheme="cyan" variant={'enclosed-colored'}>
                      <TabList>
                        <Tab _focus={{ outline: 'none' }} key={'grade'}>
                          <HStack className="align-middle justify-center">
                            <UsersIcon className="h-4 w-4 mr-1"></UsersIcon>
                            <Text fontWeight={'semibold'}> Mentees </Text>
                            <Badge rounded={'full'}>{mentees.length}</Badge>
                          </HStack>
                        </Tab>
                        <Tab _focus={{ outline: 'none' }}>
                          <HStack className="align-middle justify-center">
                            <UsersIcon className="h-4 w-4 mr-1"></UsersIcon>
                            <Text fontWeight={'semibold'}> Mentors </Text>
                            <Badge rounded={'full'}>{mentors.length}</Badge>
                          </HStack>
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel px="-4">
                          <SimpleGrid columns={[1, 2, 2, 3]}>
                            {mentees.map(mentee => (
                              <MenteeCard
                                key={mentee.mentorship_id}
                                mentee={mentee}
                                isLoading={isLoading}
                                handleAccept={handleAcceptMentorship}
                                handleIgnore={handleRejectMentorship}
                              />
                            ))}
                          </SimpleGrid>
                          {mentees.length === 0 && (
                            <Flex
                              flexDirection="column"
                              justifyContent="center"
                              alignContent="center"
                              textAlign="center"
                              w="100%"
                              h="100%"
                            >
                              <Text
                                textColor={'gray.600'}
                                fontSize="lg"
                                fontWeight="bold"
                                mb="10px"
                              >
                                Your mentees will appear here
                              </Text>
                              <Text fontSize="sm" textColor={'gray.600'}>
                                Once you accept a mentorship request, your
                                mentees will appear here.
                              </Text>
                            </Flex>
                          )}
                        </TabPanel>
                        <TabPanel px="-4">
                          <SimpleGrid columns={[1, 2, 2, 3]}>
                            {mentors.map(mentor => (
                              <MentorCard
                                key={mentor.mentorship_id}
                                mentor={mentor}
                                isLoading={isLoading}
                              />
                            ))}
                          </SimpleGrid>
                          {mentors.length === 0 && (
                            <Flex
                              flexDirection="column"
                              justifyContent="center"
                              alignContent="center"
                              textAlign="center"
                              w="100%"
                              h="100%"
                            >
                              <Text
                                textColor={'gray.600'}
                                fontSize="lg"
                                fontWeight="bold"
                                mb="10px"
                              >
                                Your mentors will appear here
                              </Text>
                              <Text fontSize="sm" textColor={'gray.600'}>
                                Once your mentor accepts your request, your
                                mentors will appear here.
                              </Text>
                            </Flex>
                          )}
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Connections);
