import React from 'react';
import { Avatar, Text, Flex, Box, Link } from '@chakra-ui/react';
import Sidebar from '../components/Sidebar';
import DashboardNavbar from '../components/DashboardNavbar';
import DashboardFooter from '../components/DashboardFooter';
import withRouter from '../utils/withRouter.js';

const About = () => {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar title="About" hideBecomeAMentorButton={true} />
        <div className="relative bg-white md:pt-32 pb-16"></div>
        <div className="px-2 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-2 md:px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded shadow-md">
                <div className="w-full rounded-t mb-0 px-2 md:px-4 border-0">
                  <Flex
                    p={['10px', '10px', '20px', '20px']}
                    color="#718096"
                    flexWrap="wrap"
                    justify={['center', 'center', 'center', 'space-between']}
                  >
                    <Box mr={['0px', '10px', '15px', '20px']}>
                      <Avatar
                        src="https://res.cloudinary.com/techstarta/image/upload/v1649515352/k5D1fvvJ_400x400_c4gwkw.png"
                        w="100px"
                        h="100px"
                        mb="25px"
                        fontSize="45px"
                        borderRadius="50%"
                        name="Babajide Owosakin"
                        shadow="lg"
                      />
                    </Box>

                    <Flex
                      fontSize="14px"
                      mt={['20px', '10px', '0px']}
                      flex={['0 0 100%', '1 0 50%', '1 0 50%', '1 0 33%']}
                      direction={['column']}
                    >
                      <Box
                        mb="25px"
                        textAlign={['center', 'start', 'center', 'start']}
                      >
                        <Text color="#2d3748" fontSize="25px" fontWeight="700">
                          Hi, I'm Babajide!
                        </Text>
                      </Box>
                      <Box textColor={'gray.600'}>
                        <Text>
                          I created Techstarta to provide support to people who
                          are looking to kickstart their tech career. My goal is
                          to help anyone looking to start a career in tech get
                          their foot in the door, and the opportunity to help
                          others in the industry by mentoring is something I
                          thoroughly enjoy.
                        </Text>
                        <Text pt={4}>
                          If you're interested in contributing to this
                          initiative, please hit me up!
                        </Text>
                        <Text pt={8}>
                          Say hello at{' '}
                          <Link
                            textDecor={'underline'}
                            href="mailto:jide@techstarta.com"
                          >
                            jide@techstarta.com
                          </Link>{' '}
                          or Tweet{' '}
                          <Link
                            textDecor={'underline'}
                            target="_blank"
                            href="https://twitter.com/jidesakin_?ref=techstarta"
                          >
                            @jidesakin_
                          </Link>
                        </Text>

                        <Text pt={8}>
                          You can also support this initiative by helping us
                          spread the word! Thank you!
                        </Text>
                        <Text pt={8}>- Babajide</Text>
                      </Box>
                    </Flex>
                  </Flex>
                </div>
              </div>
            </div>
            <Flex width="100%" flexWrap="wrap">
              <DashboardFooter />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(About);
