import React, { useState, useRef } from 'react';
import {
  Box,
  Flex,
  Button,
  useToast,
  Text,
  Avatar,
  Spinner,
} from '@chakra-ui/react';
import AuthService from '../services/auth-service';
import Validator from '../utils/validator';
import TextInput from './TextInput';
import { Formik } from 'formik';
import { CameraIcon } from '@heroicons/react/24/outline';

const UpdateUserProfile = () => {
  const toast = useToast();
  const user = JSON.parse(AuthService.getCurrentUser());

  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [username, setUsername] = useState(user.username);
  const [bio, setBio] = useState(user.bio);
  const [email, setEmail] = useState(user.email);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedPhoto, setUploadedPhoto] = useState(null);

  const [showFirstnameError, setShowFirstnameError] = useState(false);
  const [showLastnameError, setShowLastnameError] = useState(false);
  const [showUsernameError, setShowUsernameError] = useState(false);
  const [showBioError, setShowBioError] = useState(false);

  const [maxSize, setMaxSize] = useState(false);

  const fileInput = useRef(null);
  const handlePhotoUpload = event => {
    fileInput.current.click();
  };

  const handleFileChange = event => {
    const image = event.target.files[0];
    if (image.size < 2097152) {
      setMaxSize(false);
      setUploadedPhoto(image);
    } else {
      setMaxSize(true);
    }
  };

  const handleUserProfileUpdate = async () => {
    let isError = false;
    const isFirstnameValid = Validator.isValueValid(firstname);
    const isLastnameValid = Validator.isValueValid(lastname);
    const isUsernameValid = Validator.isValueValid(username);
    const isBioValid = Validator.isValueValid(bio);

    setShowFirstnameError(!isFirstnameValid);
    setShowLastnameError(!isLastnameValid);
    setShowUsernameError(!isUsernameValid);
    setShowBioError(!isBioValid);

    if (
      !isFirstnameValid ||
      !isLastnameValid ||
      !isUsernameValid ||
      !isBioValid
    ) {
      isError = true;
    }

    if (isError) {
      return;
    }

    setIsLoading(true);

    try {
      const updatedProfile = {
        firstname: firstname,
        lastname: lastname,
        username: username,
        bio: bio,
      };

      const userProfile = new FormData();
      if (uploadedPhoto !== null) {
        userProfile.append('image', uploadedPhoto);
      }
      userProfile.append('user', JSON.stringify(updatedProfile));

      const response = await AuthService.updateUserProfile(userProfile);
      if (response === 200) {
        toast({
          title: 'Great!',
          description: 'Your profile has been successfully updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (err) {
      if (err) {
        toast({
          title: 'Oops! Something went wrong.',
          description: 'Profile could not be updated.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Flex
      p="20px"
      h={['100%', '100%', '100%']}
      w={['100%', '100%', '100%', '100%']}
      direction="column"
    >
      <Flex
        w="100%"
        direction={['column', 'row']}
        justifyContent={['center', 'flex-start']}
        alignItems="center"
      >
        <Avatar
          bg="#2d3748"
          color="#dd6b20"
          size="xl"
          name={user.firstname + ' ' + user.lastname}
          src={
            uploadedPhoto
              ? URL.createObjectURL(uploadedPhoto)
              : AuthService.buildImageUrl(user.image)
          }
        />
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          ml={['0px', '15px']}
          mt="25px"
        >
          <Button
            _focus={{ outline: 'none' }}
            onClick={handlePhotoUpload}
            colorScheme="gray"
            size="md"
            rounded="9999px"
            leftIcon={<CameraIcon className="w-5 h-5" />}
          >
            {' '}
            Change profile picture{' '}
          </Button>
          <Text
            display="block"
            mt="5px"
            fontSize={['12px', '10px']}
            color={maxSize ? '#ff0000' : 'grey'}
          >
            {' '}
            * Allowed JPG, GIF or PNG. Max size of 2MB
          </Text>
        </Box>
        <Box
          display="flex"
          w="100px"
          flexDirection="column"
          ml={['0px', '15px']}
          mt="25px"
        >
          <input
            onChange={e => handleFileChange(e)}
            ref={fileInput}
            style={{ display: 'none' }}
            type="file"
          />
          <input
            readOnly
            style={{
              fontSize: '12px',
              display: uploadedPhoto ? 'block' : 'none',
            }}
            value={uploadedPhoto ? uploadedPhoto.name : ''}
            type="text"
          />
        </Box>
      </Flex>

      {/* firstname and lastname */}

      <Flex
        direction={['column', 'row']}
        mt="20px"
        w={['93%', '95%', '95%', 'auto']}
        justifyContent="space-between"
      >
        <TextInput
          title="First name"
          onChangeFunction={e => setFirstname(e.target.value)}
          type="text"
          value={firstname || ''}
          placeholder="Your first name"
          showError={showFirstnameError}
          errorMessage="Your firstname is required"
          isRequired={true}
        />
        <TextInput
          title="Last name"
          onChangeFunction={e => setLastname(e.target.value)}
          type="text"
          addMarginTop={true}
          value={lastname || ''}
          placeholder="Your last name"
          showError={showLastnameError}
          errorMessage="Your lastname is required"
          isRequired={true}
        />
      </Flex>

      {/* Username and Email */}

      <Flex
        direction={['column', 'row']}
        mt="20px"
        w={['93%', '95%', '95%', 'auto']}
        justifyContent="space-between"
      >
        <TextInput
          title="Username"
          onChangeFunction={e => setUsername(e.target.value)}
          type="text"
          isreadonly={true}
          value={username || ''}
          placeholder="Enter your username"
          showError={showUsernameError}
          errorMessage="Your username is required"
          isRequired={true}
        />
        <TextInput
          title="Email"
          onChangeFunction={e => setEmail(e.target.value)}
          type="text"
          addMarginTop={true}
          value={email || ''}
          placeholder="Your email"
          isreadonly={true}
          isRequired={true}
        />
      </Flex>

      {/* Bio */}

      <Flex mt="20px" w={['95%', '97%', '97%', 'auto']} direction="column">
        <TextInput
          title="Bio"
          element="textArea"
          onChangeFunction={e => setBio(e.target.value)}
          value={bio || ''}
          placeholder="Tell everyone a bit more about who you are, and what you're in to. e.g. I'm a Product Designer who loves cycling"
          showError={showBioError}
          errorMessage="Please provide a short bio"
        />
      </Flex>

      <Flex my="20px" justifyContent="flex-end">
        <Button
          _focus={{ outline: 'none' }}
          rounded={'full'}
          onClick={handleUserProfileUpdate}
          bgColor="brand.darkBlue"
          _hover={{ shadow: 'md' }}
          textColor={'white'}
          size="md"
        >
          Save changes
          <Spinner
            display={isLoading ? 'block' : 'none'}
            ml="5px"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="gray.400"
            size="md"
          />
        </Button>
      </Flex>
    </Flex>
  );
};

export default UpdateUserProfile;
