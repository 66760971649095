import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const submitMentorApplication = async payload => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + 'mentors',
    { mentor: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.status;
};

const getMentorList = async () => {
  return await connection.get(API_BASE_URL + 'mentors');
};

const getMentor = async (username, noAuth) => {
  return await connection.get(API_BASE_URL + `mentors/${username}`);
};

const updateMentorProfile = async (username, payload) => {
  const token = AuthService.getAuthToken();
  return await connection.put(
    API_BASE_URL + `mentors/${username}`,
    { mentor: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const getMentorshipConnections = async connectionType => {
  const token = AuthService.getAuthToken();

  return await connection.get(
    API_BASE_URL + `mentorships?mentorship_role=${connectionType}`,
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

const updateMentorshipRequest = async (id, action) => {
  const token = AuthService.getAuthToken();
  return await connection.patch(
    API_BASE_URL + `mentorships/${id}?action=${action}`,
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
};

export default {
  submitMentorApplication: submitMentorApplication,
  getMentorList,
  getMentor,
  updateMentorProfile,
  getMentorshipConnections,
  updateMentorshipRequest,
};
