import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  LinkBox,
  LinkOverlay,
  Stack,
  StackDivider,
  Tag,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import submissionsService from '../services/submissions-service.js';
import MilestoneCard from '../components/Achievements/MilestoneCard.jsx';
import mentorService from '../services/mentor-service.js';
import FeedbackBox from '../components/FeedbackBox.js';

import moment from 'moment';
import { SubmissionItem } from '../components/SubmissionItem.js';

export default function MentorDashboard({ user }) {
  const { firstname, username, is_mentor } = user;
  const [mentor, setMentor] = useState({
    achievements: { feedbacks_given: 0 },
  });
  const [submissions, setSubmissions] = useState();
  const [submissionCount, setSubmissionCount] = useState(0);
  const toast = useToast();

  useEffect(() => {
    async function getSubmission() {
      try {
        const response = await submissionsService.listSubmissions();
        if (response.status === 200) {
          const data = response.data.submissions;
          setSubmissionCount(data.length);
          setSubmissions(data.slice(0, 5));
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          toast({
            title: 'Oops!',
            description: 'An error occurred.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
    getSubmission();
  }, [toast]);

  const getMentorDetails = async () => {
    try {
      const response = await mentorService.getMentor(username);
      setMentor(response.data.mentor);
    } catch (err) {
      toast({
        title: 'Something went wrong',
        description: `We are unable to fetch some details. Please try again later.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getMentorDetails();
  }, [username, toast]);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Home"
          isMentor={is_mentor}
          hideBecomeAMentorButton={false}
        />
        {/* Header */}
        <div className="px-2 md:px-10 mx-auto w-full py-8">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 md:px-4 border-0">
                  <Flex
                    rounded={'lg'}
                    flexDirection="column"
                    bgColor="white"
                    p={2}
                    mb={4}
                    alignContent={'center'}
                  >
                    <Flex color="brand.gray" flexDirection={'column'}>
                      <Text fontSize={'2xl'} fontWeight="bold">
                        Welcome, {`${firstname}!`}
                      </Text>
                      <Text
                        fontSize={'md'}
                        textColor={'gray.700'}
                        fontWeight="normal"
                      >
                        We are thrilled to work with you as a mentor in growing
                        tech talents.
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    rounded={'lg'}
                    p={4}
                    shadow={'sm'}
                    borderWidth="1px"
                    borderColor="gray.100"
                    mb={4}
                    flexDirection="column"
                  >
                    <Text
                      textColor={'gray.600'}
                      fontSize={'md'}
                      fontWeight="semibold"
                    >
                      Recent feedback requests
                    </Text>
                    <Text
                      pb={2}
                      fontSize={'sm'}
                      textColor={'gray.500'}
                      fontWeight="normal"
                    >
                      Provide feedback to learners on their submissions to help
                      them improve their skills.
                    </Text>

                    <Divider />

                    {submissions && submissions.length > 0 ? (
                      <Flex w={'100%'} className="flex flex-col items-left">
                        {submissions.map((submission, index) => (
                          <SubmissionItem
                            key={index}
                            submission={submission}
                            index={index}
                          />
                        ))}
                      </Flex>
                    ) : (
                      <Box pt={4}>
                        <Text
                          textColor={'gray.600'}
                          fontSize={'sm'}
                          fontWeight="normal"
                        >
                          Submissions that require your feedback will appear
                          here.
                        </Text>
                      </Box>
                    )}

                    {submissionCount > 3 ? (
                      <Link
                        textColor={'brand.darkBlue'}
                        fontWeight={'semibold'}
                        size={'sm'}
                        mt={4}
                        alignSelf={'center'}
                        variant={'outline'}
                        width="40"
                        fontSize="sm"
                        href="/submissions"
                      >
                        See more requests
                      </Link>
                    ) : null}
                  </Flex>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-4/12 px-4 pb-12">
              <Flex flexDirection={'column'}>
                <Flex flexDirection={'column'}>
                  <Text
                    pb={'4'}
                    fontSize={'lg'}
                    textColor="gray.700"
                    fontWeight="semibold"
                  >
                    Your achievements
                  </Text>
                  <MilestoneCard
                    actual={mentor?.achievements.feedbacks_given}
                    target={1}
                    trophyColor={'text-emerald-500'}
                  />
                  <MilestoneCard
                    actual={mentor?.achievements.feedbacks_given}
                    target={10}
                    trophyColor={'text-sky-500'}
                  />
                  <MilestoneCard
                    actual={mentor?.achievements.feedbacks_given}
                    target={25}
                    trophyColor={'text-yellow-500'}
                  />
                  <MilestoneCard
                    actual={mentor?.achievements.feedbacks_given}
                    target={50}
                    trophyColor={'text-red-500'}
                  />
                </Flex>
                <FeedbackBox user={user} />
              </Flex>
            </div>
            <Flex width="100%" flexWrap="wrap">
              <DashboardFooter />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
}
