import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Avatar,
  Box,
  Flex,
  Alert,
  AlertIcon,
  Heading,
  Skeleton,
  Text,
  Button,
  useToast,
  Link,
  Stack,
  Textarea,
  Fade,
  HStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Tab } from '@headlessui/react';

import Comment from '../components/Comment';
import RichEditor from '../components/Editor';
import submissionsService from '../services/submissions-service';
import AuthService from '../services/auth-service';
import validator from '../utils/validator';
import { LinkIcon } from '@heroicons/react/24/outline';
import SubmissionStatus from './Submission/SubmissionStatus';
import GradeBox from './Submission/GradeBox';

export const Solution = ({ solution }) => {
  const [editorValue, setEditorValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);
  const [comments, setComments] = useState(solution.comments);
  const toast = useToast();
  const currentUser = JSON.parse(AuthService.getCurrentUser());
  const { id, submitter, updated_at, challenge } = solution;
  const [gradeValue, setGradeValue] = useState(solution.grade);
  const [gradeComment, setGradeComment] = useState(solution.grade_comment);
  const [status, setStatus] = useState(solution.status);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const submitComment = async () => {
    const body = editorValue;
    if (!validator.isValueValid(editorValue)) {
      setIsInputValid(false);
      return;
    }
    const payload = {
      body,
      submission_id: id,
    };
    try {
      setIsLoading(true);

      const response = await submissionsService.submitComment(payload);
      setComments([response.data.comment, ...comments]);
      if (response === 201) {
        toast({
          title: 'Success!',
          description: 'Comment submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      setEditorValue('');
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to submit comment, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const gradeValues = [
    {
      label: 'Excellent',
      value: 5,
    },
    {
      label: 'Good',
      value: 4,
    },
    {
      label: 'Average',
      value: 3,
    },
    {
      label: 'Poor',
      value: 2,
    },
    {
      label: 'Terrible',
      value: 1,
    },
  ];

  const handleGrade = grade => {
    setGradeValue(grade);
  };

  const handleSubmitGrade = async event => {
    event.preventDefault();
    const payload = {
      submissionId: id,
      gradeComment,
      grade: gradeValue,
    };

    try {
      const response = await submissionsService.submitGrading(payload);
      if (response.status === 200) {
        toast({
          title: 'Success!',
          description: 'Grade submitted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setGradeComment(response.data.submission.grade_comment);
        setGradeValue(response.data.submission.grade);
        setStatus(response.data.submission.status);
      }
    } catch (error) {
      toast({
        title: 'Failed to submit',
        description: error.data.submission.detail,
        isClosable: true,
        status: 'error',
        colorScheme: 'red',
        variant: 'left-accent',
        position: 'bottom-left',
        duration: 3000,
      });
    }
  };

  const GradeRating = () => {
    return (
      <>
        <Wrap spacing={2}>
          {gradeValues.map(grade => (
            <WrapItem key={grade.value}>
              <Button
                onClick={() => handleGrade(grade.value)}
                colorScheme={grade.value === gradeValue ? 'orange' : 'gray'}
                className={
                  (gradeValue == grade.value
                    ? 'text-white bg-gray-700'
                    : 'text-gray-700 bg-white') +
                  ' px-4 py-1 text-base font-semibold rounded-lg shadow-sm  ring-gray-600 hover:bg-gray-700 hover:text-white'
                }
                value={grade.value}
                type="button"
                size={'sm'}
              >
                {grade.label}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
      </>
    );
  };

  return (
    <>
      <Flex
        p={4}
        border="1px solid"
        borderColor="gray.100"
        shadow="sm"
        rounded="lg"
        flexDirection="column"
      >
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Flex alignItems={'center'}>
            <Avatar mr={2} name={submitter.fullname} src={submitter.image} />{' '}
            <Link
              href={`/profile/${submitter.username}`}
              textColor={'orange.600'}
              fontWeight={'light'}
              fontSize={'sm'}
              pr="2"
            >
              {submitter.fullname}
            </Link>
          </Flex>
          <HStack spacing={2}>
            <SubmissionStatus status={status} />
            <Text fontSize="xs">{moment(updated_at).fromNow()}</Text>
          </HStack>
        </Flex>
        <Box py={4} justifyContent={'space-between'}>
          <Heading fontSize="lg" color="gray.600">
            {solution.challenge.title}
          </Heading>
        </Box>
        <Flex p={'4'} rounded="lg" borderLeft={'2px'} bg="gray.50">
          <Text fontSize="md" color="gray.700">
            {solution.additional_information}
          </Text>
        </Flex>
        <Box py={4}>
          <Text
            pb={1}
            fontSize={'md'}
            fontWeight="semibold"
            textColor={'gray.700'}
          >
            Details
          </Text>
          <Stack isInline>
            <LinkIcon className="h-4 w-4" />
            <Link
              fontSize={'sm'}
              color="orange.600"
              href={solution.work_link}
              target="_blank"
              isExternal
            >
              Solution
            </Link>
          </Stack>
        </Box>
        {solution.grade != null && solution.assigned_mentor != null ? (
          <Box p={4}>
            <GradeBox
              grade={gradeValue}
              assignedMentor={solution.assigned_mentor}
              comment={gradeComment}
            />
          </Box>
        ) : null}

        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            <Tab
              key={'grade'}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              Grade
            </Tab>
            <Tab
              key={'comments'}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              Comments
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel
              key={'grade'}
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-100 focus:outline-none focus:ring-2'
              )}
            >
              <Box py={4}>
                <Text pb={2} fontSize={'sm'} textColor="gray.600">
                  How would you grade{' '}
                  <span className="font-semibold">{submitter.fullname}'s</span>{' '}
                  solution to the challenge?
                </Text>
                <GradeRating />
                <Fade in={gradeValue !== 0}>
                  <Box flexDirection={'column'}>
                    <Textarea
                      fontSize={'sm'}
                      value={gradeComment}
                      onChange={event => setGradeComment(event.target.value)}
                      my={4}
                      _focus={{ borderColor: 'gray.500' }}
                      placeholder={`What did ${submitter.fullname} did well? What could be improved? Any other feedback`}
                    />
                    <Button
                      type="button"
                      onClick={event => handleSubmitGrade(event)}
                      variant={'solid'}
                      textColor="white"
                      bgColor={'gray.700'}
                      _hover={{ shadow: 'md' }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Fade>
              </Box>
            </Tab.Panel>
            <Tab.Panel
              key={'comments'}
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-100 focus:outline-none focus:ring-2'
              )}
            >
              <Box marginTop="20px">
                {comments.map(comment => (
                  <Comment key={comment.id} comment={comment} />
                ))}
              </Box>
              <Flex
                flexDirection={['column', 'column']}
                p={['5px', '5px', '5px', '10px']}
              >
                <Flex marginTop="20px">
                  <Box pr="5px" className="hidden md:block">
                    <Avatar
                      src={
                        currentUser
                          ? AuthService.buildImageUrl(currentUser.image)
                          : null
                      }
                      name={
                        currentUser
                          ? currentUser.firstname + ' ' + currentUser.lastname
                          : ''
                      }
                    />
                  </Box>
                  <Flex w="100%" flexDirection={'column'}>
                    <RichEditor
                      editorValue={editorValue}
                      setEditorValue={setEditorValue}
                      editorPlaceholder="Write a comment..."
                    />
                    <Alert
                      status="warning"
                      fontSize="sm"
                      style={{
                        display: isInputValid ? 'none' : 'block',
                      }}
                    >
                      <AlertIcon />
                      Comment can not be empty
                    </Alert>
                    <Box pt={4}>
                      <Button
                        _focus={{ outline: 'none' }}
                        bg="brand.darkBlue"
                        _hover={{ shadow: 'md' }}
                        onClick={submitComment}
                        colorScheme="whiteAlpha"
                        isLoading={isLoading}
                        loadingText="Submitting"
                        rounded="md"
                      >
                        Post
                      </Button>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Flex>
    </>
  );
};

export const SolutionSkeleton = () => {
  return (
    <>
      <Box color="#2d3748" mt="30px">
        <Box fontSize="60px">
          <Skeleton h="60px"> </Skeleton>
        </Box>
        <Box display="flex" mt="10px" flexDirection="column">
          <Skeleton mt="20px" h="40px">
            {' '}
          </Skeleton>
          <Skeleton mt="20px" h="30px">
            {' '}
          </Skeleton>
        </Box>
      </Box>
      <Box mt="40px">
        <Box color="#718096">
          <Skeleton h="50px"> </Skeleton>
          <Box>
            <Skeleton mt="20px" h="30px">
              {' '}
            </Skeleton>
            <Skeleton mt="20px" h="40px">
              {' '}
            </Skeleton>
          </Box>
        </Box>
        <Box mt="40px">
          <Skeleton h="50px"> </Skeleton>
          <Box color="#718096">
            <Skeleton mt="20px" h="20px">
              {' '}
            </Skeleton>
            <Skeleton mt="20px" h="40px">
              {' '}
            </Skeleton>
          </Box>
        </Box>
        <Box bg="whitesmoke" mt="40px" p="20px" pt="40px">
          <Skeleton mt="20px" h="30px">
            {' '}
          </Skeleton>
          {/* <Skeleton mt="20px" h="20px"> </Skeleton> */}
        </Box>
      </Box>
    </>
  );
};
