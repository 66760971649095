import { API_BASE_URL, connection } from './index';
import AuthService from './auth-service';

const fetchAllTracks = async () => {
  const token = AuthService.getAuthToken();
  if (!token) {
    const response = await connection.get(API_BASE_URL + 'tracks');
    return response.data;
  }

  const response = await connection.get(API_BASE_URL + `tracks`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });

  return response.data;
};

const joinTrack = async (slug, username) => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + `tracks/${slug}/join`,
    {
      data: {
        user: {
          profile: `${username}`,
        },
      },
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.status;
};

const deleteTrack = async (slug, username) => {
  const token = AuthService.getAuthToken();
  return await connection.delete(
    API_BASE_URL + `tracks/${slug}/join`,
    {
      headers: {
        Authorization: `token ${token}`,
      },
    },
    {
      data: {
        user: {
          profile: `${username}`,
        },
      },
    }
  );
};

const getTrack = async slug => {
  const token = AuthService.getAuthToken();
  if (token) {
    const response = await connection.get(API_BASE_URL + `tracks/${slug}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }

  const response = await connection.get(API_BASE_URL + `tracks/${slug}`);
  return response.data;
};

const getTrackLessons = async slug => {
  const token = AuthService.getAuthToken();
  const response = await connection.get(
    API_BASE_URL + `tracks/${slug}/lessons`,
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.data;
};

const markLessonComplete = async id => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + `lessons/${id}/complete`,
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.status;
};

const undoLessonComplete = async id => {
  const token = AuthService.getAuthToken();
  const response = await connection.delete(
    API_BASE_URL + `lessons/${id}/complete`,
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.status;
};

const fetchRecommendedChallenges = async () => {
  const token = AuthService.getAuthToken();
  if (!token) {
    const response = await connection.get(API_BASE_URL + 'challenges');
    return response.data;
  }
  const response = await connection.get(API_BASE_URL + `challenges`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const fetchAllChallenges = async () => {
  const response = await connection.get(API_BASE_URL + `challenges`);

  return response.data;
};

const getChallenge = async slug => {
  const token = AuthService.getAuthToken();
  if (!token) {
    const response = await connection.get(API_BASE_URL + `challenges/${slug}`);
    return response.data;
  }
  const response = await connection.get(API_BASE_URL + `challenges/${slug}`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });
  return response.data;
};

const createChallenge = async payload => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + 'challenges',
    { challenge: payload },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.status;
};

const startChallenge = async slug => {
  const token = AuthService.getAuthToken();
  const response = await connection.post(
    API_BASE_URL + `challenges/${slug}/join`,
    {},
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );
  return response.status;
};

export default {
  fetchAllTracks,
  joinTrack,
  deleteTrack,
  getTrack,
  getTrackLessons,
  markLessonComplete,
  undoLessonComplete,
  getRecommendedChallenges: fetchRecommendedChallenges,
  fetchAllChallenges,
  getChallenge,
  createChallenge,
  startChallenge,
};
