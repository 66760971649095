import React from "react";
import {
    SimpleGrid
} from "@chakra-ui/react";
import { TopPerformerItem, TopPerformerItemSkeleton } from "./TopPerformerItem";

const TopPerformers = ({ firstPosition, secondPosition, thirdPosition, isLoading }) => {
    return (
        <SimpleGrid columns={[1, 2, 2, 4]} alignItems="flex-end" py="30px" spacingX="30px" spacingY="50px">
            { isLoading ?
                <>
                    <TopPerformerItemSkeleton />
                    <TopPerformerItemSkeleton isFirst={true} />
                    <TopPerformerItemSkeleton />
                </>
                :
                <>
                    <TopPerformerItem userPosition={firstPosition} isFirst={true} order={["1", "1", "1", "2"]} />
                    <TopPerformerItem userPosition={secondPosition}  order={["2", "2", "2", "1"]} />
                    <TopPerformerItem userPosition={thirdPosition}  order={["3", "3", "3", "3"]} />
                </>
            }
        </SimpleGrid>
    )
}

export default TopPerformers;
