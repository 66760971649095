import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  ListItem,
  Skeleton,
  SkeletonText,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';

import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import { Solution, SolutionSkeleton } from '../components/Solution';
import withRouter from '../utils/withRouter.js';

import SubmissionService from '../services/submissions-service';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import MarkdownToHtml from '../components/ReactMarkdownItem.js';

const SubmissionDetail = () => {
  const { submissionsId } = useParams();
  const [solution, setSolution] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    async function getSolution() {
      try {
        setIsLoading(true);
        const response = await SubmissionService.getSubmission(submissionsId);
        setSolution(response.data.submission);
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch this solution, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    getSolution();
  }, [submissionsId, toast]);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Submission Details"
          hideBecomeAMentorButton={true}
        />
        <div className="px-4 md:px-10 mx-auto w-full">
          <Flex p={4}>
            <Button
              size={'sm'}
              textColor={'gray.600'}
              leftIcon={<ArrowLeftIcon className="w-4 h-4" />}
              variant={'ghost'}
              colorScheme="gray"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Flex>
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-5/12">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                <div className="flex flex-wrap items-center text-xs px-4">
                  <Flex
                    flexDirection={'column'}
                    width="100%"
                    className="sticky"
                  >
                    {solution ? (
                      <Flex
                        border="1px solid"
                        borderColor="gray.100"
                        shadow={'sm'}
                        borderRadius={'lg'}
                        p={4}
                        bgColor="white"
                        flexDirection={'column'}
                      >
                        <Flex flexDirection="column">
                          <Text
                            pb={4}
                            fontWeight={'semibold'}
                            textColor={'gray.600'}
                            fontSize="md"
                          >
                            {solution.challenge.title}
                          </Text>
                          <Box
                            textAlign={'justify'}
                            textColor={'gray.700'}
                            fontSize={'sm'}
                            noOfLines={10}
                          >
                            <MarkdownToHtml
                              input={solution.challenge.description}
                            />
                          </Box>
                        </Flex>
                        <Flex pt={'4'} pb={4}>
                          <Button
                            variant={'outline'}
                            size="sm"
                            bgColor={'white'}
                            onClick={() =>
                              navigate(`/challenges/${solution.challenge.slug}`)
                            }
                          >
                            View details
                          </Button>
                        </Flex>
                      </Flex>
                    ) : (
                      <Skeleton>
                        <SkeletonText spacing={'4'} noOfLines={5} />
                      </Skeleton>
                    )}
                    <Skeleton isLoaded={!isLoading} pt={'8'}>
                      <Flex
                        border="1px solid"
                        borderColor="gray.100"
                        flexDirection="column"
                        shadow={'sm'}
                        borderRadius={'lg'}
                        p={4}
                      >
                        <Text
                          pb={4}
                          fontWeight={'semibold'}
                          textColor={'gray.600'}
                          fontSize="md"
                        >
                          How to grade this challenge
                        </Text>
                        <UnorderedList
                          textColor={'gray.700'}
                          fontSize="sm"
                          spacing={'4'}
                        >
                          <ListItem>
                            Read the description of the challenge above.
                            Optionally, You could also view the details of the
                            challenge.
                          </ListItem>
                          <ListItem>
                            Review the solution submitted by your mentee.
                          </ListItem>
                          <ListItem>
                            Grade the solution based on the criteria provided by
                            the challenge.
                          </ListItem>
                          <ListItem>
                            Provide a constructive feedback to your mentee.
                          </ListItem>
                        </UnorderedList>
                      </Flex>
                    </Skeleton>
                  </Flex>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-7/12 mb-12 xl:mb-0 px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 border-0">
                  {solution ? (
                    <Solution solution={solution} />
                  ) : (
                    <SolutionSkeleton />
                  )}
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(SubmissionDetail);
