import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import { Flex, Text, Select, useToast } from '@chakra-ui/react';
import filterSearch from '../utils/filterSearch';
import LeaderboardService from '../services/leaderboard-service';
import LeaderboardList from '../components/LeaderboardList';
import TopPerformers from '../components/TopPerformers';

const AuthLeaderboard = () => {
  const [filterBy, setFilterBy] = useState(filterSearch.FILTER_BY_TODAY);
  const toast = useToast();

  const [userRanking, setUserRanking] = useState('');
  const [firstPosition, setFirstPosition] = useState('');
  const [secondPosition, setSecondPosition] = useState('');
  const [thirdPosition, setThirdPosition] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getLeaderboard() {
      try {
        const response = await LeaderboardService.getLeaderboardRanking();
        if (response.status === 200) {
          const rankings = response.data.leaderboard.results;
          setUserRanking(rankings.slice(3, rankings.length));
          setFirstPosition(rankings[0]);
          setSecondPosition(rankings[1]);
          setThirdPosition(rankings[2]);
          setIsLoading(false);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          toast({
            title: 'Oops!',
            description: 'An error occurred.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
    getLeaderboard();
  }, [toast]);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar title="Leaderboard" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="relative bg-white md:pt-32 pb-16"></div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full  mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0  border-0">
                  <Flex flexDirection="column">
                    <Flex
                      mt={['50px', '50px', '50px', '20px']}
                      py="20px"
                      w="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Flex>
                        <i
                          style={{
                            color: '#718096',
                            fontSize: '30px',
                            paddingRight: '10px',
                          }}
                          className="fas fa-medal"
                        ></i>
                        <Text color="#2d3748">
                          {' '}
                          <strong> Top performers </strong>{' '}
                        </Text>
                      </Flex>
                      <Flex>
                        <Select
                          bg="whitesmoke"
                          color="#2d3748"
                          variant="filled"
                          onChange={event => setFilterBy(event.target.value)}
                        >
                          <option value={filterSearch.FILTER_BY_TODAY}>
                            Today
                          </option>
                          <option value={filterSearch.FILTER_BY_WEEK}>
                            Week
                          </option>
                          <option value={filterSearch.FILTER_BY_MONTH}>
                            Month
                          </option>
                          <option value={filterSearch.FILTER_BY_YEAR}>
                            Year
                          </option>
                          <option value={filterSearch.FILTER_BY_ALL_TIME}>
                            All time
                          </option>
                        </Select>
                      </Flex>
                    </Flex>
                    <TopPerformers
                      firstPosition={firstPosition}
                      secondPosition={secondPosition}
                      thirdPosition={thirdPosition}
                      isLoading={isLoading}
                    />
                    <Flex w="100%" flexDirection="column">
                      <Flex color="#2d3748">
                        <i
                          style={{
                            color: '#718096',
                            fontSize: '30px',
                            paddingRight: '10px',
                          }}
                          className="fas fa-users"
                        ></i>
                        <strong> Other leaders</strong>
                      </Flex>
                      <LeaderboardList userRanking={userRanking} />
                    </Flex>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default AuthLeaderboard;
