import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Button,
  Heading,
  Text,
  Box,
  FormLabel,
  Textarea,
  Input,
  InputGroup,
  InputLeftAddon,
  useToast,
  Stack,
  FormControl,
} from '@chakra-ui/react';
import {
  CheckBadgeIcon,
  FaceSmileIcon,
  GlobeAltIcon,
  BriefcaseIcon,
} from '@heroicons/react/24/outline';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Form, Formik } from 'formik';
import { Select } from 'chakra-react-select';
import * as Yup from 'yup';
import SEO from '../components/Common/SEO.js';
import Navbar from '../components/Navbar';
import mentorService from '../services/mentor-service.js';
import { Mixpanel } from '../utils/Mixpanel';
import authService from '../services/auth-service.js';
import withRouter from '../utils/withRouter.js';

const MentorOnboarding = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const expertises = [
    {
      label: 'Android Development',
      value: 'android-development',
    },
    {
      label: 'Backend Development',
      value: 'backend-development',
    },
    {
      label: 'Frontend Development',
      value: 'frontend-development',
    },
    {
      label: 'User Interface and Experience Design',
      value: 'ui-ux',
    },
    {
      label: 'Product Management',
      value: 'product-management',
    },
    {
      label: 'Content Marketing',
      value: 'content-marketing',
    },
  ];

  const [data, setData] = useState({
    professionalPitch: '',
    funFact: '',
    tracks: [],
    currentRole: '',
    company: '',
    personalWebsite: '',
    linkedIn: '',
    twitter: '',
    calendarLink: '',
    github: '',
    location: '',
  });

  const handleNext = async (newData, isFinalStep = false) => {
    setData(prev => ({ ...prev, ...newData }));
    if (isFinalStep) {
      const payload = {
        pitch: newData.professionalPitch,
        current_role: newData.currentRole,
        company: newData.company,
        linkedin: newData.linkedIn,
        twitter: newData.twitter,
        website: newData.personalWebsite,
        github: newData.github,
        fun_fact: newData.funFact,
        calendly_booking_link: newData.calendarLink,
        location: newData.location,
        tracks: newData.tracks.map(item => item.value),
      };
      try {
        await mentorService.submitMentorApplication(payload);
        Mixpanel.track('Submitted mentor application', {
          tracks: newData.tracks,
        });
        toast({
          title: 'Your application has been submitted for review.',
          description: 'We would get back to you shortly. Thank you.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        authService.fetchCurrentUser();
        nextStep();
      } catch (err) {
        toast({
          title: 'Oops!',
          description: err.data.errors.detail,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      nextStep();
    }
  };

  const handlePrev = newData => {
    setData(prev => ({ ...prev, ...newData }));
    prevStep();
  };

  const { nextStep, prevStep, reset, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const introValidationSchema = Yup.object({
    professionalPitch: Yup.string().required().label('Professional pitch'),
    funFact: Yup.string().label('Fun fact'),
    location: Yup.string().required().label('Location'),
  });

  const experienceAndExperienceValidationSchema = Yup.object({
    tracks: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        })
      )
      .min(1, 'Please select at least one area of expertise')
      .required(),
    currentRole: Yup.string().required().label('Current role'),
    company: Yup.string().required().label('Company'),
  });

  const elsewhereStepValidationSchema = Yup.object({
    linkedIn: Yup.string()
      .url()
      .required('Your linkedin profile link is required'),
    twitter: Yup.string().label('Twitter'),
    calendarLink: Yup.string().label('Calendar link'),
    personalWebsite: Yup.string().label('Personal website'),
    github: Yup.string().label('Github'),
  });

  const IntroStep = props => {
    const handleSubmit = values => {
      props.next(values);
    };

    return (
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={introValidationSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="w-full flex flex-col justify-center">
            {/* Professional Pitch */}
            <Flex mt="30px" mb="30px" w={['100%', '100%']}>
              <FormControl>
                <FormLabel>Your pitch</FormLabel>
                <Textarea
                  name="professionalPitch"
                  value={values.professionalPitch}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Tell us briefly about your professional experience..."
                ></Textarea>
                <Text
                  borderRadius="md"
                  fontSize="sm"
                  mt="1"
                  textColor="red.500"
                >
                  {errors.professionalPitch && touched.professionalPitch
                    ? errors.professionalPitch
                    : null}
                </Text>
              </FormControl>
            </Flex>

            {/* Fun Fact */}
            <FormControl mt="30px" mb="30px" w={['100%', '100%']}>
              <FormLabel>Fun fact</FormLabel>
              <Textarea
                name="funFact"
                value={values.funFact}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="E.g Like the first job you wanted as a kid :)"
              ></Textarea>
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.funFact && touched.funFact ? errors.funFact : null}
              </Text>
            </FormControl>
            <FormControl mt="30px" mb="30px" w={['100%', '100%']}>
              <FormLabel>Which country do your live in?</FormLabel>
              <Input
                name="location"
                value={values.location}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="E.g. Germany"
              ></Input>
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.location && touched.location ? errors.location : null}
              </Text>
            </FormControl>

            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                type="button"
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button size="sm" type="submit">
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const ExpertiseAndExperienceStep = props => {
    const handleSubmit = values => {
      props.next(values);
    };

    return (
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={experienceAndExperienceValidationSchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <Form className="w-full flex flex-col justify-center">
            {/* Expertise */}
            <FormControl mt="30px" mb="30px" w={['100%', '100%']}>
              <FormLabel>Which expertise would you like to mentor?</FormLabel>
              <Select
                placeholder="Select one or more expertise"
                name="tracks"
                value={values.tracks}
                focusBorderColor="orange.500"
                onBlur={handleBlur}
                isMulti
                tagVariant="subtle"
                colorScheme="orange"
                size="sm"
                options={expertises}
                onChange={newValue => setFieldValue('tracks', newValue)}
              />

              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.tracks && touched.tracks ? errors.tracks : null}
              </Text>
            </FormControl>

            {/* Work experience */}
            <FormControl mt="30px" mb="30px" w={['100%', '100%']}>
              <FormLabel>What's your current role?</FormLabel>
              <Input
                name="currentRole"
                value={values.currentRole}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="e.g. Senior Product Manager"
              ></Input>
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.currentRole && touched.currentRole
                  ? errors.currentRole
                  : null}
              </Text>
            </FormControl>
            <FormControl mt="30px" mb="30px" w={['100%', '100%']}>
              <FormLabel>Where do you currently work?</FormLabel>
              <Input
                name="company"
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="E.g. Google"
              ></Input>
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.company && touched.company ? errors.company : null}
              </Text>
            </FormControl>
            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button size="sm" type="submit">
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const ElsewhereStep = props => {
    const handleSubmit = values => {
      props.next(values, true);
    };

    return (
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={elsewhereStepValidationSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form className="w-full flex flex-col justify-center">
            <FormControl w={['95%', '97%', '97%', 'auto']} mt="20px">
              <FormLabel>LinkedIn URL</FormLabel>
              <Input
                name="linkedIn"
                value={values.linkedIn}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="e.g. https://www.linkedin.com/in/yourname/"
              />
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.linkedIn && touched.linkedIn ? errors.linkedIn : null}
              </Text>
            </FormControl>
            <FormControl w={['95%', '97%', '97%', 'auto']} mt="20px">
              <FormLabel>Twitter handle</FormLabel>
              <InputGroup>
                <InputLeftAddon children="@" />
                <Input
                  name="twitter"
                  value={values.twitter}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="E.g. johndoe"
                ></Input>
              </InputGroup>
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.twitter && touched.twitter ? errors.twitter : null}
              </Text>
            </FormControl>
            <FormControl mt="20px" w={['95%', '97%', '97%', 'auto']}>
              <FormLabel>Calendar link</FormLabel>
              <Input
                name="calendarLink"
                value={values.calendarLink}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="E.g. https://calendly.com/yourname/techstarta-mentoring"
              />

              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.calendarLink && touched.calendarLink
                  ? errors.calendarLink
                  : null}
              </Text>
            </FormControl>
            <FormControl w={['95%', '97%', '97%', 'auto']} mt="20px">
              <FormLabel>Personal website</FormLabel>
              <InputGroup>
                <InputLeftAddon children="https://" />
                <Input
                  name="personalWebsite"
                  value={values.personalWebsite}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="E.g. www.johndoe.me"
                />
              </InputGroup>
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.personalWebsite && touched.personalWebsite
                  ? errors.personalWebsite
                  : null}
              </Text>
            </FormControl>
            <FormControl w={['95%', '97%', '97%', 'auto']} mt="20px">
              <FormLabel>Github username</FormLabel>
              <Input
                name="github"
                value={values.github}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="E.g. johndoe"
              ></Input>
              <Text borderRadius="md" fontSize="sm" mt="1" textColor="red.500">
                {errors.github && touched.github ? errors.github : null}
              </Text>
            </FormControl>
            <Flex width="100%" justify="flex-end" pt={4}>
              <Button
                isDisabled={activeStep === 0}
                mr={4}
                onClick={() => props.prev(values)}
                size="sm"
                variant="ghost"
              >
                Prev
              </Button>
              <Button size="sm" type="submit">
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };

  const steps = [
    {
      label: 'Intro',
      component: <IntroStep data={data} next={handleNext} />,
      icon: <FaceSmileIcon className="w-4 h-4" />,
    },
    {
      label: 'Expertise & Experience',
      component: (
        <ExpertiseAndExperienceStep
          data={data}
          next={handleNext}
          prev={handlePrev}
        />
      ),
      icon: <BriefcaseIcon className="w-4 h-4" />,
    },
    {
      label: 'Elsewhere',
      component: (
        <ElsewhereStep data={data} next={handleNext} prev={handlePrev} />
      ),
      icon: <GlobeAltIcon className="w-4 h-4" />,
    },
  ];

  return (
    <>
      <SEO
        title="Mentor Onboarding | Techstarta"
        url="https://techstarta.com/onboarding/mentor"
        description={'Welcome to Techstarta. Setup your account'}
      />
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        bg="#1a202c"
        minHeight="100%"
        backgroundColor="white"
      >
        <Navbar transparent={false} showMenu={false} />

        <div className="flex items-center h-screen justify-between setup w-full">
          <div className="flex w-full md:w-2/3 items-center justify-center setup-buttons onboarding-glass-bg">
            <Flex flexDirection={'column'} className="width-80">
              <Steps colorScheme={'gray'} activeStep={activeStep} py={4}>
                {steps.map(({ label, component, icon }, index) => (
                  <Step key={index} label={label} icon={() => icon}>
                    <Flex p={2}>{component}</Flex>
                  </Step>
                ))}
              </Steps>
              {activeStep === steps.length ? (
                <Flex px={4} py={4} width="100%" flexDirection="column">
                  <Heading fontSize="xl" textAlign="center">
                    Woohoo! All steps completed!
                  </Heading>
                  <Button
                    mx="auto"
                    mt={6}
                    size="sm"
                    onClick={() => navigate('/home')}
                  >
                    Continue to your Dashboard
                  </Button>
                </Flex>
              ) : null}
            </Flex>
          </div>
          <div className="hidden sm:block w-full md:w-1/3">
            <Flex
              height="100%"
              align={'center'}
              flexDirection="column"
              justifyContent={'center'}
            >
              <Text className="text-left text-lg font-semibold pt-8">
                Benefits of Mentoring at Techstarta
              </Text>

              <Stack spacing={'4'} p={'12'}>
                <Stack
                  bg={'white'}
                  key={1}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Solidify your knowledge</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Sharing your knowledge with others is a great way to
                    solidify your own understanding and learn new things.
                  </Text>
                </Stack>
                <Stack
                  key={2}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Contribute to learners progress</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Your feedback can help learners focus on the areas that need
                    the most improvement and help them achieve their goals.
                  </Text>
                </Stack>
                <Stack
                  key={3}
                  bg={'white'}
                  p={4}
                  rounded={'lg'}
                  className="border box-border"
                >
                  <CheckBadgeIcon className="w-6 h-6" />

                  <Text fontWeight={600}>Master your soft skills</Text>
                  <Text color={'gray.600'} className="text-sm">
                    Mentoring is a great way to develop your soft skills, such
                    as communication, leadership, and empathy.
                  </Text>
                </Stack>
              </Stack>
            </Flex>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default withRouter(MentorOnboarding);
