import React, { useState, useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import AuthService from '../services/auth-service';
import EditMentorProfile from '../components/EditMentorProfile';
import MentorService from '../services/mentor-service';
import UpdateUserProfile from '../components/UpdateUserProfile';

const EditUserProfile = () => {
  const user = JSON.parse(AuthService.getCurrentUser());
  const { username } = user;

  const [mentorProfile, setMentorProfile] = useState('');

  useEffect(() => {
    async function getMentorDetails() {
      try {
        const response = await MentorService.getMentor(username);
        if (response.status === 200) {
          setMentorProfile(response.data.mentor);
        }
      } catch (err) {
        setMentorProfile('');
      }
    }
    getMentorDetails();
  }, [username]);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar
          title="Profile settings"
          hideBecomeAMentorButton={true}
        />
        {/* Header */}
        <div className="relative bg-white md:pt-32 pb-16"></div>
        <div className="px-2 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full  mb-12 xl:mb-0 px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="rounded-t mb-0 px-2 border-0">
                  <Tabs
                    color="gray.700"
                    mt={['50px', '50px', '50px', '0px']}
                    w={['100%', '100%', '100%', '70%']}
                    colorScheme="orange"
                  >
                    <TabList>
                      <Tab _focus={{ outline: 'none' }}>Personal Info</Tab>
                      {mentorProfile ? (
                        <Tab _focus={{ outline: 'none' }}>
                          Mentorship profile
                        </Tab>
                      ) : null}
                    </TabList>

                    <TabPanels>
                      <TabPanel boxShadow="sm" rounded={'lg'}>
                        <UpdateUserProfile />
                      </TabPanel>
                      <TabPanel boxShadow="sm" rounded={'lg'}>
                        <EditMentorProfile
                          mentorProfile={mentorProfile}
                          user={user}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          <DashboardFooter title="Dashboard" />
        </div>
      </div>
    </>
  );
};

export default EditUserProfile;
