import React from 'react';
import {
  Flex,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import {
  Square3Stack3DIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/outline';

export default function LessonCard({ lesson, trackSlug }) {
  return (
    <>
      <LinkBox alignContent={'flex-start'} width={'100%'}>
        <Flex
          className="hover:bg-gray-50 hover:cursor-pointer"
          rounded={'lg'}
          borderColor={'gray.100'}
          justifyItems={'space-between'}
          justifyContent={'space-between'}
          width={'100%'}
          p={2}
        >
          <Flex flexDirection={'column'} width="75%">
            <LinkOverlay href={`/skills/${trackSlug}/lessons`}>
              <h3 className="font-semibold text-gray-700">{lesson.title}</h3>
            </LinkOverlay>
            <Text noOfLines={1} textColor="gray.600" fontSize={'md'}>
              {lesson.explainer}
            </Text>
          </Flex>
          <HStack>
            <IconButton
              variant={'outline'}
              rounded={'full'}
              aria-label="Play"
              size={'sm'}
              icon={<Square3Stack3DIcon className="w-4 h-4" />}
            />
            <IconButton
              variant={'outline'}
              rounded={'full'}
              aria-label="Play"
              size={'sm'}
              icon={<PuzzlePieceIcon className="w-4 h-4" />}
            />
          </HStack>
        </Flex>
      </LinkBox>
    </>
  );
}
