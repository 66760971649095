import React from 'react';
import { SimpleGrid, Flex, Text } from '@chakra-ui/react';
import { StoryItem, StoryItemSkeleton } from './StoryItem';

const StoryGrid = ({ stories, isLoading }) => {
  return (
    <div>
      <SimpleGrid
        mt="25px"
        columns={[1, 2, 2, 3]}
        justifyContent="space-evenly"
        spacing="20px"
        mb="20px"
        w="100%"
      >
        {isLoading && (
          <>
            <StoryItemSkeleton />
            <StoryItemSkeleton />
            <StoryItemSkeleton />
          </>
        )}

        {!isLoading &&
          stories.length > 0 &&
          stories.map((story, index) => (
            <StoryItem storyDetail={story} key={index} />
          ))}
      </SimpleGrid>
      {!isLoading && stories.length < 1 && (
        <Flex
          mx={['10px', '20px']}
          px="10px"
          boxShadow="md"
          borderRadius="md"
          p="20px"
        >
          <Text fontWeight="normal" color="#718096">
            {' '}
            There are no stories yet{' '}
          </Text>
        </Flex>
      )}
    </div>
  );
};

export default StoryGrid;
