import React from "react";
import DashboardNavbar from "../components/DashboardNavbar.js";
import Sidebar from "../components/Sidebar.js";
import DashboardFooter from "../components/DashboardFooter.js";
import {
    Flex,
} from "@chakra-ui/react";

import { CreateTeamForm } from "../components/CreateTeamForm";

const CreateTeam = () => {

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-white">
                <DashboardNavbar title="Create Team" hideBecomeAMentorButton={true} />
                {/* Header */}
                <div className="relative bg-white md:pt-32 pb-16"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full  mb-12 xl:mb-0 px-2">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                                <div className="rounded-t mb-0 px-2 border-0">
                                    <Flex w="100%" justifyContent="center">
                                        {/* <CreateTeamFormSkeleton />  */}
                                        <CreateTeamForm />
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DashboardFooter title="Create Team" />
                </div>
            </div>
        </>
    )
}

export default CreateTeam;
