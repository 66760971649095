import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import Sidebar from '../components/Sidebar';
import DashboardNavbar from '../components/DashboardNavbar';
import DashboardFooter from '../components/DashboardFooter';
import { ProfileSection, ProfileSkeleton } from '../components/Profile.js';

import ProfileService from '../services/profile-service';
import authService from '../services/auth-service';
import ChallengeEmptyStateImage from '../images/puzzle.png';
import queryString from 'query-string';
import submissionsService from '../services/submissions-service';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPuzzlePiece,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import withRouter from '../utils/withRouter.js';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import SubmissionStatus from '../components/Submission/SubmissionStatus';
import { SkillWithProgress } from '../components/Skill/SkillWithProgress';

const Profile = () => {
  const { username } = useParams();
  const [profile, setProfile] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const user = JSON.parse(authService.getCurrentUser());
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const queryParams = queryString.parse(location.search);
  const tabs = ['learning', 'portfolio'];

  const groupSubmissionsByTrack = submissions => {
    const tracks = {};
    submissions.forEach(submission => {
      const { track } = submission.challenge;
      if (!tracks[track]) {
        tracks[track] = [];
      }
      tracks[track].push(submission);
    });
    return tracks;
  };

  useEffect(() => {
    async function fetchProfile() {
      const response = await ProfileService.getProfile(username);
      setProfile(response.data.profile);
      if (queryParams.section) {
        setTabIndex(tabs.indexOf(queryParams.section));
      }
    }
    fetchProfile();
  }, [username]);

  useEffect(() => {
    async function fetchSubmissions() {
      const response = await submissionsService.listSubmissions(username);
      setSubmissions(groupSubmissionsByTrack(response.data.submissions));
    }
    fetchSubmissions();
  }, [username]);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-white overflow-x-hidden">
        <DashboardNavbar title="Profile" hideBecomeAMentorButton={true} />
        <div className="md:px-10 mx-auto w-full py-8">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 md:px-4 sm:px-2">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="w-full rounded-t mb-0 px-2 border-0">
                  {profile ? (
                    <div>
                      <Flex
                        shadow="md"
                        rounded="lg"
                        mt={['50px', 0, 0, 0]}
                        direction="column"
                        width={'100%'}
                      >
                        <ProfileSection
                          profile={profile}
                          currentUser={user}
                          username={username}
                        />
                      </Flex>
                      <SimpleGrid mt={8} columns={[1, 2, 2, 3]} spacing={4}>
                        {profile.skills.length > 0 &&
                          profile.skills.map((skill, index) => (
                            <SkillWithProgress key={index} skill={skill} />
                          ))}
                      </SimpleGrid>
                      <Flex
                        mt={8}
                        variant={'unstyled'}
                        color="#718096"
                        flexDirection={'column'}
                      >
                        <Text fontSize="lg" fontWeight="700" color="#2d3748">
                          Portfolio
                        </Text>
                        {submissions.length === 0 && (
                          <Flex
                            flexDirection="column"
                            width="100%"
                            alignItems={'center'}
                          >
                            <Box
                              alignSelf={'center'}
                              justifySelf="center"
                              p={4}
                            >
                              <Image
                                alignSelf={'center'}
                                justifySelf="center"
                                boxSize={'28'}
                                alt="Challenge empty state"
                                src={ChallengeEmptyStateImage}
                              />
                            </Box>
                            <Text p={4}>
                              Challenges you work on will appear here
                            </Text>
                            <Box p={4}>
                              <Button
                                onClick={() => navigate('/challenges')}
                                rounded={'full'}
                                variant={'outline'}
                                _hover={{ bgColor: 'gray.700', color: 'white' }}
                                leftIcon={
                                  <FontAwesomeIcon icon={faPuzzlePiece} />
                                }
                              >
                                Find a challenge
                              </Button>
                            </Box>
                          </Flex>
                        )}
                        {submissions !== null &&
                          Object.entries(submissions).map(([key, value]) => (
                            <Flex flexDirection={'column'} pb="4" key={key}>
                              <Text
                                textColor={'gray.600'}
                                fontWeight="semibold"
                              >
                                {key}
                              </Text>
                              <SimpleGrid
                                mt="4"
                                columns={[1, 2, 2, 3]}
                                justifyContent="space-evenly"
                                spacing="4"
                                mb="20px"
                                w="100%"
                              >
                                {value.length > 0 &&
                                  value.map(submission => (
                                    <LinkBox key={submission.challenge.slug}>
                                      <Flex
                                        _hover={{ boxShadow: 'sm' }}
                                        rounded={'lg'}
                                        flexDirection="column"
                                        className="border border-gray-300"
                                      >
                                        <Image
                                          roundedTop="lg"
                                          src={submission.challenge.image}
                                          objectFit="cover"
                                          height="52"
                                        />
                                        <Stack p={4}>
                                          <LinkOverlay
                                            href={`/challenges/${submission.challenge.slug}`}
                                          >
                                            <Text
                                              textColor={'gray.600'}
                                              fontWeight={'semibold'}
                                              noOfLines={1}
                                              fontSize="lg"
                                            >
                                              {submission.challenge.title}
                                            </Text>
                                            <HStack spacing={2}>
                                              <Text
                                                fontSize={'xs'}
                                                textTransform="capitalize"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faGraduationCap}
                                                  className="w-3 h-3 pr-2"
                                                />
                                                {submission.challenge.track}
                                              </Text>
                                              <HStack spacing={1}>
                                                <ChartBarIcon className="w-3 h-3" />
                                                <Text
                                                  fontSize={'xs'}
                                                  textTransform="capitalize"
                                                >
                                                  {
                                                    submission.challenge
                                                      .difficulty_level
                                                  }
                                                </Text>
                                              </HStack>
                                            </HStack>
                                          </LinkOverlay>
                                          <Text
                                            fontSize="sm"
                                            noOfLines={2}
                                            textColor="gray.700"
                                          >
                                            {submission.challenge.description}
                                          </Text>
                                        </Stack>
                                        <Flex
                                          p={4}
                                          justifyContent={'space-between'}
                                        >
                                          <SubmissionStatus
                                            status={submission.status}
                                          />
                                          <Tag
                                            colorScheme={'gray'}
                                            fontSize="xs"
                                          >
                                            <CalendarDaysIcon className="w-4 h-4 mr-1" />
                                            {moment(
                                              submission.created_at
                                            ).format('ll')}
                                          </Tag>
                                        </Flex>
                                      </Flex>
                                    </LinkBox>
                                  ))}
                              </SimpleGrid>
                            </Flex>
                          ))}
                      </Flex>
                    </div>
                  ) : (
                    <ProfileSkeleton />
                  )}
                </div>
              </div>
            </div>
            <Flex width="100%" flexWrap="wrap">
              <DashboardFooter />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Profile);
