import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box,
  Image,
  Text,
  useToast,
  Icon,
  Flex,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  HStack,
  Heading,
  Skeleton,
  UnorderedList,
  ListItem,
  Link,
  Avatar,
  Tag,
} from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { ChartBarIcon, ListBulletIcon } from '@heroicons/react/24/solid';
import { LinkIcon, PlayCircleIcon } from '@heroicons/react/20/solid';

import DashboardNavbar from '../components/DashboardNavbar.js';
import Sidebar from '../components/Sidebar.js';
import DashboardFooter from '../components/DashboardFooter.js';
import TrackService from '../services/track-service';
import { Mixpanel } from '../utils/Mixpanel';
import withRouter from '../utils/withRouter.js';
import { FaCheckCircle } from 'react-icons/fa';
import { SocialShareBox } from '../components/Common/SocialShareBox.js';

import InclusionItem from '../components/Membership/InclusionItem.jsx';
import MarkdownToHtml from '../components/ReactMarkdownItem.js';
import challengeService from '../services/challenge-service.js';
import Resources from '../components/Track/Resources.js';
import SubmissionStatus from '../components/Submission/SubmissionStatus.jsx';
import authService from '../services/auth-service.js';

const Challenge = () => {
  const navigate = useNavigate();
  const taskRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const cancelRef = useRef();
  const onAlertDialogClose = () => setIsAlertDialogOpen(false);

  const { challengeId: slug } = useParams();
  const [challenge, setChallenge] = useState({});
  const [tasks, setTasks] = useState([]);
  const toast = useToast();

  const [hasJoined, setHasJoined] = useState(challenge.has_joined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleStartChallenge = async () => {
    try {
      setIsSubmitting(true);
      const response = await TrackService.startChallenge(slug);
      Mixpanel.track('Started Challenged', {});
      if (response === 201) {
        toast({
          title: 'Success!',
          description: 'You have successfully started this challenge.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setHasJoined(true);
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description:
          'Unable to start this challenge at this time. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
    setIsAlertDialogOpen(false);
  };

  splitbee.track('Challenge Detail Page', { slug });
  Mixpanel.track('Challenge Detail Page', { slug });

  useEffect(() => {
    async function getCurrentChallenge() {
      try {
        const response = await TrackService.getChallenge(slug);
        setChallenge(response.challenge);
        setHasJoined(response.challenge.has_joined);
        setTasks(response.challenge.tasks);
        document.title = `Techstarta | ${response.challenge.title} Challenge`;
      } catch (err) {
        toast({
          title: 'Oops!',
          description: 'Unable to fetch this challenge, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    getCurrentChallenge();
  }, [slug, toast]);

  const ChallengeStartConfirmationDialog = (
    <AlertDialog
      isOpen={isAlertDialogOpen}
      onClose={onAlertDialogClose}
      color="gray.800"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader color="gray.700" fontSize="lg" fontWeight="bold">
          Welcome to {challenge.title} challenge! &#127881;
        </AlertDialogHeader>

        <AlertDialogBody color="gray.700" fontSize="sm">
          <Box pb="10px">
            <Text>
              At Techstarta, we believe in learning by doing, so we encourage
              you to create a solution worth adding to your portfolio.
            </Text>
            <Text></Text>
          </Box>

          <Box py="10px">
            <Text>We hope you enjoy the challenge!</Text>
          </Box>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button
            rounded={'full'}
            ref={cancelRef}
            variant="outline"
            onClick={onAlertDialogClose}
          >
            Cancel
          </Button>
          <Button
            rounded={'full'}
            isLoading={isSubmitting}
            loadingText="Joining..."
            rightIcon={<PlayCircleIcon className="w-h h-4 text-gray-500" />}
            _focus={{ outline: 'none' }}
            color="white"
            _hover={{
              backgroundColor: 'brand.darkBlue',
              shadow: 'lg',
            }}
            backgroundColor="brand.darkBlue"
            onClick={handleStartChallenge}
            ml={3}
          >
            Start challenge
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  const getUpdatedTasks = (previousTasks, updatedTask) => {
    const indexOfUpdatedTask = [...previousTasks].findIndex(
      task => task.id === updatedTask.id
    );
    const updatedTasks = [...previousTasks];
    updatedTasks[indexOfUpdatedTask] = updatedTask;

    return updatedTasks;
  };

  const handleMarkTaskAsComplete = async taskId => {
    try {
      const response = await challengeService.markTaskComplete(taskId);
      if (response.status === 200) {
        toast({
          title: 'Bravo! 👏',
          description: "You're on a journey to success!",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        setTasks(getUpdatedTasks(tasks, response.data.task));
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to mark lesson complete, please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUndoTaskCompleted = async taskId => {
    try {
      const response = await challengeService.undoTaskComplete(taskId);
      if (response.status === 200) {
        toast({
          title: 'Task undone!',
          description: "Keep going, you're doing great!",
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        setTasks(getUpdatedTasks(tasks, response.data.task));
      }
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Unable to undo lesson complete, please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={challenge.description} />
        <title>{`Techstarta | ${challenge.title} Challenge`}</title>
      </Helmet>
      <Sidebar />
      <div className="relative md:ml-64 bg-white">
        <DashboardNavbar title="Challenge" hideBecomeAMentorButton={true} />
        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full pt-8">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 xl:mb-12 mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg">
                <div className="rounded-t mb-0 px-2 border-0">
                  <Button
                    marginBottom="12px"
                    size={'sm'}
                    cursor={'pointer'}
                    onClick={() => navigate(-1)}
                    variant="ghost"
                    fontSize={'sm'}
                    w={'20'}
                    leftIcon={
                      <FontAwesomeIcon
                        icon={faArrowLeftLong}
                        className="text-gray-500"
                      />
                    }
                  >
                    Back
                  </Button>
                  <Skeleton isLoaded={!isLoading} rounded="lg">
                    <Box
                      rounded="lg"
                      shadow="sm"
                      border={'1px'}
                      borderColor="gray.100"
                      mt={['30px', '40px', '40px', '0px']}
                      display="flex"
                      flexDirection="column"
                    >
                      <Image
                        objectFit={'cover'}
                        roundedTopLeft="lg"
                        roundedTopRight="lg"
                        w={'full'}
                        height="200px"
                        src={challenge.image}
                        alt={challenge.title}
                      />
                      <Flex
                        display={{ md: 'flex' }}
                        color="gray.800"
                        flexDirection={'column'}
                        pb="4"
                      >
                        <Box mt={{ base: 4, md: 4 }} w={'100%'} px="6">
                          <Text
                            fontWeight="bold"
                            fontSize="x-large"
                            letterSpacing="wide"
                            color="gray.600"
                          >
                            {challenge.title}
                          </Text>

                          <Flex
                            mt={4}
                            flexDirection={['column', 'row']}
                            justifyItems={'flex-start'}
                            alignItems={'center'}
                          >
                            <Tag
                              colorScheme="gray"
                              bgColor="brown.100"
                              fontSize={'xs'}
                              rounded="full"
                              mr={[0, 4]}
                              mb={[2, 0]}
                            >
                              {challenge.track}
                            </Tag>
                            <HStack
                              spacing={1}
                              alignItems="center"
                              color="gray.600"
                              mr={[0, 4]}
                              mb={[2, 0]}
                            >
                              <ChartBarIcon className="h-4 w-4" />
                              <Text
                                letterSpacing="wide"
                                textTransform="capitalize"
                                fontSize={'sm'}
                              >
                                {challenge.difficulty_level}
                              </Text>
                            </HStack>
                            {tasks.length > 0 && (
                              <Button
                                onClick={() =>
                                  taskRef.current.scrollIntoView({
                                    behavior: 'smooth',
                                  })
                                }
                                variant={'outline'}
                                rounded={'lg'}
                                size="sm"
                                leftIcon={
                                  <ListBulletIcon className="w-4 h-4" />
                                }
                              >
                                {tasks.length} Tasks
                              </Button>
                            )}
                          </Flex>
                        </Box>
                      </Flex>
                      <Divider />
                      {challenge?.what_you_will_learn && (
                        <Flex p={6} flexDirection="column" mb={4}>
                          <Heading color="gray.600" fontSize="lg" mb={4}>
                            Skills your will gain:
                          </Heading>
                          <Flex w={'100%'}>
                            <MarkdownToHtml
                              input={challenge?.what_you_will_learn}
                            />
                          </Flex>
                        </Flex>
                      )}
                    </Box>
                  </Skeleton>
                  {/* Brief */}
                  <Flex
                    mt={4}
                    p={4}
                    rounded="lg"
                    shadow="sm"
                    border={'1px'}
                    borderColor="gray.100"
                    flexDirection="column"
                  >
                    <Skeleton isLoaded={!isLoading}>
                      <Heading color="gray.600" fontSize="lg" mb={4}>
                        Brief
                      </Heading>
                    </Skeleton>
                    <Skeleton isLoaded={!isLoading}>
                      <article className="prose prose-lg">
                        <MarkdownToHtml input={challenge?.description} />
                      </article>
                    </Skeleton>
                  </Flex>
                  {/* Tasks */}
                  {tasks.length > 0 && (
                    <Box
                      ref={taskRef}
                      mt={8}
                      p={4}
                      rounded="lg"
                      shadow="sm"
                      border={'1px'}
                      borderColor="gray.100"
                    >
                      <Skeleton isLoaded={!isLoading}>
                        <Heading color="gray.600" mb="4" fontSize="lg">
                          Tasks
                        </Heading>
                      </Skeleton>
                      <Skeleton isLoaded={!isLoading}>
                        <Box border={1} borderColor="gray.100">
                          <Accordion
                            m={-4}
                            border={1}
                            borderColor="gray.100"
                            mt="10px"
                            _focus={{ outline: 'none' }}
                            rounded="md"
                            defaultIndex={[0]}
                          >
                            {tasks?.map((task, index) => (
                              <AccordionItem key={index}>
                                <AccordionButton
                                  w="100%"
                                  _expanded={{
                                    bg: 'gray.500',
                                    textColor: 'white',
                                  }}
                                  _focus={{ outline: 'none' }}
                                >
                                  <Flex
                                    w={'100%'}
                                    flexDirection={'row'}
                                    textAlign="left"
                                    fontWeight="bold"
                                    fontSize="14px"
                                    alignItems={'center'}
                                  >
                                    <Icon
                                      as={FaCheckCircle}
                                      color={
                                        task?.is_completed
                                          ? 'green.500'
                                          : 'gray.200'
                                      }
                                      mr="8px"
                                    />
                                    <Text>
                                      Task {task?.index}: {task?.title}
                                    </Text>
                                  </Flex>
                                  <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel
                                  _focus={{ outline: 'none' }}
                                  pb={4}
                                  flexDirection="column"
                                >
                                  <Box className="py-4" textColor="gray.600">
                                    <MarkdownToHtml
                                      classname="markdown"
                                      input={task?.description}
                                    />
                                  </Box>
                                  {task?.resources.length > 0 && (
                                    <>
                                      <Text
                                        fontSize={'xs'}
                                        pb="4"
                                        textColor="gray.600"
                                        fontWeight="semibold"
                                        textTransform={'uppercase'}
                                      >
                                        Relevant Resources
                                      </Text>
                                      <Resources resources={task?.resources} />
                                    </>
                                  )}

                                  <Box
                                    direction={'column'}
                                    className="py-4 flex justify-end"
                                  >
                                    {task?.is_completed ? (
                                      <Button
                                        rounded="full"
                                        size="sm"
                                        isLoading={false}
                                        loadingText="Working..."
                                        fontSize="sm"
                                        onClick={event => {
                                          event.preventDefault();
                                          handleUndoTaskCompleted(task.id);
                                        }}
                                        leftIcon={<FaCheckCircle />}
                                        colorScheme="green"
                                      >
                                        Completed
                                      </Button>
                                    ) : (
                                      <Button
                                        bg="white"
                                        rounded="full"
                                        _hover={{ shadow: 'md' }}
                                        size="sm"
                                        isLoading={false}
                                        fontSize="sm"
                                        loadingText="Working..."
                                        onClick={event => {
                                          event.preventDefault();
                                          handleMarkTaskAsComplete(task.id);
                                        }}
                                        leftIcon={<FaCheckCircle />}
                                        variant="outline"
                                      >
                                        Mark as completed
                                      </Button>
                                    )}
                                  </Box>
                                </AccordionPanel>
                              </AccordionItem>
                            ))}
                          </Accordion>
                        </Box>
                      </Skeleton>
                    </Box>
                  )}
                  {/* Additional information */}
                  {challenge?.instructions && (
                    <Box
                      mt={4}
                      p={4}
                      rounded="lg"
                      shadow="sm"
                      border={'1px'}
                      borderColor="gray.100"
                    >
                      <Skeleton isLoaded={!isLoading}>
                        <Heading color="gray.600" mb={4} fontSize="lg">
                          Additional Information
                        </Heading>
                      </Skeleton>
                      <Skeleton isLoaded={!isLoading}>
                        <MarkdownToHtml
                          classname="markdown"
                          input={challenge.instructions}
                        />
                      </Skeleton>
                    </Box>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full xl:w-4/12 md:px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                <div className="rounded-t mb-0 py-3 border-0">
                  <div className="flex flex-wrap items-center text-sm text-gray-600">
                    <Skeleton isLoaded={!isLoading} width={'full'}>
                      {!challenge.submission && (
                        <Flex
                          width={'full'}
                          shadow="sm"
                          mt={[0, 0, 0, 10]}
                          rounded={'lg'}
                          border="1px"
                          borderColor={'gray.100'}
                          mb={4}
                          p={4}
                          flexDirection={'column'}
                        >
                          <Text
                            pb={8}
                            fontWeight="semibold"
                            fontSize={'md'}
                            textColor="gray.600"
                          >
                            What you'll get
                          </Text>
                          <InclusionItem label="Pared down real-world challenge" />
                          <InclusionItem label="Access to hand-picked resources" />
                          <InclusionItem label="Access to personalized expert mentor review" />
                          <InclusionItem label="Build your portfolio" />
                          <Box pt={4}>
                            {!hasJoined && !challenge.submission && (
                              <Button
                                width={'100%'}
                                variant="solid"
                                rounded={'full'}
                                _focus={{ outline: 'none' }}
                                onClick={
                                  hasJoined
                                    ? onOpen
                                    : () => setIsAlertDialogOpen(true)
                                }
                                bgColor="brand.darkBlue"
                                textColor={'white'}
                                size="lg"
                                _hover={{
                                  shadow: 'md',
                                }}
                              >
                                Start Challenge
                              </Button>
                            )}
                          </Box>
                        </Flex>
                      )}
                    </Skeleton>

                    {challenge?.submission ? (
                      <Box
                        rounded={'lg'}
                        border="1px"
                        borderColor={'gray.100'}
                        p={4}
                        mt={[0, 0, 0, 10]}
                        width={'full'}
                      >
                        <Heading
                          fontSize="xs"
                          color="gray.600"
                          textTransform="uppercase"
                          fontWeight="semibold"
                          mb="2"
                        >
                          Your submission
                        </Heading>
                        <Text fontSize={'xs'} textColor="gray.600">
                          <strong>Last updated:</strong>{' '}
                          {moment(challenge?.submission?.update_at).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </Text>
                        <Flex
                          py="4"
                          flexDirection={'column'}
                          textColor="gray.600"
                        >
                          <Text
                            pb={2}
                            fontSize={'sm'}
                            fontWeight="semibold"
                            color="gray.600"
                          >
                            Comment
                          </Text>
                          <Box p={2} bg={'orange.50'} rounded="lg">
                            {challenge.submission.additional_information?.trim()
                              .length > 0 ? (
                              <MarkdownToHtml
                                classname="markdown"
                                input={
                                  challenge.submission.additional_information
                                }
                              />
                            ) : (
                              <Text color="gray.400" fontStyle={'italic'}>
                                No additional information provided
                              </Text>
                            )}
                          </Box>
                          <Flex p={2} alignContent={'center'}>
                            <LinkIcon className="mr-2 w-4 h-4 text-gray-600" />{' '}
                            {challenge.submission?.url ? (
                              <Link
                                href={challenge.submission.work_link}
                                isExternal
                                color={'orange.500'}
                              >
                                Link to your work
                              </Link>
                            ) : (
                              <Text fontStyle={'italic'} color="gray.400">
                                No link provided
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                        <Box pt={4}>
                          <SubmissionStatus
                            status={challenge.submission.status}
                          />
                        </Box>
                        <Box pt={4} w="100%">
                          <Button
                            width={'100%'}
                            variant="solid"
                            rounded={'full'}
                            _focus={{ outline: 'none' }}
                            onClick={() => {
                              navigate(`/challenges/${challenge.slug}/submit`);
                            }}
                            bgColor="brand.darkBlue"
                            textColor={'white'}
                            size="md"
                            _hover={{
                              shadow: 'md',
                            }}
                          >
                            {hasJoined &&
                              !challenge.submission &&
                              'Submit your work'}

                            {hasJoined &&
                              challenge.submission &&
                              'Edit submission'}
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        mt="4"
                        rounded={'md'}
                        border="1px"
                        borderColor={'gray.100'}
                        p={4}
                        mb={4}
                        width={'full'}
                      >
                        <Skeleton isLoaded={!isLoading}>
                          <Heading
                            fontSize={'md'}
                            color="gray.600"
                            fontWeight="semibold"
                            mb="4"
                          >
                            How to submit your solution
                          </Heading>
                        </Skeleton>
                        <Skeleton isLoaded={!isLoading}>
                          <Box fontSize={'sm'} textColor={'gray.700'}>
                            <UnorderedList spacing={2}>
                              <ListItem>
                                <Text>
                                  <strong>Step 1:</strong> Complete all the
                                  tasks in the challenge
                                </Text>
                              </ListItem>
                              <ListItem>
                                <Text>
                                  <strong>Step 2:</strong> Submit your solution.
                                  Make sure the link to you solution is public
                                  and accessible.
                                </Text>
                              </ListItem>
                              <ListItem>
                                <Text>
                                  <strong>Step 3:</strong> Get feedback from our
                                  expert mentors
                                </Text>
                              </ListItem>
                            </UnorderedList>
                          </Box>
                        </Skeleton>
                      </Box>
                    )}
                    <Skeleton isLoaded={!isLoading} width="full" rounded={'lg'}>
                      <Box
                        mt={4}
                        rounded={'lg'}
                        border="1px"
                        borderColor={'gray.100'}
                        p={4}
                        mb={4}
                        width={'full'}
                      >
                        <Box mb="4">
                          <Heading
                            fontSize="lg"
                            color="gray.600"
                            fontWeight="semibold"
                          >
                            Available Mentors
                          </Heading>
                          <Text color="gray.700" fontSize={'sm'}>
                            Get feedback from our expert mentors
                          </Text>
                        </Box>
                        {challenge?.track_detail?.mentors?.map(
                          (mentor, index) => (
                            <Flex
                              key={index}
                              alignItems="center"
                              justifyContent="space-between"
                              mb="4"
                            >
                              <Flex alignItems="center">
                                <Avatar
                                  size="md"
                                  name={
                                    mentor?.firstname + ' ' + mentor?.lastname
                                  }
                                  src={authService.buildImageUrl(mentor?.image)}
                                />
                                <Box>
                                  <Text
                                    ml="2"
                                    fontSize="md"
                                    fontWeight={'semibold'}
                                  >
                                    {mentor?.firstname} {mentor?.lastname}
                                  </Text>
                                  <Text ml="2" fontSize="sm">
                                    {mentor?.current_role}, {mentor?.company}
                                  </Text>
                                </Box>
                              </Flex>
                            </Flex>
                          )
                        )}
                      </Box>
                    </Skeleton>
                    <Skeleton isLoaded={!isLoading} w={'full'}>
                      <SocialShareBox
                        boxTitle="Share this challenge"
                        contentTitle={challenge.title}
                        contentType="challenge"
                      />
                    </Skeleton>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto"></div>
              </div>
            </div>
          </div>
          {ChallengeStartConfirmationDialog}
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Challenge);
