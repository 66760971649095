import React, { useState, useEffect } from "react";
import {
    Flex,
    Text,
    Button,
    Skeleton,
    Heading,
    Divider,
    Box,
    Input,
    Alert,
    AlertIcon,
    useToast
} from "@chakra-ui/react";
import TextInput from "./TextInput";
import Validator from "../utils/validator";
import TrackService from "../services/track-service";
import { EditorState, convertToRaw } from "draft-js";
import RichEditor from "./Editor";

export const CreateChallengeForm = () => {

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false)

    const [trackList, setTrackList] = useState("")
    useEffect(
        () => {
            async function getAllTracks() {
                const response = await TrackService.fetchAllTracks()
                setTrackList(response.tracks)
            }
            getAllTracks()
        }, []
    )

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [instructions, setInstructions] = useState("");
    const [track, setTrack] = useState("");
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [submissionDate, setSubmissionDate] = useState("");
    const [submissionTime, setSubmissionTime] = useState("");
    const [inspirationLink, setInspirationLink] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [prizeSummary, setPrizeSummary] = useState("");

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [showTitleError, setShowTitleError] = useState(false)
    const [showDescriptionError, setShowDescriptionError] = useState(false)
    const [showTrackError, setShowTrackError] = useState(false)
    const [showStartDateError, setShowStartDateError] = useState(false)
    const [showSubmissionDateError, setShowSubmissionDateError] = useState(false)
    const [showDifficultyError, setShowDifficultyError] = useState(false)

    const createChallenge = async () => {
        let isError = false;

        const isTitleValid = Validator.isValueValid(title);
        const isDescriptionValid = Validator.isValueValid(description);
        const isTrackValid = Validator.isValueValid(track);
        const isStartDateValid = Validator.isValueValid(startDate);
        const isSubmissionDateValid = Validator.isValueValid(submissionDate);
        const isDifficultyValid = Validator.isValueValid(difficulty);

        setShowTitleError(!isTitleValid)
        setShowDescriptionError(!isDescriptionValid)
        setShowTrackError(!isTrackValid)
        setShowStartDateError(!isStartDateValid)
        setShowSubmissionDateError(!isSubmissionDateValid)
        setShowDifficultyError(!isDifficultyValid)

        if (!isTitleValid || !isDescriptionValid || !isTrackValid || !isStartDateValid || !isSubmissionDateValid || !isDifficultyValid
        ) {
            isError = true;
        }

        if (isError) {
            return;
        }

        setIsLoading(true)

        const trackSlug = trackList.filter(trackItem => {
            return trackItem.title === track
        })[0].slug

        const prizeBlocks = convertToRaw(editorState.getCurrentContent()).blocks
        const prizeDetails = prizeBlocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        const payload = {
            title,
            description,
            track,
            track_slug: trackSlug,
            instructions,
            difficulty_level: difficulty,
            submission_requirements: instructions,
            challenge_start_date: new Date(startDate + " " + startTime).toISOString(),
            submission_deadline: new Date(submissionDate + " " + submissionTime).toISOString(),
            inspiration_link: inspirationLink,
            prize_detail: prizeDetails,
            prize_summary: prizeSummary
        }
        try {
            const response = await TrackService.createChallenge(payload)
            if (response === 201) {
                toast({
                    title: "Great!",
                    description: "Your challenge was created successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (err) {
            toast({
                title: "Oops!",
                description: "Unable to create challenge, please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
        setIsLoading(false)
    }

    return (
        <>
            <Flex
                mt={["40px", "40px", "0px"]}
                p="20px"
                boxShadow="md"
                h={["100%", "100%", "100%"]}
                w={["100%", "100%", "100%", "100%"]}
                direction="column">
                <Flex alignItems="center" mt={["10px"]}>
                    <Heading ml="10px" size="lg" color="#2d3748">
                        Create a new challenge
                    </Heading>
                </Flex>
                <Divider />
                <Flex mt="20px" direction="column">

                    {/* Title and Difficulty */}

                    <Flex
                        boxSizing="border-box"
                        direction={["column", "column", "column", "row"]}
                        justifyContent="space-between"
                        w={["93%", "95%", "95%", "auto"]}
                    >
                        <TextInput title="Title" type="text" onChangeFunction={(e) => setTitle(e.target.value)}
                            value={title || ""} placeholder="Enter challenge title" showError={showTitleError} errorMessage="Please provide a valid title."
                        />

                        <Box mt={["20px", "20px", "20px", "0px"]} w={["100%", "100%"]} mx="10px">
                            <Text color="#2d3748" mb="5px"> Track </Text>
                            <Input
                                w="100%"
                                type="text"
                                list="tracks"
                                focusBorderColor="#dd6b20"
                                color="#2d3748"
                                placeholder="Enter challenge track"
                                onChange={e => setTrack(e.target.value)}
                                value={track}
                                fontSize={["sm", "md"]}
                            />
                            <datalist id="tracks">
                                {
                                    trackList ? trackList.map((track, index) => <option key={index} value={track.title}> </option>) : null
                                }
                            </datalist>
                            <Alert
                                borderRadius="md"
                                fontSize="sm"
                                style={{
                                    display: showTrackError
                                        ? "block"
                                        : "none",
                                }}
                                mt="1"
                                status="error"
                            >
                                <AlertIcon size="13px" />
                                Please choose a track.
                            </Alert>
                        </Box>
                    </Flex>

                    {/* Description */}
                    <Box w={["95%", "97%", "97%", "auto"]} mt="20px">
                        <TextInput title="Description" element="textArea" onChangeFunction={(e) => setDescription(e.target.value)}
                            value={description || ""} placeholder="Describe the challenge" showError={showDescriptionError} errorMessage="Please add a description for this challenge."
                        />
                    </Box>

                    {/* Instructions */}
                    <Box w={["95%", "97%", "97%", "auto"]} mt="20px">
                        <TextInput title="Instructions" element="textArea" onChangeFunction={(e) => setInstructions(e.target.value)}
                            value={instructions || ""} placeholder="Enter instructions to be followed"
                        />
                    </Box>

                    {/* Start and Submission dates */}

                    <Flex
                        direction={["column", "column", "column", "row"]}
                        mt="20px"
                        justifyContent="space-between"
                        w={["93%", "95%", "95%", "auto"]}
                    >
                        <TextInput
                            value={startDate || ""}
                            title="Start date"
                            type="date"
                            onChangeFunction={(e) => setStartDate(e.target.value)}
                            showError={showStartDateError}
                            errorMessage="Please provide a start date."
                        />
                        <TextInput
                            value={submissionDate || ""}
                            title="Submission date"
                            type="date"
                            onChangeFunction={(e) => setSubmissionDate(e.target.value)}
                            addMarginTop={true}
                            showError={showSubmissionDateError}
                            errorMessage="Please provide a submission date."
                        />
                    </Flex>

                    {/* Start and Submission Time */}

                    <Flex
                        direction={["column", "column", "column", "row"]}
                        mt="20px"
                        justifyContent="space-between"
                        w={["93%", "95%", "95%", "auto"]}
                    >
                        <TextInput title="Start time" type="time" onChangeFunction={(e) => setStartTime(e.target.value)}
                            value={startTime || ""}
                        />
                        <TextInput value={submissionTime || ""} title="Submission time" type="time" onChangeFunction={(e) => setSubmissionTime(e.target.value)}
                            addMarginTop={true}
                        />
                    </Flex>

                    {/* Inspiration Link */}

                    <Flex
                        direction={["column", "column", "column", "row"]}
                        mt="20px"
                        justifyContent="space-between"
                        w={["93%", "95%", "95%", "auto"]}
                    >
                        <TextInput title="Inspiration" type="url" onChangeFunction={(e) => setInspirationLink(e.target.value)}
                            value={inspirationLink || ""} placeholder="A link to an example to inspire"
                        />
                        <Box mt={["20px", "20px", "20px", "0px"]} w={["100%", "100%"]} mx="10px">
                            <Text color="#2d3748" mb="5px"> Difficulty </Text>
                            <Input
                                type="text"
                                list="difficulties"
                                focusBorderColor="#dd6b20"
                                color="#2d3748"
                                placeholder="Select the challenge difficulty level"
                                onChange={e => setDifficulty(e.target.value)}
                                value={difficulty}
                                fontSize={["sm", "md"]}
                            />
                            <datalist id="difficulties">
                                <option value="Easy"> </option>
                                <option value="Intermediate"> </option>
                                <option value="Hard"> </option>
                            </datalist>
                            <Alert
                                borderRadius="md"
                                fontSize="sm"
                                style={{
                                    display: showDifficultyError
                                        ? "block"
                                        : "none",
                                }}
                                mt="1"
                                status="error"
                            >
                                <AlertIcon size="13px" />
                                Please choose a difficulty level.
                            </Alert>
                        </Box>
                    </Flex>

                    {/* Prize summary */}

                    <Flex mt="20px">
                        <TextInput title="Prize summary" type="text" onChangeFunction={e => setPrizeSummary(e.target.value)}
                            value={prizeSummary || ""} placeholder="Enter prize summary e.g. $money, Knowledge, Swags" maxlength={15}
                        />
                    </Flex>

                    {/* Prize description */}

                    <Flex mx="10px" mt="20px" direction="column">
                        <Text color="#2d3748" mb="5px"> Prize Details </Text>
                        <RichEditor
                            editorState={editorState}
                            setEditorState={setEditorState}
                            placeholder="Describe the prize to be won"
                        />
                    </Flex>

                    <Flex mx="20px" my="20px" justifyContent={["center", "flex-end"]}>
                        <Button
                            _focus={{ outline: "none" }}
                            colorScheme="orange"
                            onClick={createChallenge}
                            isLoading={isLoading}
                            loadingText="Creating"
                            size="md">
                            Create challenge
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export const CreateChallengeFormSkeleton = () => {
    return (
        <Flex
            p="20px"
            boxShadow="md"
            h={["100%", "100%", "100%"]}
            w={["100%", "100%", "100%", "100%"]}
            direction="column">
            <Flex alignItems="center" mt={["10px"]}>
                <Skeleton w="100%" h="50px"></Skeleton>
            </Flex>
            <Divider />
            <Flex mt="20px" direction="column">

                {/* Title and Difficulty */}

                <Flex
                    direction={["column", "column", "column", "row"]}
                    justifyContent="space-between"
                >
                    <Box w={["100%", "100%"]} mx="10px">
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
                    </Box>

                    <Box w={["100%", "100%"]} mt={["20px", "20px", "20px", "0px"]} mx="10px">
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
                    </Box>
                </Flex>

                {/* Description */}
                <Box mx={["0px", "0px", "0px", "10px"]} mt="20px">
                    <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                    <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
                </Box>


                {/* Instructions */}
                <Box mx={["0px", "0px", "0px", "10px"]} mt="20px">
                    <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                    <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
                </Box>


                {/* Start and Submission dates */}

                <Flex
                    direction={["column", "column", "column", "row"]}
                    mt="20px"
                    justifyContent="space-between"
                >
                    <Box w={["100%", "100%"]} mx={["0px", "0px", "0px", "10px"]}>
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
                    </Box>
                    <Box w={["100%", "100%"]} mt={["20px", "20px", "20px", "0px"]} mx={["0px", "0px", "0px", "10px"]}>
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
                    </Box>
                </Flex>

                {/* Start and Submission Time */}

                <Flex
                    direction={["column", "column", "column", "row"]}
                    mt="20px"
                    justifyContent="space-between"
                >
                    <Box w={["100%", "100%"]} mx={["0px", "0px", "0px", "10px"]}>
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
                    </Box>
                    <Box w={["100%", "100%"]} mt={["20px", "20px", "20px", "0px"]} mx={["0px", "0px", "0px", "10px"]}>
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
                    </Box>
                </Flex>

                {/* Inspiration Link */}

                <Flex
                    direction={["column", "column", "column", "row"]}
                    mt="20px"
                    justifyContent="space-between"
                >
                    <Box w={["100%", "100%"]} mx={["0px", "0px", "0px", "10px"]}>
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
                    </Box>
                    <Box w={["100%", "100%"]} mt={["20px", "20px", "20px", "0px"]} mx={["0px", "0px", "0px", "10px"]}>
                        <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                        <Skeleton mt="5px" w="100%" h="30px"></Skeleton>
                    </Box>
                </Flex>

                <Box mx={["0px", "0px", "0px", "10px"]} mt="20px">
                    <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                    <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
                </Box>

                {/* prize summary */}

                <Box mx={["0px", "0px", "0px", "10px"]} mt="20px">
                    <Skeleton mt="5px" w="100%" h="20px"></Skeleton>
                    <Skeleton mt="5px" w="100%" h="60px"></Skeleton>
                </Box>

                {/* prize description */}

                <Flex mx="20px" my="20px" justifyContent={["center", "flex-end"]}>
                    <Skeleton w={["40%", "20%"]} mt="5px" h="40px">
                        <Button  >
                        </Button>
                    </Skeleton>
                </Flex>
            </Flex>
        </Flex>
    )
}
