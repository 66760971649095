import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Stack,
  Button,
  Icon,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  Box,
  Image,
  Container,
  Center,
  Skeleton,
  useToast,
  Tag,
  Link,
  Wrap,
  WrapItem,
  Spacer,
  Show,
} from '@chakra-ui/react';
import splitbee from '@splitbee/web';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import AuthService from '../services/auth-service';
import { Mixpanel } from '../utils/Mixpanel';
import withRouter from '../utils/withRouter.js';
import { FaArrowRight } from 'react-icons/fa';
import { CareerPathCard } from '../components/Track/CareerPathCard.js';
import careerPaths from '../data/careerPaths.json';
import StoryService from '../services/story-service';
import { StoryItem, StoryItemSkeleton } from '../components/Story/StoryItem.js';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faMapSigns,
  faPeopleGroup,
  faPuzzlePiece,
  faStarOfLife,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
import marketingImage from '../images/pexels-christina-morillo-1181414.jpg';

const Landing = () => {
  Mixpanel.track('Landing Page Viewed');
  splitbee.track('Landing Page');

  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(undefined);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [stories, setStories] = useState([]);
  const toast = useToast();

  useEffect(() => {
    async function fetchStories() {
      try {
        setIsLoading(true);
        const response = await StoryService.listStories();
        setStories(response.data.stories);
      } catch (err) {
        toast({
          title: 'An error occured',
          description: 'Unable to get stories, please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    fetchStories();
  }, [toast]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setCurrentUser(JSON.parse(user));
  }, []);
  const Blob = props => {
    return (
      <Icon
        width={'100%'}
        viewBox="0 0 578 440"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
          fill="currentColor"
        />
      </Icon>
    );
  };

  const Feature = ({ title, text, icon }) => {
    return (
      <Stack>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={'gray.100'}
          mb={1}
        >
          {icon}
        </Flex>
        <Text color={'gray.700'} fontWeight={600}>
          {title}
        </Text>
        <Text color={'gray.700'}>{text}</Text>
      </Stack>
    );
  };

  const MotionHeading = motion(Heading);

  return (
    <>
      <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
      <Container p={['2', '20', '20', '20']} maxW={'95%'}>
        <Flex
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <MotionHeading
              as="h1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 2 }}
              lineHeight={1.1}
              fontWeight={'bold'}
              fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}
            >
              <Text
                as={'span'}
                className="text-slate-700"
                position={'relative'}
              >
                Kickstart your career in tech
              </Text>
            </MotionHeading>
            <Text color={'gray.700'} as={'h3'} fontSize={'2xl'}>
              Gain the{' '}
              <span className="underline underline-offset-2 text-stone-500">
                work experience
              </span>
              ,{' '}
              <span className="underline underline-offset-2 text-red-800">
                mentorship
              </span>
              , and{' '}
              <span className="underline underline-offset-2 text-cyan-600">
                portfolio
              </span>{' '}
              you need to start your career in tech.
            </Text>
            {currentUser ? (
              <Stack
                spacing={{ base: 4, sm: 4 }}
                direction={{ base: 'column', sm: 'row' }}
                alignItems={'center'}
              >
                <a href="/home">
                  <Button
                    variant={'outline'}
                    rounded={'full'}
                    size={'lg'}
                    fontSize={'md'}
                    leftIcon={<UserCircleIcon className="w-4 h-4" />}
                  >
                    {`Continue as ${currentUser.firstname}`}
                  </Button>
                </a>
                <Link fontSize={'md'} href="/logout">
                  Login in as a different user
                </Link>
              </Stack>
            ) : (
              <Stack
                alignItems={'center'}
                justifyItems={'center'}
                spacing={{ base: 4, sm: 4 }}
                direction={{ base: 'column', sm: 'row' }}
              >
                <a href="/get-started">
                  <Button
                    width={['80', '50', '50', '40']}
                    rounded={'full'}
                    size={'lg'}
                    px={6}
                    colorScheme={'orange'}
                    fontWeight={'semibold'}
                    bg={'brand.darkBlue'}
                    _hover={{ shadow: 'lg' }}
                  >
                    Get started
                  </Button>
                </a>
                <a href="/login">
                  <Button
                    width={['80', '50', '50', '40']}
                    size={'lg'}
                    rounded="full"
                    colorScheme={'gray'}
                    fontWeight={'semibold'}
                    variant="outline"
                  >
                    Login
                  </Button>
                </a>
              </Stack>
            )}
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
          >
            <Blob
              w={'150%'}
              h={'150%'}
              position={'absolute'}
              top={'-20%'}
              left={0}
              zIndex={-1}
              color={useColorModeValue('orange.50', 'orange.400')}
            />
            <Box
              position={'relative'}
              height={'300px'}
              rounded={'2xl'}
              boxShadow={''}
              width={'full'}
              overflow={'hidden'}
            >
              <Skeleton isLoaded={imageLoaded} height={'100%'}>
                <Image
                  alt={'Learn tech skills, change your life.'}
                  fit={'cover'}
                  align={'center'}
                  style={{
                    objectFit: '',
                    visibility: imageLoaded ? 'visible' : 'hidden',
                  }}
                  w={'100%'}
                  h={'100%'}
                  src="https://res.cloudinary.com/techstarta/image/upload/v1678568812/Landing-hero-image_t2dqyb.png"
                  onLoad={() => setImageLoaded(true)}
                />
              </Skeleton>
            </Box>
          </Flex>
        </Flex>
        <Center pt={8} width="100%">
          <p className="text-sm text-gray-600 uppercase font-semibold">
            Where our mentors work
          </p>
        </Center>
        <Center
          width={'100%'}
          pt="8"
          pb={8}
          alignItems="center"
          justifyItems={'center'}
        >
          <Wrap
            spacing="80px"
            width="100%"
            py={4}
            justify={'center'}
            align={'center'}
          >
            <WrapItem>
              <Image
                alt="GetYourGuide"
                height="30px"
                width="auto"
                src={
                  'https://res.cloudinary.com/techstarta/image/upload/v1621080284/grayscale/image_wvguy5.png'
                }
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="Delivery Hero"
                height="30px"
                width="auto"
                src={
                  'https://res.cloudinary.com/techstarta/image/upload/v1621085669/grayscale/image_8_urb8y9.png'
                }
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="Lieferando"
                height="30px"
                width="auto"
                src={
                  'https://res.cloudinary.com/techstarta/image/upload/v1621085083/grayscale/image_7_xsojxd.png'
                }
              ></Image>
            </WrapItem>

            <WrapItem>
              <Image
                alt="Andela"
                height="30px"
                width="auto"
                src={
                  'https://res.cloudinary.com/techstarta/image/upload/v1621080505/grayscale/image_1_hmrml8.png'
                }
              ></Image>
            </WrapItem>
            <WrapItem>
              <Image
                alt="Sennder"
                height="30px"
                width="auto"
                src={'//logo.clearbit.com/trybrass.com?size=80&greyscale=true'}
              ></Image>
            </WrapItem>
          </Wrap>
        </Center>

        <Box width="full" bgColor="gray.50" px={8} py="20">
          <Center pb={12} flexDirection="column">
            <Heading as="h2" className="text-slate-600">
              What can I do on Techstarta?
            </Heading>
            <Text>
              Get job-ready in tech with practical challenges, mentorship, and
              skill guides.
            </Text>
          </Center>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} pb={12}>
            <Feature
              icon={
                <FontAwesomeIcon
                  size="2x"
                  className="text-orange-400"
                  icon={faPeopleGroup}
                />
              }
              title={'Join 1000+ techies'}
              text={
                'Join a community of techies from all over the world and get support from our mentors.'
              }
            />
            <Feature
              icon={
                <FontAwesomeIcon
                  size="2x"
                  className="text-orange-400"
                  icon={faMapSigns}
                />
              }
              title={'Discover your path in tech'}
              text={
                'Take our career quiz to discover your path in tech and get personalized recommendations.'
              }
            />
            <Feature
              icon={
                <FontAwesomeIcon
                  size="2x"
                  className="text-orange-400"
                  icon={faPuzzlePiece}
                />
              }
              title={'Build your skills and portfolio'}
              text={
                'Solve real-world challenges to build your portfolio and boost your confidence.'
              }
            />

            <Feature
              icon={
                <FontAwesomeIcon
                  size="2x"
                  className="text-orange-400"
                  icon={faComments}
                />
              }
              title={'Get Feedback & guidance'}
              text={
                'Get feedback and access to 1-on-1 mentoring sessions with industry experts.'
              }
            />
            <Feature
              icon={
                <FontAwesomeIcon
                  size="2x"
                  className="text-orange-400"
                  icon={faStarOfLife}
                />
              }
              title={'Learn with bite-sized lessons'}
              text={
                'Learn with high-quality resources hand-picked for you to learn faster.'
              }
            />
            <Feature
              icon={
                <FontAwesomeIcon
                  size="2x"
                  className="text-orange-400"
                  icon={faUserCheck}
                />
              }
              title={'Become a Techstarta Mentor'}
              text={
                'Share your knowledge and experience with learners from all over the world.'
              }
            />
          </SimpleGrid>
          <Center pt={4}>
            <Button
              className="text-xs font-semibold"
              onClick={() => navigate('/get-started')}
              shadow="sm"
              width={['80', '50', '50', '40']}
              size="md"
              rounded="full"
              _hover={{ bg: 'brand.darkBlue', shadow: 'md' }}
              variant="solid"
              colorScheme="white"
              bgColor="brand.darkBlue"
              rightIcon={<FaArrowRight />}
            >
              Get started
            </Button>
          </Center>
        </Box>
        <Box py="8">
          <Flex
            py={8}
            flexDirection={['column', 'row', 'row', 'row']}
            justifyContent="space-between"
          >
            <Flex flexDirection="column">
              <Text className="uppercase text-xs font-semibold text-orange-500 tracking-wide py-2">
                Tech careers
              </Text>
              <Heading as="h2" className="text-slate-600 py-2">
                Explore tech career families
              </Heading>
              <Text className="text-slate-600 py-2">
                Tech jobs ≠ coding. All welcome in tech.
              </Text>
            </Flex>
            <Show above="sm">
              <Button
                onClick={() => navigate('/skills')}
                rounded="full"
                width={['80', '50', '50', '44']}
                variant="outline"
                colorScheme="gray"
              >
                {' '}
                Explore more skills{' '}
              </Button>
            </Show>
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            {careerPaths.paths.map((path, index) => (
              <CareerPathCard key={index} path={path} />
            ))}
          </SimpleGrid>

          <Show below="sm">
            <Box pt={4}>
              <Button
                onClick={() => navigate('/skills')}
                rounded="full"
                width={['80', '50', '50', '40']}
                variant="outline"
                colorScheme="gray"
              >
                {' '}
                Explore more skills{' '}
              </Button>
            </Box>
          </Show>
        </Box>

        <Box py="10">
          <Flex
            py={8}
            flexDirection={['column', 'row', 'row', 'row']}
            justifyContent="space-between"
          >
            <Flex flexDirection="column">
              <Text className="uppercase text-xs font-semibold text-orange-500 tracking-wide py-2">
                Stories
              </Text>
              <Heading as="h3" className="text-slate-600 py-2">
                Learn from industry experts
              </Heading>
              <Wrap pb={4} className="py-2">
                <WrapItem>
                  <Tag
                    colorScheme="cyan"
                    bgColor="cyan.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Interview tips
                  </Tag>
                </WrapItem>
                <WrapItem>
                  <Tag
                    colorScheme="orange"
                    bgColor="brown.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Career stories
                  </Tag>
                </WrapItem>
                <WrapItem>
                  <Tag
                    colorScheme="gray"
                    bgColor="gray.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Leading thoughts
                  </Tag>
                </WrapItem>
              </Wrap>
            </Flex>
            <Show above="sm">
              <Box pt={4}>
                <Button
                  onClick={() => navigate('/stories')}
                  rounded="full"
                  width={['80', '50', '50', '40']}
                  variant="outline"
                  colorScheme="gray"
                >
                  {' '}
                  Read more stories{' '}
                </Button>
              </Box>
            </Show>
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            {isLoading && (
              <>
                <StoryItemSkeleton />
                <StoryItemSkeleton />
                <StoryItemSkeleton />
              </>
            )}

            {!isLoading &&
              stories.length > 0 &&
              stories
                .slice(-4, -1)
                .map((story, index) => (
                  <StoryItem storyDetail={story} key={index} />
                ))}
          </SimpleGrid>
          <Show below="sm">
            <Box pt={4}>
              <Button
                onClick={() => navigate('/stories')}
                rounded="full"
                width={['80', '50', '50', '50']}
                variant="outline"
                colorScheme="gray"
              >
                Read more stories
              </Button>
            </Box>
          </Show>
        </Box>

        <Box
          p={4}
          bgSize="auto"
          bgImage={marketingImage}
          brightness="0.5"
          rounded="lg"
          height={'100%'}
        >
          <Center py={8} flexDirection="column">
            <Heading>Ready to get started with Techstarta?</Heading>
            <Box width={['100%', '80%', '50%']} alignContent={['left', 'left']}>
              <Text
                color="brand.darkBlue"
                pb="8"
                textAlign={['left', 'center']}
              >
                The best way to learn is to do and we want to help you cut
                through the noise with our learn-by-doing approach. Join our
                community of smart learners to put your skill to the action.
              </Text>
            </Box>
            <Stack
              alignItems={'center'}
              justifyItems={'center'}
              spacing={{ base: 4, sm: 4 }}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Button
                onClick={() => navigate('/get-started')}
                shadow="sm"
                size="md"
                width={['80', '50', '50', '40']}
                rounded="full"
                variant="solid"
                colorScheme="white"
                bgColor="brand.darkBlue"
              >
                {' '}
                Get started{' '}
              </Button>
              <Button
                onClick={() => navigate('/quiz')}
                bgColor="white"
                shadow="sm"
                width={['80', '50', '50', '40']}
                size="md"
                rounded="full"
                variant="outline"
                colorScheme="gray"
                textColor={'gray.600'}
              >
                Take career quiz
              </Button>
            </Stack>
          </Center>
        </Box>
      </Container>

      <Footer />
    </>
  );
};

export default withRouter(Landing);
