import React from 'react';
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Tag,
  Text,
} from '@chakra-ui/react';
import { ClockIcon } from '@heroicons/react/24/outline';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';

const MentorCard = ({ mentor, isLoading }) => {
  return (
    <>
      <Skeleton isLoaded={!isLoading}>
        <LinkBox>
          <Flex
            p={2}
            flexDir={'column'}
            rounded="lg"
            border={'1px'}
            shadow={'sm'}
            borderColor="gray.300"
            _hover={{ shadow: 'lg' }}
          >
            <Box pb={4}>
              <Image
                rounded={'lg'}
                src={mentor.profile.image}
                alt={mentor.profile.fullname}
                objectFit="cover"
                h={'52'}
                w={'full'}
              />
              <Badge class="absolute top-0 right-0 bg-white text-gray-700 p-2 rounded-lg m-4 text-sm">
                <HStack>
                  <CheckBadgeIcon className="w-4 h-4" />
                  <Text>Verified</Text>
                </HStack>
              </Badge>
            </Box>
            <Box p={1}>
              <Box pb={4} d="flex" alignItems="baseline">
                <LinkOverlay href={`/mentor/${mentor.profile.username}`}>
                  <Text
                    color="gray.700"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="md"
                    textTransform="capitalize"
                  >
                    {mentor.profile.fullname}
                  </Text>
                </LinkOverlay>
                <HStack>
                  <Text
                    fontSize={'sm'}
                    textColor={'gray.600'}
                    fontWeight={'semibold'}
                  >
                    {mentor.mentor_profile.current_role} at{' '}
                    {mentor.mentor_profile.company}
                  </Text>
                </HStack>
              </Box>

              <Box>
                <Text
                  fontSize={'sm'}
                  textColor={'gray.700'}
                  mt="1"
                  fontWeight="normal"
                  alignItems={'center'}
                  justifyItems={'center'}
                  noOfLines={2}
                  as="h4"
                >
                  {mentor.profile.bio}
                </Text>
              </Box>
              <Box pt={4} className="font-normal text-md ">
                <HStack>
                  <Text
                    color="gray.700"
                    fontSize={'sm'}
                    fontWeight={'semibold'}
                  >
                    Expert in
                  </Text>
                </HStack>
                <Text color="gray.700" fontSize={'sm'}>
                  {mentor.track.title}
                </Text>
              </Box>
              <Box pt={'4'}>
                {mentor.status === 'pending' && (
                  <Button
                    variant={'outline'}
                    textColor={'gray.600'}
                    rounded={'full'}
                    size={'sm'}
                    colorScheme="gray"
                    leftIcon={<ClockIcon className="w-4 h-4" />}
                  >
                    Pending
                  </Button>
                )}
              </Box>
            </Box>
          </Flex>
        </LinkBox>
      </Skeleton>
    </>
  );
};

export default MentorCard;
