import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Select,
  Button,
  useToast,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import Validator from '../utils/validator';
import data from '../data/ListOfIndustries.json';
import MentorService from '../services/mentor-service';
import TextInput from './TextInput';

const EditMentorProfile = ({ mentorProfile, user }) => {
  const toast = useToast();

  const { username } = user;
  const industryList = data.industryData;

  const [isLoading, setIsLoading] = useState(false);

  const [professionalPitch, setProfessionalPitch] = useState('');
  const [industry, setIndustry] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [company, setCompany] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [twitter, setTwitter] = useState('');
  const [personalWebsite, setPersonalWebsite] = useState('');
  const [github, setGithub] = useState('');
  const [funFact, setFunFact] = useState('');
  const [calendlyLink, setCalendlyLink] = useState('');
  const [location, setLocation] = useState('');
  const [pricePerSession, setPricePerSession] = useState('');
  const [percentForCharity, setPercentForCharity] = useState('');

  useEffect(() => {
    setProfessionalPitch(mentorProfile.pitch);
    setIndustry(mentorProfile.industry);
    setCurrentRole(mentorProfile.current_role);
    setYearsOfExperience(mentorProfile.years_of_experience);
    setCompany(mentorProfile.company);
    setLinkedIn(mentorProfile.linkedin);
    setTwitter(mentorProfile.twitter);
    setPersonalWebsite(mentorProfile.website);
    setGithub(mentorProfile.github);
    setFunFact(mentorProfile.fun_fact);
    setCalendlyLink(mentorProfile.calendly_booking_link);
    setLocation(mentorProfile.location);
    setPricePerSession(mentorProfile.price_per_session);
    setPercentForCharity(mentorProfile.percentage_for_charity);
  }, [mentorProfile]);

  const [showProfessionalPitchError, setShowProfessionalPitchError] =
    useState(false);
  const [showCalendlyLinkError, setShowCalendlyLinkError] = useState(false);
  const [showFunFactError, setShowFunFactError] = useState(false);
  const [showLinkedInError, setShowLinkedInError] = useState(false);
  const [showTwitterError, setShowTwitterError] = useState(false);
  const [showPersonalWebsiteError, setShowPersonalWebsiteError] =
    useState(false);
  const [showLocationError, setShowLocationError] = useState(false);

  const handleMentorProfileUpdate = async event => {
    event.preventDefault();

    let isError = false;
    const isProfessionalPitchValid = Validator.isValueValid(professionalPitch);
    const isFunFactValid = Validator.isValueValid(funFact);
    const isLinkedInValid = Validator.isUrlValid(linkedIn);
    const isPersonalWebsiteValid = Validator.isUrlValid(personalWebsite);
    const isCalendlyLinkValid = Validator.isCalendlyLinkValid(calendlyLink);
    const isLocationValid = Validator.isValueValid(location);
    const isTwitterValid = Validator.isValueValid(twitter);

    setShowProfessionalPitchError(!isProfessionalPitchValid);
    setShowFunFactError(!isFunFactValid);
    setShowCalendlyLinkError(!isCalendlyLinkValid);
    setShowLinkedInError(!isLinkedInValid);
    setShowPersonalWebsiteError(!isPersonalWebsiteValid);
    setShowLocationError(!isLocationValid);
    setShowTwitterError(!isTwitterValid);

    if (
      !isProfessionalPitchValid ||
      !isCalendlyLinkValid ||
      !isLinkedInValid ||
      !isPersonalWebsiteValid ||
      !isLocationValid ||
      !isTwitterValid
    ) {
      isError = true;
      toast({
        title: 'Error!',
        description: 'Please fill in all required fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    if (isError) {
      return;
    }

    setIsLoading(true);

    const payload = {
      pitch: professionalPitch,
      industry: industry,
      current_role: currentRole,
      years_of_experience: yearsOfExperience,
      company: company,
      linkedin: linkedIn,
      twitter: twitter,
      website: personalWebsite,
      github: github,
      fun_fact: funFact,
      calendly_booking_link: calendlyLink,
      location: location,
    };

    try {
      const response = await MentorService.updateMentorProfile(
        username,
        payload
      );
      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Your mentor profile has been updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (err) {
      toast({
        title: 'Error!',
        description: 'Unable to update your profile, please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {mentorProfile ? (
        <Flex
          p="20px"
          h={['100%', '100%', '100%']}
          w={['100%', '100%', '100%', '100%']}
          direction="column"
        >
          <Flex mt="20px" direction="column">
            {/* Professional Pitch */}
            <Box w={['95%', '97%', '97%', 'auto']}>
              <TextInput
                title="Professional pitch"
                element="textArea"
                onChangeFunction={e => setProfessionalPitch(e.target.value)}
                isRequired={true}
                value={professionalPitch || ''}
                placeholder="Tell us about your professional experience"
                showError={showProfessionalPitchError}
                errorMessage="Your professional pitch is required."
              />
            </Box>

            {/* Fun Fact */}
            <Box w={['95%', '97%', '97%', 'auto']} mt="20px">
              <TextInput
                title="Your fun-fact"
                element="textArea"
                onChangeFunction={e => setFunFact(e.target.value)}
                value={funFact || ''}
                placeholder="Like the first job you wanted as a kid :)"
                showError={showFunFactError}
                errorMessage="A fun fact is required."
              />
            </Box>
          </Flex>

          {/* Company and years of experience */}

          <Flex
            direction={['column', 'column', 'column', 'row']}
            mt="20px"
            justifyContent="space-between"
            w={['93%', '95%', '95%', 'auto']}
          >
            <TextInput
              title="Current role"
              type="text"
              onChangeFunction={e => setCurrentRole(e.target.value)}
              placeholder="e.g. Software Engineer"
              isRequired={true}
              value={currentRole || ''}
              addMarginTop={true}
            />
            <TextInput
              value={company || ''}
              title="Company"
              type="text"
              onChangeFunction={e => setCompany(e.target.value)}
              placeholder="e.g Google"
              isRequired={true}
            />
          </Flex>

          {/* Calendly and Location */}

          <Flex
            direction={['column', 'column', 'column', 'row']}
            mt="20px"
            justifyContent="space-between"
            w={['93%', '95%', '95%', 'auto']}
          >
            <TextInput
              value={location || ''}
              title="Location"
              type="text"
              onChangeFunction={e => setLocation(e.target.value)}
              placeholder="Lagos, Nigeria"
              addMarginTop={true}
              showError={showLocationError}
              errorMessage="Your Location is required."
            />
          </Flex>
          <Flex
            direction={['column', 'column', 'column', 'row']}
            mt="40px"
            paddingLeft="10px"
            justifyContent="space-between"
            w={['93%', '95%', '95%', 'auto']}
          >
            {/* <Text color="brand.gray" fontWeight="semibold" fontSize="sm" className="uppercase">Elsewhere</Text> */}
          </Flex>

          <Flex
            direction={['column', 'column', 'column', 'row']}
            mt="20px"
            justifyContent="space-between"
            w={['93%', '95%', '95%', 'auto']}
          >
            <TextInput
              title="LinkedIn"
              type="url"
              onChangeFunction={e => setLinkedIn(e.target.value)}
              isRequired={true}
              value={linkedIn || ''}
              placeholder="https://www.linkedin.com/in/yourname/"
              showError={showLinkedInError}
              errorMessage="A valid url is required."
            />
            <TextInput
              title="Personal website"
              type="url"
              onChangeFunction={e => setPersonalWebsite(e.target.value)}
              value={personalWebsite || ''}
              placeholder="https://www.yourname.com"
              showError={showPersonalWebsiteError}
              errorMessage="A valid url is required."
            />
          </Flex>

          <Flex
            direction={['column', 'column', 'column', 'row']}
            mt="20px"
            justifyContent="space-between"
            w={['93%', '95%', '95%', 'auto']}
          >
            <TextInput
              title="Calendly link"
              type="url"
              onChangeFunction={e => setCalendlyLink(e.target.value)}
              value={calendlyLink || ''}
              placeholder="https://calendly.com/yourprofilename"
              showError={showCalendlyLinkError}
              errorMessage="A valid calendly link is required"
            />
          </Flex>

          {/* Personal website and Github */}

          <Flex
            direction={['column', 'column', 'column', 'row']}
            mt="20px"
            justifyContent="space-between"
            w={['93%', '95%', '95%', 'auto']}
          >
            <TextInput
              title="Github"
              type="text"
              onChangeFunction={e => setGithub(e.target.value)}
              value={github || ''}
              placeholder="Github Handle"
              addMarginTop={true}
            />
            <TextInput
              title="Twitter"
              type="text"
              onChangeFunction={e => setTwitter(e.target.value)}
              value={twitter || ''}
              placeholder="username"
              addMarginTop={true}
              showError={showTwitterError}
              errorMessage="Check your twitter handle."
            />
          </Flex>

          <Flex mx={2} my="20px" justifyContent={['center', 'flex-end']}>
            <Button
              _focus={{ outline: 'none' }}
              bgColor="brand.darkBlue"
              textColor={'white'}
              rounded={'full'}
              size="md"
              _hover={{ shadow: 'md' }}
              onClick={handleMentorProfileUpdate}
            >
              Save changes
              <Spinner
                display={isLoading ? 'block' : 'none'}
                ml="5px"
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.500"
                size="md"
              />
            </Button>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

export default EditMentorProfile;
