import React, { useState, useEffect } from 'react';
import {
  Flex,
  Heading,
  Text,
  Stack,
  useToast,
  Link,
  Icon,
  Container,
  Tag,
  WrapItem,
  Wrap,
} from '@chakra-ui/react';
import { FaMicroblog } from 'react-icons/fa';
import Navbar from '../components/Navbar.js';
import StoryGrid from '../components/Story/StoryGrid';
import StoryService from '../services/story-service';
import SEO from '../components/Common/SEO.js';
import AuthService from '../services/auth-service';
import Footer from '../components/Footer.js';
import withRouter from '../utils/withRouter.js';

const Stories = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stories, setStories] = useState([]);
  const currentUser = JSON.parse(AuthService.getCurrentUser());
  const toast = useToast();

  useEffect(() => {
    async function fetchStories() {
      try {
        setIsLoading(true);
        const response = await StoryService.listStories();
        setStories(response.data.stories);
      } catch (err) {
        toast({
          title: 'An error occured',
          description: 'Unable to get stories, please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
    fetchStories();
  }, [toast]);

  return (
    <>
      <SEO
        title="Learn from industry experts in tech | Techstarta"
        url="http://techstarta.com/stories"
        description={
          'From career journeys, to interview tips. We help you learn from the best in the industry.'
        }
      />
      <div className="relative bg-white">
        <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
        <Container p={['5', '20', '20', '20']} maxW={'7xl'}>
          <Flex
            className="bg-gradient-to-bl from-cyan-400 to-gray-100  rounded-lg overflow-hidden border"
            p={['10px', '30px', '40px', '40px']}
            w="auto"
            my={['10px', '10px', '10px', '20px']}
            justifyContent={['center', 'center', 'center', 'space-between']}
            flexDirection={['column', 'column', 'column', 'row']}
            mb="10px"
            rounded="lg"
            mt={[20, 10, 10, 10]}
          >
            <Flex
              justifyContent="center"
              alignContent="center"
              color="gray.700"
              flexDirection="column"
            >
              <Heading textAlign="left">
                Discover the journeys and triumphs of techies
              </Heading>
              <Wrap py={4}>
                <WrapItem>
                  <Tag
                    colorScheme="cyan"
                    bgColor="cyan.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Interview tips
                  </Tag>
                </WrapItem>
                <WrapItem>
                  <Tag
                    colorScheme="orange"
                    bgColor="brown.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Career stories
                  </Tag>
                </WrapItem>
                <WrapItem>
                  <Tag
                    colorScheme="gray"
                    bgColor="gray.100"
                    mb={['5px', '5px', '0px', '0px']}
                    mr={2}
                  >
                    Leading thoughts
                  </Tag>
                </WrapItem>
              </Wrap>
              <Text textAlign="left" fontSize="md">
                Our Stories inspire and teach through shared experiences and
                expertise.
              </Text>
              <Stack py="15px" isInline spacing="10px">
                <Link
                  fontWeight="semibold"
                  href="mailto:hello@techstarta.com"
                  _focus={{ outline: 'none' }}
                >
                  Share your story or recommend someone
                  <Icon as={FaMicroblog}></Icon>
                </Link>
              </Stack>
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <StoryGrid stories={stories} isLoading={isLoading} />
          </Flex>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Stories);
