import React from "react";
import moment from "moment";
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";

import AuthService from "../services/auth-service";

const Comment = ({ comment }) => {
  return (
    <>
      <Flex p={["10px", "10px", "10px", "20px"]}>
        <Flex>
          <Avatar size="sm" name={comment.author} src={AuthService.buildImageUrl(comment.author_image)}/>
          <div>
            <Box marginX="10px" padding="10px" rounded="lg" bg="gray.100" width={["90%", "95%", "95%", "100%"]}>
              <div className="font-semibold text-sm leading-relaxed">{comment.author} {comment.is_author_mentor ? (<span className="text-green-600 text-xs font-normal"> Verified Mentor</span>) :''}</div>
                {/* <Flex className="flex items-center mt-1 py-2" flexWrap="wrap" alignItems="left">
                    <Flex alignItems="center">
                        <Text fontSize="sm" color="gray.600" marginRight="5px">Quality Rating</Text>
                        <Icon color="yellow.600" name="star" size="12px"></Icon>
                        <Icon color="yellow.600" name="star" size="12px"></Icon>
                        <Icon color="yellow.600" name="star" size="12px"></Icon>
                        <Icon color="yellow.600" name="star" size="12px"></Icon>
                        <Icon color="gray.400" name="star" size="12px"></Icon>
                    </Flex>
                    <Flex alignItems="center">
                        <Text fontSize="sm" paddingLeft="15px" color="gray.600" marginRight="5px">Efficiency Rating</Text>
                        <Icon color="yellow.600" name="star" size="12px"></Icon>
                        <Icon color="yellow.600" name="star" size="12px"></Icon>
                        <Icon color="yellow.600" name="star" size="12px"></Icon>
                        <Icon color="yellow.600" name="star" size="12px"></Icon>
                        <Icon color="gray.400" name="star" size="12px"></Icon>
                    </Flex>
                </Flex> */}
                
              <div className="text-base leading-snug md:leading-normal pt-5">
                {comment.body}
              </div>
            </Box>
            <Text paddingX="10px" fontSize="xs" color="gray.600">
              {moment(comment.updated_at).fromNow()}
            </Text>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default Comment;
