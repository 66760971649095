import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  useToast,
  useDisclosure,
  Container,
  Link,
} from '@chakra-ui/react';

import Navbar from '../components/Navbar.js';

import DetailedStorySkeleton from '../components/Story/DetailedStorySkeleton';

import withRouter from '../utils/withRouter.js';
import { Mixpanel } from '../utils/Mixpanel';
import { DetailedStory } from '../components/Story/DetailedStory.js';
import { ContentAuthorCard } from '../components/Common/ContentAuthorCard.js';
import { SocialShareBox } from '../components/Common/SocialShareBox.js';

import authService from '../services/auth-service.js';
import storyService from '../services/story-service';
import CommentBox from '../components/Common/CommentBox.js';
import LoginModal from '../components/Common/LoginModal.js';
import Footer from '../components/Footer.js';
import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';

const StoryDetail = () => {
  const { slug } = useParams();
  const location = useLocation();

  const [story, setStory] = useState(null);
  const toast = useToast();
  const currentUser = JSON.parse(authService.getCurrentUser());
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [isCommentValid, setIsCommentValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const postComment = async event => {
    event.preventDefault();
    if (comment.trim() === '') {
      setIsCommentValid(false);
      return;
    }
    const payload = {
      body: comment,
    };
    setIsCommentValid(true);
    try {
      setIsLoading(true);
      const response = await storyService.postComment(slug, payload);
      setComments(comments => [response.data.comment, ...comments]);
    } catch (err) {
      toast({
        title: 'Comment not sent',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  const handleCommentBoxClick = event => {
    if (currentUser == null) {
      onOpen();
    }
    event.preventDefault();
  };

  const upvoteComment = async commentId => {
    try {
      const response = await storyService.upvoteComment(commentId);
      const updatedComments = comments.map(comment =>
        comment.id === commentId
          ? { ...comment, upvotes: response.data.comment.upvotes }
          : comment
      );
      setComments(updatedComments);
    } catch (err) {}
  };

  useEffect(() => {
    async function getStoryDetail() {
      try {
        const response = await storyService.getStoryDetail(slug);
        setStory(response.data.story);
        setComments(response.data.story.comments);
        document.title = `Techstarta | ${response.data.story.title}`;
        Mixpanel.track('Viewed Story', { story: response.data.story.title });
      } catch (err) {
        toast({
          title: 'An error occurred!',
          description: 'Unable to fetch this story, please try again later',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    getStoryDetail();
  }, [slug, toast]);

  return (
    <>
      <Helmet>
        <meta name="description" />
        <title>Career stories, interview tips and more | Techstarta</title>
      </Helmet>
      <div className="relative bg-white">
        <Navbar transparent={false} currentUser={currentUser} showMenu={true} />
        <Container p={['5', '5', '20', '20']} maxW={['5xl', '4xl']}>
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg">
                <div className="rounded-lg mb-0 border-0">
                  <Box
                    p={'4'}
                    mt={['30px', '40px', '40px', '0px']}
                    display="flex"
                    flexDirection="column"
                    rounded="md"
                  >
                    {story ? (
                      <DetailedStory
                        story={story}
                        slug={slug}
                        location={location}
                      />
                    ) : (
                      <DetailedStorySkeleton />
                    )}

                    <Flex
                      pt={'16'}
                      flexDirection={['column', 'row']}
                      justifyContent={['flex-start', 'space-between']}
                    >
                      <Box
                        rounded={'lg'}
                        shadow={'sm'}
                        border={'1px solid'}
                        borderColor={'gray.200'}
                        p={['10px', '10px']}
                        py={'8'}
                        m={['10px', '10px']}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        w="100%"
                      >
                        <Text fontSize="lg" color="gray.600">
                          Want to share your story? Talk to us!
                        </Text>
                        <Link pt={4} href="mailto:hello@techstarta.com">
                          <Button
                            _focus={{ outline: 'none' }}
                            bg={'white'}
                            textColor={'gray.700'}
                            _hover={{ shadow: 'sm' }}
                            variant={'outline'}
                            rounded="full"
                            fontSize="sm"
                          >
                            Share your story
                          </Button>
                        </Link>
                      </Box>

                      <Box
                        border={'1px solid'}
                        shadow={'sm'}
                        borderColor={'gray.200'}
                        rounded={'lg'}
                        p={['10px', '10px']}
                        m={['10px', '10px']}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        w="100%"
                      >
                        <Text pt={4} fontSize="lg" color="gray.600">
                          Want to start your career in tech?
                        </Text>
                        <Link py={4} href="/get-started">
                          <Button
                            _focus={{ outline: 'none' }}
                            bg="gray.700"
                            _hover={{ shadow: 'md' }}
                            textColor={'white'}
                            rounded="full"
                            size="md"
                            fontSize="sm"
                            rightIcon={
                              <ArrowRightCircleIcon className="w-4 h-4" />
                            }
                          >
                            Get started
                          </Button>
                        </Link>
                      </Box>
                    </Flex>

                    <Flex
                      flexDirection={['column', 'column']}
                      py={['4', '4', '4', '12']}
                      border
                    >
                      <Flex marginTop="20px">
                        <Box pr="5px" className="hidden md:block">
                          <Avatar
                            src={
                              currentUser
                                ? authService.buildImageUrl(currentUser.image)
                                : ''
                            }
                            name={
                              currentUser
                                ? currentUser.firstname +
                                  ' ' +
                                  currentUser.lastname
                                : ''
                            }
                            size="md"
                          />
                        </Box>
                        <Box w="100%">
                          <Textarea
                            fontSize="sm"
                            placeholder="Write a comment..."
                            value={comment}
                            onClick={handleCommentBoxClick}
                            onChange={event => {
                              event.preventDefault();
                              setIsCommentValid(
                                event.target.value.trim() !== ''
                              );
                              setComment(event.target.value);
                            }}
                          />
                          <Text
                            style={{
                              display: isCommentValid ? 'none' : 'block',
                            }}
                            fontSize="sm"
                            color="red.500"
                          >
                            {' '}
                            Comment cannot be blank
                          </Text>
                        </Box>
                      </Flex>
                      <Flex
                        my="10px"
                        justifyContent={['flex-start', 'flex-end']}
                      >
                        <Button
                          _focus={{ outline: 'none' }}
                          bg="brand.darkBlue"
                          _hover={{ shadow: 'md' }}
                          onClick={postComment}
                          textColor={'white'}
                          isLoading={isLoading}
                          loadingText="Working..."
                          rounded="full"
                          size="md"
                          fontSize="sm"
                        >
                          Post comment
                        </Button>
                      </Flex>
                    </Flex>

                    {story ? (
                      <Box p={['10px', '10px']} marginTop="20px">
                        <Flex direction="column">
                          <Text
                            fontWeight="semibold"
                            fontSize="xl"
                            letterSpacing="normal"
                            color="brand.darkBlue"
                          >
                            {' '}
                            Comments
                          </Text>
                        </Flex>
                        {comments.length > 0 ? (
                          comments.map(comment => (
                            <CommentBox
                              key={comment.id}
                              comment={comment}
                              upvoteFn={upvoteComment}
                            />
                          ))
                        ) : (
                          <Text fontSize="sm" color="gray.500">
                            Be the first to comment on this story
                          </Text>
                        )}
                      </Box>
                    ) : null}
                    <LoginModal
                      isOpen={isOpen}
                      onClose={onClose}
                      redirectTo={location.pathname}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(StoryDetail);
