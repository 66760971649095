import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Text,
  Image,
  IconButton,
  Skeleton,
  Flex,
  Icon,
  Divider,
  Badge,
  Stack,
} from '@chakra-ui/react';
import { FaAngleUp, FaRegComments } from 'react-icons/fa';
import MarkdownToHtml from '../ReactMarkdownItem';
import moment from 'moment';
import storyService from '../../services/story-service';
import { ContentAuthorCard } from '../Common/ContentAuthorCard';
import { SocialShareBox } from '../Common/SocialShareBox';

export const DetailedStory = ({ story }) => {
  const { category, upvotes, slug, comments, guest_name, guest_current_role } =
    story;
  const [votes, setVotes] = useState(upvotes);

  const upvoteStory = async event => {
    event.preventDefault();
    try {
      const response = await storyService.upvoteStory(slug);
      setVotes(response.data.story.upvotes);
    } catch (err) {}
  };

  return (
    <>
      <Flex
        p={2}
        pb={4}
        alignItems="center"
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <Box w={{ base: '100%', sm: '100%', md: '50%' }}>
          <Text
            fontWeight="semibold"
            fontSize="3xl"
            letterSpacing="normal"
            as={'h1'}
            color="brand.darkBlue"
          >
            {story.title}
          </Text>
          <Text
            pt={2}
            fontWeight={'semibold'}
            textColor={'gray.600'}
            fontSize={'md'}
            noOfLines={2}
          >
            {guest_name}, {guest_current_role}
          </Text>
          <Stack py="4" w="100%" spacing="4" direction="row" align={'center'}>
            {category && (
              <Badge size="xs" rounded="full" colorScheme="teal" px="2">
                {category.name}
              </Badge>
            )}
            {
              <Text textColor="gray.500" fontSize="xs">
                {moment(story.published_date).fromNow()}
              </Text>
            }

            {comments && (
              <Text textColor="gray.500" fontSize="xs">
                <Icon color="gray.500" as={FaRegComments}></Icon>{' '}
                {comments.length}
              </Text>
            )}
            <ButtonGroup size="sm" isAttached variant="outline">
              <IconButton
                onClick={upvoteStory}
                aria-label="Upvote"
                _focus={{ outline: 'none' }}
                icon={<FaAngleUp />}
              />
              <Button mr="-px" _focus={{ outline: 'none' }}>
                {votes}
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
        <Flex
          alignContent={'flex-end'}
          flexShrink={0}
          justifyContent={'flex-end'}
          w={{ base: '100%', sm: '100%', md: '50%' }}
        >
          <Image
            borderRadius="lg"
            height={'300px'}
            objectFit={'cover'}
            width={'auto'}
            src={story.featured_image}
            alt={story.title}
          />
        </Flex>
      </Flex>
      <Divider />

      <Flex
        pt="4"
        justifyContent={[
          'flex-start',
          'flex-start',
          'flex-start',
          'space-between',
        ]}
        flexDirection={['column', 'column', 'row', 'row']}
        pb={4}
      >
        {story ? (
          <Flex>
            <SocialShareBox
              boxTitle={'Share this story'}
              contentTitle={story.title}
              contentType={'story'}
            />
          </Flex>
        ) : null}
        {story ? (
          <Flex
            justifyContent={['flex-start', 'flex-end', 'flex-end', 'flex-end']}
          >
            <ContentAuthorCard author={story.author} />
          </Flex>
        ) : null}
      </Flex>
      <Divider />
      <Box pt={10} m={{ base: 2, md: 2 }}>
        <Box fontSize="md" color="gray.800">
          <MarkdownToHtml input={story.body} />
        </Box>
      </Box>
    </>
  );
};

export const StoryDetailsSkeleton = () => {
  return (
    <>
      <Box color="gray.500" mt="30px">
        <Box fontSize="60px">
          <Skeleton h="40px"> </Skeleton>
        </Box>
        <Box display="flex" mt="10px" flexDirection="column">
          <Skeleton mt="10px" h="20px">
            {' '}
          </Skeleton>
          <Skeleton mt="10px" h="30px">
            {' '}
          </Skeleton>
        </Box>
      </Box>
      <Box mt="40px">
        <Box mt="40px">
          <Heading color="gray.500" mb="20px" size="sm">
            Getting started
          </Heading>
          <Box color="#718096">
            <Skeleton mt="20px" h="20px">
              {' '}
            </Skeleton>
            <Skeleton mt="20px" h="40px">
              {' '}
            </Skeleton>
          </Box>
        </Box>

        <Box mt="40px">
          <Heading color="gray.500" mb="20px" size="sm">
            Prize
          </Heading>
          <Box color="#718096">
            <Box color="#718096">
              <Skeleton mt="20px" h="20px">
                {' '}
              </Skeleton>
              <Skeleton mt="20px" h="40px">
                {' '}
              </Skeleton>
            </Box>
          </Box>
        </Box>
        <Box mt="40px">
          <Heading color="gray.500" mb="20px" size="sm">
            Inspiration
          </Heading>
          <Skeleton h="30px"> </Skeleton>
          <Box color="#718096">
            <Skeleton mt="20px" h="20px">
              {' '}
            </Skeleton>
            <Skeleton mt="20px" h="40px">
              {' '}
            </Skeleton>
          </Box>
        </Box>
        <Box bg="whitesmoke" mt="40px" p="20px" pt="40px">
          <Skeleton mt="20px" h="30px">
            {' '}
          </Skeleton>
          {/* <Skeleton mt="20px" h="20px"> </Skeleton> */}
        </Box>
      </Box>
    </>
  );
};
